/* mixins */
@mixin background-size-settings {
  -webkit-background-size: 16px;
  -moz-background-size: 16px;
  -o-background-size: 16px;
  background-size: 16px;
}

@mixin background-position-settings {
  -webkit-background-position: left 15px top 50%;
  -moz-background-position: left 15px top 50%;
  -o-background-position: left 15px top 50%;
  background-position: left 15px top 50%;

  @media #{$tablet-landscape} {
    -webkit-background-position: left 30px top 50%;
    -moz-background-position: left 30px top 50%;
    -o-background-position: left 30px top 50%;
    background-position: left 30px top 50%;
  }

  @media #{$desktop-narrow} {
    -webkit-background-position: left 15px top 50%;
    -moz-background-position: left 15px top 50%;
    -o-background-position: left 15px top 50%;
    background-position: left 15px top 50%;
  }
}

@mixin background-repeat-settings {
  background-repeat: no-repeat;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  background: $brand-blue;
  z-index: 100;

  @include box-shadow;

  @media #{$tablet-portrait} {
    height: 68px;
  }

  @media #{$tablet-landscape} {
    height: 100px;
  }

  @media #{$desktop} {
    background: url(~/assets/images/misc/watermark.svg) $brand-blue top left;
    @include background-size(100px, 100px);
  }

  @media #{$desktop-narrow} {
    height: 68px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 68px;
    margin: 0 0 0 5%;
    float: left;
    position: relative;

    @media #{$tablet-portrait} {
      margin-left: 20px;
    }

    @media #{$tablet-landscape} {
      height: 100px;
      margin-left: 30px;
      background-image: none;
      background-color: transparent;
    }

    @media #{$desktop-narrow} {
      height: 68px;
    }

    .logo-container {
      display: flex;
      height: 28px;

      @media #{$desktop} {
        height: 40px;
      }

      @media #{$desktop-narrow} {
        height: 28px;
      }

      a {
        height: 100%;
      }

      img {
        max-height: 100%;
        max-width: 100%;

        &.logo-image {
          height: 100%;
          display: none;

          @media #{$tablet-landscape} {
            display: block;
          }

          &.logo-brand {
            display: block;
          }
        }
      }

      .vertical-seperator {
        display: none;

        @media #{$tablet-landscape} {
          display: block;
          width: 1px;
          height: 100%;
          margin: 0 1rem;
          background: rgba($fill, 0.6);
        }
      }
    }
  }
}

.main-search {
  position: relative;
  top: 13px;
  float: right;

  &.on {
    width: 100%;
    height: 72px;
    padding: 15px 5%;
    background-color: $brand-blue;
    top: 0;
    z-index: 2;

    select {
      width: 20%;
      margin-bottom: 0;
    }

    input[type="text"] {
      width: 80%;
      margin-bottom: 0;
      border: 1px solid $fill;
    }

    input[type="submit"] {
      width: 20%;
    }
  }

  input,
  select {
    float: left;
  }

  select {
    width: 82px;
    border-right: 1px solid $brand-light-blue2;
  }

  input[type="text"] {
    width: 380px;
    margin-bottom: 0;
    border: 1px solid $fill;

    &:focus {
      border: 1px solid $fill;
    }

    @media #{$desktop-wide} {
      width: 718px;
    }
  }

  .icon-search {
    width: 20%;
    min-height: 42px;
    float: left;
    background: url(~/assets/images/icons/icon-search.svg) $fill no-repeat
      center center;
    @include background-size(20px, 20px);
    border-right: 1px solid $brand-light-blue2;
    cursor: pointer;

    @media #{$tablet-portrait} {
      width: 50px;
    }
  }

  input[type="submit"] {
    width: 42px;
    background: url(~/assets/images/icons/icon-search-white.svg) $brand-black
      no-repeat center center;
    @include background-size(20px, 20px);
    @include background-transition;
    color: $text-color-light;
    font-size: 0;

    &:hover {
      background-color: $grey2;
    }
  }

  .toggle-search-container {
    display: none;

    &.on {
      display: block;
    }
  }

  @media #{$tablet-portrait} {
    margin-right: 15px;

    .toggle-search-container {
      display: block;
    }
  }

  @media #{$tablet-landscape} {
    margin-right: 30px;
    top: 29px;

    &.independent {
      top: 0;
    }
  }

  @media #{$desktop-narrow} {
    top: 13px;
    margin-right: 15px;
  }

  &.independent {
    width: 100%;
    height: 44px;
    margin: 0;
    border: 1px solid $brand-light-blue2;
    top: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    input[type="text"] {
      &.search-input {
        width: 80%;
        flex-grow: 1;
      }
    }

    .icon-search {
      width: 50px;
    }

    .auto-complete-results {
      width: auto;
      min-width: auto;
      max-height: 308px;
      margin-top: 42px;

      @media #{$tablet-portrait} {
        position: fixed;
      }

      ul {
        margin-left: 0;
      }
    }
  }
}

.veil {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(40, 47, 57, 0.75);
  z-index: 1;
  display: none;

  &.on {
    display: block;
  }
}

.settings-panel {
  min-height: 68px;
  float: right;
  margin-right: 5%;
  border-left: 1px solid rgba($fill, 0.3);
  border-right: 1px solid rgba($fill, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .username-my-profile-link-container {
    display: none;
    width: 100%;
    height: 100px;
    align-items: center;
    cursor: pointer;
    @include background-transition;
    @include all-transition;

    &:hover {
      background-color: $brand-dark-blue;
    }

    @media #{$desktop} {
      display: flex;
    }
  }

  .settings-panel-toggle {
    width: 60px;
    height: 68px;
    position: relative;
    float: right;
    background: url(~/assets/images/icons/icon-cog-white.svg) no-repeat center
      center;
    @include background-size(25px, 25px);
    @include background-transition;
    cursor: pointer;
    z-index: 1;

    &.on {
      background: url(~/assets/images/icons/icon-cog-blue.svg)
        $brand-light-blue1 no-repeat center center;
      @include background-size(25px, 25px);

      &:hover {
        @include background-transition;
        background-color: $brand-light-blue1;
      }
    }

    &:hover {
      @include background-transition;
      background-color: $brand-dark-blue;
    }

    @media #{$tablet-landscape} {
      width: 80px;
      height: 100px;
    }

    @media #{$desktop-narrow} {
      width: 60px;
      height: 68px;
    }
  }

  p {
    margin: 0 0 0 20px;
    color: $text-color-light;
    display: none;

    @media #{$desktop} {
      display: block;
      font-size: 1.6em;
    }
  }

  @media #{$tablet-portrait} {
    margin-right: 20px;
  }

  @media #{$tablet-landscape} {
    min-height: 100px;
    margin-right: 30px;
  }

  @media #{$desktop-narrow} {
    min-height: 68px;
  }

  .avatar-container {
    height: 68px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    @include background-transition;

    &:hover {
      background-color: $brand-dark-blue;
    }

    @media #{$tablet-landscape} {
      height: 100px;
    }

    @media #{$desktop-narrow} {
      height: 68px;
    }

    &.on {
      background-color: $brand-light-blue1;

      .avatar {
        border-color: $brand-blue;
      }
    }

    .avatar {
      margin: 0 1rem;
      width: 42px;
      height: 42px;
      border: 2px solid $fill;
    }
  }
}

.settings-panel-content {
  display: block;
  width: 90%;
  max-width: 322px;
  margin-right: 5%;
  position: absolute;
  top: 68px;
  right: 1px;
  background-color: $brand-light-blue1;
  @include box-shadow;

  @media #{$tablet-portrait} {
    margin-right: 20px;
  }

  @media #{$tablet-landscape} {
    width: 322px;
    margin-right: 30px;
    top: 100px;
  }

  @media #{$desktop-narrow} {
    top: 68px;
  }

  ul {
    li {
      border-bottom: 1px solid $brand-light-blue2;

      @include all-transition;

      &:last-of-type {
        border-bottom: none;
      }

      a {
        padding: 20px 20px 20px 40px;
        display: block;
        color: $link-color;
        @include color-transition;

        &:hover {
          color: $link-hover-color;
        }

        @media #{$tablet-landscape} {
          padding: 28px 28px 28px 65px;
        }

        @media #{$desktop-narrow} {
          padding: 20px 20px 20px 40px;
        }
      }

      .my-profile-link-container {
        padding:20px 20px;
        display: flex;
        flex-direction: column;

        .username {
          padding: 0 0 0 20px;
          margin-bottom: 15px;
        }
        p {
          padding-top: 5px;
          margin-bottom: 0;
          font-size: 0.8rem;
          opacity: 0.8;
          padding-left: 42px;
          display:flex;

          span {
             display:flex;
          }
          span:not(.squash-level) {
            padding-left: 10px;
          }
          a {
            padding:0 0 0 10px;
          }
        }
      }

      &#profile {
        background-image: url(~/assets/images/icons/icon-small-profile-blue.svg);
        @include background-repeat-settings;
        -webkit-background-position: left 15px top 18px;
        -moz-background-position: left 15px top 18px;
        -o-background-position: left 15px top 18px;
        background-position: left 15px top 18px;
        @include background-size(16px, 16px);

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-profile.svg);
          @include background-repeat-settings;
          -webkit-background-position: left 15px top 18px;
          -moz-background-position: left 15px top 18px;
          -o-background-position: left 15px top 18px;
          background-position: left 15px top 18px;
          @include background-size-settings;
        }
      }

      &#manage-users {
        background-image: url(~/assets/images/icons/icon-people-blue.svg);
        @include background-repeat-settings;
        @include background-position-settings;
        @include background-size(16px, 16px);

        &:hover {
          background-image: url(~/assets/images/icons/icon-people.svg);
          @include background-repeat-settings;
          @include background-position-settings;
          @include background-size-settings;
        }
      }

      &#siteSelection {
        background-image: url(~/assets/images/icons/icon-nav-dashboard.svg);
        @include background-repeat-settings;
        @include background-position-settings;
        @include background-size(16px, 16px);

        &:hover {
          background-image: url(~/assets/images/icons/icon-nav-dashboard-on.svg);
          @include background-repeat-settings;
          @include background-position-settings;
          @include background-size-settings;
        }
      }

      &#logout {
        background-image: url(~/assets/images/icons/icon-small-logout-blue.svg);
        @include background-repeat-settings;
        @include background-position-settings;
        @include background-size(16px, 16px);

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-logout.svg);
          @include background-repeat-settings;
          @include background-position-settings;
          @include background-size-settings;
        }
      }

      &#returnToAccout {
        background-image: url(~/assets/images/icons/icon-small-logout-blue.svg);
        @include background-repeat-settings;
        @include background-position-settings;
        @include background-size(16px, 16px);

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-logout.svg);
          @include background-repeat-settings;
          @include background-position-settings;
          @include background-size-settings;
        }
      }
    }
    .site-selection {
      .header {
        pointer-events: none;
      }
      a {
        padding: 20px 28px 10px 65px;
      }
      .hiddenOption {
        display: none !important;
      }
      .siteSelection-sub-option {
        font-size: 0.8rem;
        padding: 20px 28px 20px 65px;
      }
    }
  }
}

.auto-complete-results {
  display: none;
  min-width: 100%;
  z-index: 1;

  &.on {
    display: block;
  }

  width: 100%;
  min-width: auto;
  max-height: 410px;
  overflow-y: auto;
  @include box-shadow;

  @media #{$tablet-portrait} {
    max-width: 380px;
  }

  ul {
    background-color: $fill;

    li {
      background-color: $brand-light-blue1;
      padding: 15px;
      position: relative;
      color: $link-color;
      @include background-transition;

      span {
        margin-top: 5px;
        margin-left: 0;
        display: block;

        &.result-text {
          font-size: 1em;
        }

        &.archived-tag {
          font-size: 0.9em;
        }

        @media #{$tablet-landscape} {
          display: inline;
          margin-top: 0;
          margin-left: 7px;
        }
      }

      &.selected,
      &:hover {
        background: $link-color;
        color: $text-color-light;

        > span {
          color: $text-color-light;
        }
      }

      &:nth-of-type(2n) {
        background-color: $fill;

        &.selected,
        &:hover {
          background: $link-color;
          color: $text-color-light;

          > span {
            color: $text-color-light;
          }
        }
      }
    }
  }
}

.notifications-icon {
  margin-left: 20px;
  background-image: url("~/assets/images/icons/icon-star-white.svg");
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  @include all-transition;

  @media #{$desktop} {
    &:hover {
      background-image: url("~/assets/images/icons/icon-star-white-fill.svg");
    }
  }

  &.on {
    background-image: url("~/assets/images/icons/icon-star-white-fill.svg");
  }

  .notification-alert {
    width: 1rem;
    height: 1rem;
    background-color: $red;
    border-radius: 50%;
    right: 0;
    top: 0;
    position: absolute;
    box-shadow: 0 0 1px 1px #0000001a;
    animation: pulse 2s infinite;
    color: $fill;

    p {
      font-size: 0.8rem;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-family: monospace; // Default font used throughout site has stupid numbers
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 67, 67, 0.7);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 67, 67, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 67, 67, 0);
  }
}
