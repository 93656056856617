#globalMessageContainer {
  position: fixed;
  width: 100%;
  background-color: $brand-light-blue1;
  z-index: 80;
  top: 68px;
  width: 100%;
  right: 0;
  display: none;

  @media #{$tablet-landscape} {
    width: calc(100% - 62px);
    top: 100px;
  }

  @media #{$desktop} {
    width: calc(100% - 85px);
  }

  @media #{$desktop-narrow} {
    top: 68px;
  }

  &.login-page {
    width: 100%;
  }

  &.signup-page {
    width: 100%;
    top:0;
  }

  span {
    &.alert {
      margin: 0;
      padding: 22px 45px 20px 45px;
      line-height: 20px;

      &.success {
        color: $fill;
        background: $green1;
        @include background-size(24px, 24px);
        color: $fill;
      }

      &.error {
        color: $fill;
        background: $red;
        @include background-size(24px, 24px);
        color: $fill;

        b {
          color: $fill;
        }
      }
    }
  }
}

span {
  &.alert {
    font-size: 1.4em;
    width: 100%;
    margin: 0 0 15px 0;
    padding: 15px;
    display: inline-block;
    clear: both;
    text-align: center;

    @media #{$tablet-landscape} {
      margin: 0 0 30px 0;
    }

    b {
      color: $brand-dark-blue;
    }

    &.blue {
      background-color: $brand-blue;
      color: $text-color-light;
      border: none;

      b {
        color: $text-color-light;
      }
    }

    &.success {
      color: $text-color-light;
      border: 1px solid $green1;
      background: $green3;
      @include background-size(24px, 24px);
      color: $green1;
    }

    &.error {
      color: $text-color-light;
      border: 1px solid $red;
      background: $red2;
      @include background-size(24px, 24px);
      color: $red;

      b {
        color: $red;
      }

      &.delete {
        padding-right: 55px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;

        b {
          font-weight: 700;
        }
      }
    }

    &.archive {
      margin-bottom: 20px;
      padding: 20px;
      background-color: $brand-light-blue1;
      z-index: 5;
      position: relative;
      @include box-shadow;

      @media #{$tablet-landscape} {
        padding: 30px;
        margin-bottom: 30px;
      }
    }

    &.info {
      position: relative;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-color-light;
      cursor: default;

      // background
      background-color: $red;
      background-image: url("/assets/images/icons/icon-info-white.svg");
      background-position: center left 10px;
      background-size: 20px 20px;
      background-repeat: no-repeat;

      .dismiss-icon {
        position: absolute;
        height: 12px;
        width: 12px;
        right: 12px;
        top: 50%;
        transform: translate(0%, -50%);

        background-image: url("/assets/images/icons/icon-cross-white.svg");
        background-size: 12px 12px;
        background-repeat: no-repeat;

        cursor: pointer;
      }
    }
  }
}

section {
  &.widget {
    &.filter {
      span {
        &.alert {
          margin: 15px 0 0 0;

          @media #{$tablet-landscape} {
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }
}
