/* colour palette */
$text-color: #282f39;
$text-color-light: #fff;
$link-color: #00aff0;
$link-hover-color: #282f39;
$brand-black: #282f39;
$brand-blue: #00aff0;
$brand-light-blue0: #f6fbfd;
$brand-light-blue1: #eef7fc;
$brand-light-blue1-5: #e4eff5;
$brand-light-blue2: #c0ebfa;
$brand-dark-blue: #009cd6;
$red: #cc0000;
$red2: #fbeff0;
$orange: #ff9900;
$orange2: #ffa50017;
$yellow1: #fcf8cf;
$yellow2: #ffd865;
$orange3: #ffbc57;
$green0: #73bc32;
$green1: #5ab00e;
$green2: #66ff99;
$green3: #f0faf5;
$grey0: #c5c5c5;
$grey0-5: #9c9c9c;
$grey1: #696969;
$grey2: #363e46;
$grey3: #bbb;
$grey4: #40474e;
$fill: #fff;

/* mixins - global */
@mixin background-size($width, $height) {
  -webkit-background-size: $width $height;
  -moz-background-size: $width $height;
  -o-background-size: $width $height;
  background-size: $width $height;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow {
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-inset {
  -webkit-box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-reverse {
  -webkit-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-none {
  -webkit-box-shadow: 0 0 0 rgba($fill, 0);
  -moz-box-shadow: 0 0 0 rgba($fill, 0);
  -o-box-shadow: 0 0 0 rgba($fill, 0);
  box-shadow: 0 0 0 rgba($fill, 0);
}

@mixin background-transition {
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  -o-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

@mixin color-transition {
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

@mixin all-transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@mixin no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

@mixin all-transition-slow {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@mixin remove-outline {
  -webkit-outline: none;
  -moz-outline: none;
  outline: none;
}

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

/* fonts */
$letter-spacing: 1px;

/* global breakpoints */
$mobile-portrait: "screen and (min-width: 200px)"; // mobile portrait >= 200px
$mobile-landscape: "screen and (min-width: 480px)"; // mobile landscape >= 480px
$tablet-portrait: "screen and (min-width: 768px)"; // tablet portait >= 768px
$tablet-landscape: "screen and (min-width: 980px)"; // tablet lanscape >= 980px
$desktop: "screen and (min-width: 1280px)"; // desktop >= 1280px
$desktop-mid: "screen and (min-width: 1366px)"; // desktop >= 1366px
$desktop-wide: "screen and (min-width: 1700px)"; // desktop wide >= 1700px
$desktop-narrow: "screen and (min-width: 768px) and (max-height: 780px)"; // desktop >= 1280px and < 780px

// Breakpoints for respond-to mixin, uses max width instead of min
$breakpoints: (
  'sm': (
    max-width: 980px,
  )
) !default;
