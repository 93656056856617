/* Normalize.css(http://necolas.github.com/normalize.css/) + Eric Meyer Reset(http://meyerweb.com/eric/tools/css/reset/) = Freaks Reaet(http://www.mobifreaks.com/?p=943)*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,nav ul,nav li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,nav,output,ruby,section,summary,time,mark,audio,video{
	margin:0;
	padding:0;
	border:0;
	font-size:100%;
	font:inherit;
	vertical-align:baseline;
}
body{
	line-height:1;
}
/* HTML5 Element display reset for non supporting browsers*/
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section{
    display:block;
}
/* Corrects inline-block display not defined in IE6/7/8/9 & FF3*/
audio,canvas,video{
    display:inline-block;
}
/* Prevents modern browsers from displaying 'audio' without controls*/
audio:not([controls]){
    display:none;
}
/* Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4 Known issue:no IE6 support */
[hidden]{
    display:none;
}
/* Base
   1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
      http://clagnut.com/blog/348/#c790
   2. Keeps page centred in all browsers regardless of content height.
   3. Prevents iOS text size adjust after orientation change, without disabling user zoom.
      www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
*/
html{
    font-size:100%; /* 1 */
    overflow-y:scroll; /* 2 */
    -webkit-text-size-adjust:100%; /* 3 */
    -ms-text-size-adjust:100%; /* 3 */
}
/* Addresses font-family inconsistency between 'textarea' and other form elements */
body,button,input,select,textarea{
    font-family:sans-serif;
}
/*	Links - Addresses outline displayed oddly in Chrome */
a:focus{
    outline:thin dotted;
}
/* Improves readability when focused and also mouse hovered in all browsers people.opera.com/patrickl/experiments/keyboard/test */
a:hover,a:active{
    outline:0;
}
/* Typography - Neutralise smaller font-size in 'section' and 'article' in FF4+, Chrome, S5 */
h1{
    font-size:2em;
}
/* Addresses styling not present in IE7/8/9, S5, Chrome */
abbr[title]{
    border-bottom:1px dotted;
}
/* Addresses style set to 'bolder' in FF3+, S4/5, Chrome */
b,strong{
    font-weight:bold;
}
blockquote{
    margin:1em 40px;
}
/* Addresses styling not present in S5, Chrome */
dfn{
    font-style:italic;
}
/* Addresses styling not present in IE6/7/8/9 */
mark{
    background:#ff0;
    color:#000;
}
/* Corrects font family set oddly in IE6, S4/5, Chrome en.wikipedia.org/wiki/User:Davidgothberg/Test59 */
pre,code,kbd,samp{
    font-family:monospace,serif;
    _font-family:'courier new',monospace;
    font-size:1em;
}
/* Improves readability of pre-formatted text in all browsers */
pre{
    white-space:pre;
    white-space:pre-wrap;
    word-wrap:break-word;
}
/*	1. Addresses CSS quotes not supported in IE6/7
	2. Addresses quote property not supported in S4
*/
/* 1 */
blockquote,q{
    quotes:none;
}
/* 2 */
blockquote:before,blockquote:after,q:before,q:after{
    content:'';
    content:none;
}
small{
    font-size:75%;
}
/* Prevents sub and sup affecting line-height in all browsers - gist.github.com/413930 */
sub,sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline;
}
sup{
    top:-0.5em;
}
sub{
    bottom:-0.25em;
}
/* Lists */
article ul,article ol{
    margin-left:0;
    padding:0 0 0 40px;
}
article dd{
    margin:0 0 0 40px;
}
nav ul,nav ol{
    list-style:none;
    list-style-image:none;
}
/* Embedded content - Improves image quality when scaled in IE7 - code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
*/
img{
    -ms-interpolation-mode:bicubic; /* 1 */
}
/* Corrects overflow displayed oddly in IE9 */
svg:not(:root){
    overflow:hidden;
}
/* Define consistent border, margin, and padding */
fieldset{
    border:1px solid #c0c0c0;
    margin:0 2px;
    padding:0.35em 0.625em 0.75em;
}
/*	1. Corrects color not being inherited in IE6/7/8/9
	2. Corrects alignment displayed oddly in IE6/7
*/
legend{
    border:0; /* 1*/
}
/*	1. Corrects font size not being inherited in all browsers
	2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
	3. Improves appearance and consistency in all browsers
*/
button,input,select,textarea{
    font-size:100%; /* 1*/
    margin:0; /* 2*/
    vertical-align:baseline; /* 3*/
}
/* Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet */
button,input{
    line-height:normal;
}
/*	1. Improves usability and consistency of cursor style between image-type 'input' and others
	2. Corrects inability to style clickable 'input' types in iOS
	3. Removes inner spacing in IE7 without affecting normal text inputs. Known issue:inner spacing remains in IE6
*/
button,input[type="button"],input[type="reset"],input[type="submit"]{
    cursor:pointer; /* 1*/
    -webkit-appearance:button; /* 2*/
}
/*	1. Addresses box sizing set to content-box in IE8/9
	2. Removes excess padding in IE8/9
*/
input[type="checkbox"],input[type="radio"]{
    box-sizing:border-box; /* 1*/
    padding:0; /* 2*/
}
/*	1. Addresses appearance set to searchfield in S5, Chrome
	2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
*/
input[type="search"]{
    -webkit-appearance:textfield; /* 1*/
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; /* 2*/
    box-sizing:content-box;
}
/* Removes inner padding that is displayed in S5, Chrome on OS X */
input[type="search"]::-webkit-search-decoration{
    -webkit-appearance:none;
}
/* Removes inner padding and border in FF3+ - www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
button::-moz-focus-inner,input::-moz-focus-inner{
    border:0;
    padding:0;
}
/*	1. Removes default vertical scrollbar in IE6/7/8/9
	2. Improves readability and alignment in all browsers
*/
textarea{
    overflow:auto; /* 1*/
    vertical-align:top; /* 2*/
}
/* Tables - Remove most spacing between table cells */
table{
    border-collapse:collapse;
    border-spacing:0;
}
/* apply a natural box layout model to all elements, but allowing components to change */
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}
img {
	vertical-align: bottom;
}
ul {
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
select {
  cursor:pointer;
}
