h1,
h2,
h3 {
  margin-bottom: 15px;
  letter-spacing: $letter-spacing;
  color: $brand-blue;
}

h1 {
  font-size: 2.4em;

  @media #{$desktop} {
    font-size: 2.6em;
  }
}

h2 {
  font-size: 2em;

  @media #{$desktop} {
    font-size: 2.2em;
  }
}

h3 {
  font-size: 1.6em;

  @media #{$desktop} {
    font-size: 1.8em;
  }
}

p {
  margin: 0 0 30px 0;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 400;

  &.small {
    font-size: 1.1em;
  }

  &.sub-heading {
    width: auto;
    margin-bottom: 15px;
    top: 0;

    &.drag-items {
      display: none;

      @media #{$tablet-portrait} {
        display: block;
      }
    }
  }

  &.margin-narrow {
    margin-bottom: 15px;

    span {
      &.tag {
        margin-left: 0;
      }
    }
  }

  &.text-2-across {
    @media #{$tablet-landscape} {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -moz-column-gap: 3em;
      -webkit-column-gap: 3em;
      column-gap: 3em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.text-3-across {
    @media #{$tablet-landscape} {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -moz-column-gap: 3em;
      -webkit-column-gap: 3em;
      column-gap: 3em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &.icon-text {
    img {
      width: 16px;
      margin-right: 15px;
    }
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  span {
    &.tag {
      text-transform: none;
    }
  }
}

span {
  &.light {
    color: $grey3;
  }

  &.large {
    font-size: 2.6em;

    @media #{$desktop} {
      font-size: 3.2em;
    }
  }

  &.tag {
    margin-left: 7px;
    font-size: 1em;
    color: $grey3;
    text-transform: lowercase;

    a {
      color: $link-color;
    }
  }

  &.tag-block {
    margin: 0 7px 7px 0;
    padding: 5px 7px;
    font-size: 1em;
    float: left;
    color: $text-color;
    border: 1px solid $grey3;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  @include color-transition;
  @include remove-outline;

  &:focus,
  &:active {
    @include remove-outline;
  }

  &.click-area {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    color: $link-hover-color;
  }
}

.underline {
  padding-bottom: 15px;
  border-bottom: 1px solid $brand-light-blue2;

  @media #{$tablet-portrait} {
    margin-bottom: 25px;
  }
}

span.brand-blue {
  color: $brand-blue;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.default-font-size {
  font-size: 0.9rem;
}
