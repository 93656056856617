input[type="text"],
input[type="submit"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea,
select,
button {
  min-height: 42px;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: none;
  font-family: "Raleway", sans-serif;
  background-color: $fill;
  letter-spacing: $letter-spacing;
  outline: none;

  &::placeholder {
    color: $grey3;
  }

  &:not(.mat-calendar-body-cell) {
    font-size: 1.4em;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.radio-group {
  .radio-selector {
    display: flex;

    label {
      flex-basis: 50%;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.radio-selector {
  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: $brand-blue;
      color: $text-color-light;
    }
  }

  label {
    margin-bottom: 30px;
    margin: 0 10px 30px 0;
    padding: 10px;
    background-color: $fill;
    border: 1px solid $brand-blue;
    font-family: "Raleway", sans-serif;
    font-size: 1.4em;
    color: $link-color;
    cursor: pointer;
    float: left;
    @include background-transition;

    &:hover {
      background-color: $brand-blue;
      color: $text-color-light;
    }
  }
}

.check-selector-back {
  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    margin: 0 30px 30px 0;
    padding-left: 1.95em;
    cursor: pointer;
    float: left;
    font-family: "Raleway", sans-serif;
    font-size: 1.4em;
    letter-spacing: $letter-spacing;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    border: 1px solid $brand-blue;
    background: $fill;
    border-radius: 0;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    top: 0;
    left: 4px;
    font-family: "Raleway", sans-serif;
    letter-spacing: $letter-spacing;
    color: $brand-blue;
    transition: all 0.2s;
    font-size: 1em;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    border: 1px solid $grey3;
    background-color: $fill;
  }
  [type="checkbox"]:disabled:checked + label:after {
    color: $text-color;
  }
  [type="checkbox"]:disabled + label {
    color: $text-color;
  }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  margin: 0 0 30px 0;
  border: 1px solid $brand-light-blue2;
  @include all-transition;

  &:focus {
    border: 1px solid $brand-blue;
  }

  &.error {
    border: 1px solid $red;
    background-color: $red2;

    &:focus {
      border: 1px solid $red;
    }
  }

  &.warning {
    border: 1px solid $orange;
    background-color: $orange2;

    &:focus {
      border: 1px solid $orange;
    }
  }
}

/* Change Autocomplete styles in Chrome */

input.error:-webkit-autofill,
input.error:-webkit-autofill:hover,
input.error:-webkit-autofill:focus input.error:-webkit-autofill,
textarea.error:-webkit-autofill,
textarea.error:-webkit-autofill:hover textarea.error:-webkit-autofill:focus,
select.error:-webkit-autofill,
select.error:-webkit-autofill:hover,
select.error:-webkit-autofill:focus {
  border: 1px solid $red;
  -webkit-box-shadow: 0 0 0 1000px $red2 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.error-msg,
.warning-msg {
  display: none;
  margin-top: 5px;
  font-size: 1em;

  &.on {
    display: block;
  }

  &.error-msg-inline {
    padding: 5px 0 0 30px;
  }
}

.error-msg {
  color: $red;
}

.warning-msg {
  color: $orange;
}

textarea {
  width: 80%;
  font-size: 1.4em;
  min-height: 70px;

  @media #{$mobile-landscape} {
    width: 100%;
  }

  @media #{$tablet-portrait} {
    width: 70%;
  }

  @media #{$desktop-wide} {
    width: 80%;
  }

  &.error {
    border: 1px solid $red;
    background-color: $red2;

    &:focus {
      border: 1px solid $red;
    }
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="file"],
  input[type="button"],
  textarea {
    width: 100%;
    margin: 0 0 30px 0;
    border: 1px solid $brand-light-blue2;
    @include all-transition;

    &:focus {
      border: 1px solid $brand-blue;
    }

    @media #{$tablet-portrait} {
      width: 70%;
      float: right;
    }

    @media #{$desktop-wide} {
      width: 80%;
    }
  }

  .form-group-right {
    @media #{$tablet-portrait} {
      width: 70%;
      float: right;
    }

    @media #{$desktop-wide} {
      width: 80%;
    }
  }

  p {
    margin: 0 0 5px 0;

    &.check-label {
      margin-bottom: 7px;
    }

    @media #{$tablet-portrait} {
      width: 30%;
      float: left;
      position: relative;
      top: 12px;

      &.check-label {
        top: -2px;
        margin-bottom: 7px;
      }
    }

    @media #{$desktop-wide} {
      width: 20%;
    }
  }
}

select {
  width: 80%;
  margin-bottom: 15px;
  background: url(~/assets/images/icons/icon-arrow-down-blue.svg)
    $brand-light-blue1 no-repeat right 10px top 50%;
  @include background-size(16px, 10px);

  @media #{$mobile-landscape} {
    width: 100%;
  }

  @media #{$tablet-portrait} {
    width: 70%;
  }

  @media #{$desktop-wide} {
    width: 80%;
  }

  &.grid-item-small {
    margin-bottom: 0;
  }

  &.error {
    border: 1px solid $red;
    color: $red;
    background-color: $red2;
    background: url(~/assets/images/icons/icon-arrow-down-red.svg) $red2
      no-repeat right 10px top 50%;
    @include background-size(16px, 10px);

    &:focus {
      border: 1px solid $red;
    }
  }

  @media #{$tablet-portrait} {
    &.ten,
    &.twenty,
    &.thirty,
    &.forty,
    &.fifty,
    &.sixty,
    &.seventy,
    &.eighty,
    &.ninety {
      padding-right: 30px;
    }
  }

  &.disabled {
    background-image: url(~/assets/images/icons/icon-arrow-down-white.svg);
    background-color: $grey3 !important;
    color: white !important;
    pointer-events: none !important;
  }
}

#addEditTypes_AccountTypesContainer {
  margin-bottom: 30px;

  .account-type-block {
    position: relative;
    width: 100%;
    border: 1px solid $brand-light-blue2;
    padding: 15px;
    margin-bottom: 15px;

    &:first-child {
      margin-top: 0;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    select {
      float: left;
      width: calc(50% - 7.5px);
      margin: 0;
    }

    .checkboxDropdown {
      float: right;
      clear: none;
      width: calc(50% - 7.5px);

      label {
        font-size: 1.4em;
      }

      .checkboxDropdownDropdown {
        .checkbox-text {
          font-size: 1em;
        }
      }
    }

    .remove {
      float: right;
      margin-top: 15px;
    }

    .error-msg {
      display: inline-block;
      text-align: center;
      width: 100%;
      padding-top: 8px;
      clear: both;
    }
  }

  .check-selector-container {
    margin: 0 0 15px 0;

    .check-selector {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  #addEditTypes_AddAccountType {
    .add-item {
      float: right;
    }
  }
}

.check-selector {
  float: left;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  position: relative;
  top: 5px;
  font-size: 1.4em;
  color: $text-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include color-transition;

  input[type="checkbox"] {
    margin: 0;
  }

  &:hover {
    color: $link-color;
  }

  &.error {
    color: $red;
    .checkmark {
      border: 1px solid $red;
      background-color: $red2;

      &:focus {
        border: 1px solid $red;
      }
    }
  }

  &.disabled-check-selector {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);

    .check-selector-input {
      pointer-events: none;
    }
    .checkmark {
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0.2);
    }

    .checkmark::after {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
}

/* Hide the browser's default checkbox */
.check-selector input {
  position: absolute;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: $fill;
  border: 1px solid $brand-blue;
  @include background-transition;
}

/* On mouse-over, add a grey background color */
.check-selector:hover input ~ .checkmark {
  background-color: $brand-light-blue1;
}

/* When the checkbox is checked, add a blue background */
.check-selector input:checked ~ .checkmark {
  background-color: $fill;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-selector input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-selector .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid $brand-blue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-group {
  display: table;
  width: 100%;
  margin-bottom: 30px;

  &.mb-0 {
    margin-bottom: 0;
  }

  .add-item {
    clear: both;

    @media #{$tablet-portrait} {
      float: right;
    }
  }

  &.avatar-container {
    border: 1px solid $brand-light-blue2;
    padding: 15px;

    @media #{$tablet-portrait} {
      display: flex;
    }

    label {
      &.ava-img {
        display: flex;
        align-items: center;
        width: 100px;
        height: 100px;
        margin: 0 auto 15px auto;
        float: none;
        border: 3px solid $brand-black;
        position: relative;
        @include border-radius(50px);

        img {
          width: 34px;
          border: none;

          &.avatar {
            width: 50px;
            height: auto;
            border: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include border-radius(0);
          }

          &.uploaded {
            width: 94px;
            height: 94px;
            @include border-radius(50%);
          }
        }

        @media #{$tablet-portrait} {
          margin: 0;
          top: 0;
          text-align: left;
        }
      }

      &.add-file {
        width: 100%;
      }
    }

    input[type="text"] {
      &.file-path {
        width: 100%;

        @media #{$desktop} {
          width: 48%;
        }
      }
    }

    .avatar-upload-container {
      flex-grow: 1;

      @media #{$tablet-portrait} {
        margin-left: 20px;
      }

      @media #{$desktop} {
        display: flex;
        align-items: center;
      }
    }
  }

  label {
    margin-bottom: 15px;
    float: left;
    font-size: 1.4em;

    @media #{$tablet-portrait} {
      width: 30%;
      padding-right: 15px;
      float: left;
      position: relative;
      top: 12px;

      &.basic {
        position: relative;
        top: 0;
      }
    }

    @media #{$desktop-wide} {
      width: 20%;
    }

    &.check-selector {
      width: auto;
      margin-right: 15px;
      margin-bottom: 30px;
    }

    &.add-file {
      margin: 0 0 15px 0;
      background: url(~/assets/images/icons/icon-upload-blue.svg)
        $brand-light-blue1 no-repeat right 10px top 50%;

      &:hover {
        background: url(~/assets/images/icons/icon-upload-white.svg) $brand-blue
          no-repeat right 10px top 50%;
      }

      @media #{$desktop} {
        margin-right: 20px;
      }
    }

    &.remove-file {
      min-width: 175px;
      margin: 0;
      background: url(~/assets/images/icons/icon-delete-blue.svg)
        $brand-light-blue1 no-repeat right 10px top 50%;

      &:hover {
        background: url(~/assets/images/icons/icon-delete-white.svg) $brand-blue
          no-repeat right 10px top 50%;
      }
    }

    &.add-file,
    &.remove-file {
      width: 100%;
      padding: 14px 15px;
      overflow: hidden;
      position: relative;
      display: block;
      top: 0;
      text-align: left;
      color: $link-color;
      font-size: 1.6em;
      @include background-size(16px, 16px);
      @include background-transition;

      &:hover {
        color: $text-color-light;
        @include background-size(16px, 16px);
      }

      [type="file"] {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        filter: alpha(opacity=0);
        opacity: 0;
        text-align: right;
        cursor: pointer;
      }

      @media #{$tablet-portrait} {
        width: 100%;
      }

      @media #{$desktop} {
        margin-bottom: 0;
      }
    }
  }

  p {
    &.basic {
      width: 100%;
      margin: 0;
      float: left;

      @media #{$tablet-portrait} {
        width: 70%;
        float: right;
      }

      @media #{$desktop-wide} {
        width: 80%;
      }
    }
  }

  input[type="text"] {
    width: 80%;

    @media #{$mobile-landscape} {
      width: 100%;
    }

    @media #{$tablet-portrait} {
      width: 70%;
    }

    @media #{$desktop-wide} {
      width: 80%;
    }

    &.file-path {
      @media #{$desktop} {
        width: 35%;
        position: relative;
      }
    }
  }

  .error-msg {
    float: left;

    @media #{$tablet-portrait} {
      &:not(.left) {
        width: 70%;
        float: right;
      }
    }

    @media #{$desktop-wide} {
      width: 80% !important;
    }
  }

  .check-selector-container {
    clear: both;

    @media #{$tablet-portrait} {
      clear: none;

      &.full {
        width: 100%;
      }
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  textarea,
  select,
  .check-selector-container {
    margin-bottom: 0;
  }

  &.drag-items {
    .clear {
      clear: none;
    }
    @media #{$tablet-portrait} {
      display: none;
    }
  }

  &.tag-container {
    margin-bottom: 20px;

    button {
      &.tag {
        width: auto;
        margin-bottom: 10px;
      }
    }

    .main-search {
      margin-bottom: 30px;
    }
  }

  &.fifty {
    width: 46%;
    float: left;
    position: relative;

    &.right {
      float: right;
    }
  }

  &.sub-search-filter {
    display: flex;
    padding: 10px 15px 15px 15px;
    margin-bottom: 0;
    background: $fill;
    border-left: 1px solid $brand-light-blue2;
    border-right: 1px solid $brand-light-blue2;
    border-bottom: 1px solid $brand-light-blue2;

    label {
      &.check-selector {
        margin-bottom: 20px;
      }
    }
  }

  &.full-width {
    label,
    p,
    input,
    textarea {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.form-group-nested {
  .item-multi-option {
    margin: 0 0 30px 0;
    padding: 15px;
    clear: both;
    border: 1px solid $brand-light-blue2;

    &.current {
      border: 1px solid $orange;
    }

    &.previous {
      border: 1px solid red;
    }

    select,
    textarea {
      margin-bottom: 15px;

      @media #{$tablet-portrait} {
        width: 100%;
        float: none;

        &.twenty {
          width: 20%;
        }

        &.seventy-five {
          width: 75%;
        }

        &.right {
          float: right;
        }
      }
    }

    .remove {
      float: right;
      clear: both;
    }
  }
}

button,
input[type="submit"] {
  z-index: 1;
  @include background-transition;

  &.btn {
    width: 100%;
    padding: 10px 30px 10px 15px;
    text-align: left;
    color: $link-color;
    font-size: 1.4em;
    display: block;
    background: url(~/assets/images/icons/icon-arrow-right-blue.svg)
      $brand-light-blue1 no-repeat right 10px top 50%;
    box-sizing: border-box;

    @include background-size(10px, 16px);
    @include background-transition;
    @include box-shadow();
    @include border-radius(4px);

    &:hover {
      background-color: $fill;
    }

    &.auto-width {
      width: auto;
      display: table;
    }

    &.white {
      background-color: $fill;
      color: $brand-blue;

      &:hover {
        background-color: $brand-light-blue0;
      }
    }

    &.black {
      color: $text-color-light;
      background: url(~/assets/images/icons/icon-arrow-right-white.svg)
        $brand-black no-repeat right 10px top 50%;

      @include background-size(10px, 16px);
      @include border-radius(4px);

      &:hover {
        background-color: $grey1;
      }
    }

    &.blue {
      background-color: $brand-blue;
      color: $text-color-light;
      background: url(~/assets/images/icons/icon-arrow-right-white.svg)
        $brand-blue no-repeat right 10px top 50%;
      @include background-size(10px, 16px);

      &:hover {
        background-color: $brand-dark-blue;
      }
    }

    &.no-icon {
      padding-right: 15px;
      background-image: none !important;

      img {
        display: none !important;
      }
    }

    &.disabled {
      background-color: $grey0 !important;
      color: white !important;
      pointer-events: none !important;
    }
  }

  &.small {
    width: auto;
    padding: 5px;
    min-height: 0;
    font-size: 1.1em;

    border: 1px solid $brand-blue;
    background-color: $fill;
    color: $link-color;
    font-weight: 700;

    &:hover {
      background-color: $brand-dark-blue;
      color: $text-color-light;
    }
  }

  &.toggle-search {
    width: 42px;
    margin-right: 15px;
    font-size: 0;
    float: right;
    background: url(~/assets/images/icons/icon-search-white.svg) $brand-black
      no-repeat center center;
    @include background-size(20px, 20px);

    &.on {
      width: 90%;
      position: fixed;
      top: 13px;
      left: 5%;
      font-size: 1.4em;
      color: $text-color-light;
      background: url(~/assets/images/icons/icon-cross-white.svg) no-repeat
        center right;
      @include background-size(16px, 16px);

      span {
        margin-right: 15px;
        display: block;
        text-align: right;
      }
    }
  }

  @media #{$tablet-portrait} {
    &.toggle-search {
      display: none;
    }
  }

  &.log {
    width: auto;
    margin: 13px 15px 0 0;
    padding-right: 40px;
    float: right;
    background: url(~/assets/images/icons/icon-plus-white.svg) $brand-black
      no-repeat right 10px top 50%;
    color: $text-color-light;

    @include background-size(21px, 21px);
    @include border-radius(4px);
    @include box-shadow;

    span {
      display: none;
      font-size: 1em;
    }

    &:hover {
      background-color: $grey1;
    }

    @media #{$tablet-landscape} {
      margin: 29px 30px 0 0;
    }

    @media #{$desktop} {
      span {
        display: inline-block;
      }
    }

    @media #{$desktop-narrow} {
      margin: 13px 15px 0 0;
    }
  }

  &.mail {
    width: 40px;
    margin: 0;
    background: url(~/assets/images/icons/icon-export-white.svg) no-repeat
      center;
    @include background-size(21px, 21px);

    @media #{$tablet-portrait} {
      width: auto;
      padding-right: 40px;
      right: 162px;
      background: url(~/assets/images/icons/icon-export-white.svg) $fill
        no-repeat right 10px top 50%;
      @include background-size(21px, 21px);
    }

    span {
      display: none;
      font-size: 1em;

      @media #{$tablet-portrait} {
        display: block;
      }
    }

    &:hover {
      color: $text-color;
      background: url(~/assets/images/icons/icon-export.svg) $fill no-repeat
        right 10px top 50%;
      @include background-size(21px, 21px);
    }

    @media #{$desktop} {
      span {
        display: inline-block;
      }
    }
  }

  &.person {
    width: 40px;
    margin: 0;
    background: url(~/assets/images/icons/icon-profile-white.svg) no-repeat
      center;
    @include background-size(18px, 21px);

    @media #{$tablet-portrait} {
      width: auto;
      padding-right: 40px;
      background: url(~/assets/images/icons/icon-profile-white.svg) $fill
        no-repeat right 10px top 50%;
      @include background-size(18px, 21px);
    }

    span {
      display: none;
      font-size: 1em;

      @media #{$tablet-portrait} {
        display: block;
      }
    }

    &:hover {
      color: $text-color;
      background: url(~/assets/images/icons/icon-profile.svg) $fill no-repeat
        right 10px top 50%;
      @include background-size(18px, 21px);
    }

    @media #{$desktop} {
      span {
        display: inline-block;
      }
    }
  }

  &.add {
    margin: 0;
    padding-right: 40px;
    float: right;
    height: 100%;
    background: url(~/assets/images/icons/icon-plus-white.svg) no-repeat center
      center;
    @include background-size(21px, 21px);

    img {
      width: 21px;
      display: block;
    }

    &:hover {
      background: url(~/assets/images/icons/icon-plus-blue.svg) $fill no-repeat
        center center;
      @include background-size(21px, 21px);
    }
  }

  &.search {
    margin: 0;
    padding-right: 40px;
    float: right;
    font-size: 0;
    height: 100%;
    background: url(~/assets/images/icons/icon-search-white.svg) no-repeat
      center center;
    @include background-size(21px, 21px);

    img {
      width: 21px;
      display: block;
    }

    &:hover {
      background: url(~/assets/images/icons/icon-search-blue.svg) $fill
        no-repeat center center;
      @include background-size(21px, 21px);
    }

    &.on {
      background: url(~/assets/images/icons/icon-search-blue.svg)
        $brand-light-blue1 no-repeat center center;
      @include background-size(21px, 21px);
    }
  }

  &.tag {
    margin-right: 10px;
    margin-bottom: 15px;
    padding-right: 40px;
    color: $text-color-light;
    background-color: $brand-blue;
    background: url(~/assets/images/icons/icon-cross-white.svg) $brand-blue
      no-repeat right 15px top 50%;
    @include background-size(12px, 12px);

    &:hover {
      background-color: $brand-dark-blue;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &[data-mandatory="true"] {
      background-image: none;
      padding-right: 11px;

      &:hover {
        background-color: $brand-blue;
        cursor: default;
      }
    }
  }

  &.download {
    height: 100%;
    padding-right: 40px;
    background: url("~/assets/images/icons/icon-download-white.svg") no-repeat
      center center;
    @include background-size(22px, 22px);

    &:hover {
      background: url("~/assets/images/icons/icon-download-blue.svg") $fill
        no-repeat center center;
      @include background-size(22px, 22px);
    }
  }

  &.filter {
    padding-right: 30px;
    background-image: url("~/assets/images/icons/icon-filter.svg");

    @include background-size(14px, auto);

    &.clear-filters-btn {
      width: -moz-fit-content;
      width: fit-content;
    }
  }

  &.back-bar {
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 40px;
    background: url(~/assets/images/icons/icon-arrow-left-blue.svg) $fill
      no-repeat left 20px top 50%;
    @include background-size(10px, 16px);
    @include all-transition;

    @media #{$tablet-landscape} {
      padding-left: 50px;
      background-position: left 30px top 50%;
    }

    &:hover {
      color: $text-color;
      background: url(~/assets/images/icons/icon-arrow-left.svg) $fill no-repeat
        left 20px top 50%;
      @include background-size(10px, 16px);

      @media #{$tablet-landscape} {
        background-position: left 30px top 50%;
      }
    }
  }

  &.edit {
    height: 20px;
    min-height: 0;
    padding: 0 6px;
    font-size: 0;
    background: url(~/assets/images/icons/icon-edit-blue.svg) no-repeat;
    @include background-size(16px, 16px);

    &:hover {
      background: url(~/assets/images/icons/icon-edit.svg) no-repeat;
      @include background-transition;
      @include background-size(16px, 16px);
    }
  }

  &.remove {
    font-size: 0;
  }
}

a {
  &.btn {
    width: 100%;
    padding: 15px 30px 15px 15px;
    text-align: left;
    color: $link-color;
    font-size: 1.6em;
    display: block;
    background: url(~/assets/images/icons/icon-arrow-right-blue.svg)
      $brand-light-blue1 no-repeat right 10px top 50%;
    box-sizing: border-box;

    @include background-size(10px, 16px);
    @include background-transition;
    @include box-shadow();
    @include border-radius(4px);

    &:hover {
      color: $link-color;
      background-color: $fill;
    }

    &.auto-width {
      width: auto;
      display: table;
    }

    &.white {
      background-color: $fill;
      color: $brand-blue;

      &:hover {
        background-color: $brand-light-blue0;
      }
    }

    &.black {
      color: $text-color-light;
      background: url(~/assets/images/icons/icon-arrow-right-white.svg)
        $brand-black no-repeat right 10px top 50%;

      @include background-size(10px, 16px);
      @include border-radius(4px);

      &:hover {
        background-color: $grey1;
      }
    }

    &.blue {
      background-color: $brand-blue;
      color: $text-color-light;
      background: url(~/assets/images/icons/icon-arrow-right-white.svg)
        $brand-blue no-repeat right 10px top 50%;

      @include background-size(10px, 16px);

      &:hover {
        background-color: $brand-dark-blue;
      }
    }

    &.no-icon {
      padding-right: 15px;
      background-image: none !important;

      img {
        display: none !important;
      }
    }
  }

  &.arrow {
    padding-right: 15px;
    position: relative;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;

    @include background-size(10px, 16px);
    @include all-transition;

    &:hover {
      color: $text-color;
      @include background-size(10px, 16px);
    }
  }
}

.btn-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(~/assets/images/icons/icon-loader.svg) $fill no-repeat center
    center;
  font-size: 0;
  @include background-size(40px, 40px);
}

.remove {
  width: auto;
  min-height: 18px;
  line-height: 18px;
  margin: 0;
  padding: 0 20px 0 0;
  float: left;
  font-size: 1.1em;
  color: $link-color;
  cursor: pointer;
  background: url(~/assets/images/icons/icon-delete-blue.svg) $fill no-repeat
    center right;
  @include background-size(16px, 16px);
  @include all-transition;

  &:hover {
    color: $text-color;
    background: url(~/assets/images/icons/icon-delete.svg) $fill no-repeat
      center right;
    @include background-size(16px, 16px);
  }
}

.add-item {
  width: auto;
  min-height: 21px;
  line-height: 21px;
  margin: 0;
  padding: 0 30px 0 0;
  float: left;
  font-size: 1.4em;
  color: $link-color;
  cursor: pointer;
  background: url(~/assets/images/icons/icon-plus-blue.svg) $fill no-repeat
    center right;
  @include background-size(21px, 21px);
  @include all-transition;

  span {
    font-size: 1.1em;

    &.addition {
      display: none;

      &.on {
        display: inline-block;
      }
    }
  }

  &:hover {
    color: $text-color;
    background: url(~/assets/images/icons/icon-plus.svg) $fill no-repeat center
      right;
    @include background-size(21px, 21px);
  }
}

.edit-field {
  width: 100%;
  margin-bottom: 30px;
  min-height: 100px;
  @include background-transition;

  &:active,
  &:focus {
    border: none;
    outline: none;
    border-bottom: 1px dashed $brand-blue;
  }

  &:hover {
    border-bottom: 1px dashed $brand-blue;
    background-color: $brand-light-blue1;
    cursor: pointer;
  }

  &:focus {
    padding: 5px;
    background-color: $yellow1;
    font-style: italic;
    cursor: default;
    border: 1px dashed $brand-blue;
  }
}

.people-card-error-container {
  position: relative;

  width: 100%;
  height: 100%;

  .people-card-error-msg {
    position: absolute;
    left: 15rem;
    bottom: 0;

    display: block;

    white-space: nowrap;
  }
}

.edit-item {
  margin-bottom: 15px;

  &:first-of-type {
    margin-bottom: 15px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.indent {
    @media #{$tablet-portrait} {
      margin-left: 30px;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;

    &.off {
      display: none;
    }

    span {
      &.light {
        font-size: 1em;
      }
    }
  }

  img {
    &.edit-btn {
      width: 16px;
      height: 16px;
      margin: 0;
      cursor: pointer;
      opacity: 0.25;
    }
  }

  textarea {
    &.inline-edit {
      min-height: 150px;
    }
  }

  &.edit-on {
    background-color: $yellow1;
    padding: 10px;
    border: 1px dashed $brand-blue;

    @media #{$tablet-portrait} {
      width: max-content;
    }

    input {
      margin-bottom: 0;
    }

    .edit-option {
      img {
        &.icon-type {
          margin-right: 0;
        }

        &.edit-btn {
          display: none;
        }
      }
    }
  }

  .edit-option {
    display: flex;
    align-items: center;

    .inline-edit {
      display: flex;
      flex-grow: 1;
      margin: 0 10px;

      @media #{$tablet-portrait} {
        min-width: 200px;
      }

      input {
        flex-grow: 1;
      }
    }

    img {
      &.icon-type,
      &.icon-delete {
        width: 16px;
        margin-right: 15px;
      }

      &.icon-type {
        float: left;
      }

      &.icon-add {
        width: 21px;
        margin-right: 15px;
        cursor: pointer;
        align-self: flex-end;
        position: relative;
        bottom: 11px;

        &.no-current-items {
          margin-left: 25px;
          bottom: 0;
        }
      }

      &.edit-btn {
        margin-left: 10px;
        transition: opacity 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }

    svg {
      &.mat-datepicker-toggle-default-icon {
        fill: $brand-blue;
      }
    }

    .repeat-option-container {
      flex-grow: 1;

      .repeat-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        @media #{$tablet-portrait} {
          flex-direction: row;
        }

        label {
          margin-bottom: 10px;

          @media #{$tablet-portrait} {
            margin-left: 15px;
            margin-bottom: 0;
          }
        }

        label,
        .inline-edit {
          width: 90%;
          text-align: left;
          font-size: 1.4em;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

span.card-number-margin {
  margin-right: 15px;
}

.edit-items-multi-toggle {
  float: right;

  img {
    &.edit-btn {
      width: 16px;
      height: 16px;
      margin: 0;
      float: right;
      cursor: pointer;
      opacity: 0.25;
      transition: opacity 0.3s ease-in-out;
    }

    &.close-btn {
      margin-right: 0;
      cursor: pointer;
    }
  }

  &:hover,
  &.on {
    > {
      img {
        &.edit-btn {
          opacity: 1;
        }
      }
    }
  }

  &.on {
    .close-btn {
      display: block;
    }
    .edit-btn {
      display: block;
    }
  }
}

.edit-items-multi-container {
  &.on {
    padding: 15px;
    border: 1px dashed $brand-blue;

    .edit-item {
      img {
        &.edit-btn {
          margin-left: 0;
          opacity: 0.25;
        }
      }

      &:hover {
        > p {
          color: $link-color;
          @include all-transition;
        }

        > a {
          img {
            &.edit-btn {
              opacity: 1;
              transition: opacity 0.3s ease-in-out;
            }
          }
        }

        > {
          img {
            &.edit-btn {
              opacity: 1;
              transition: opacity 0.3s ease-in-out;
            }
          }
        }
      }

      &.edit-on {
        background-color: $yellow1;
        padding: 10px;
        border: 1px dashed $brand-blue;

        img {
          &.edit-btn {
            opacity: 1;
          }
        }

        input {
          margin-bottom: 0;
        }
      }
    }

    #primaryAddressContainer {
      &.edit-item.edit-on {
        padding: 0;
        border: none;

        .edit-off.edit-active {
          &:hover {
            background-color: $yellow1;
          }
        }
      }
    }

    .edit-option {
      img {
        &.edit-btn {
          margin-left: auto;
        }
      }

      p {
        margin-right: 15px;
      }

      input {
        width: calc(100% - 60px);
      }
    }
  }

  .edit-option {
    display: flex;

    align-items: center;
  }
}

.add-secondary-address-toggle {
  position: absolute;
  bottom: 5px;
  right: 20px;
  font-size: 1.4em;

  &.additional-addresses {
    position: relative;
    bottom: 0;
    right: 0;
    float: right;

    margin-bottom: 15px;
  }

  .add-secondary-address-link {
    color: $link-color;

    &:hover {
      color: $brand-black;
    }
  }
}

.edit-on {
  p {
    color: $link-color;
  }
}

.edit-off {
  &.edit-active {
    cursor: pointer;
    border: 1px dashed $brand-blue;
    padding: 10px;

    &:hover {
      background-color: $brand-light-blue1;
      @include all-transition;

      a > img,
      > img {
        &.edit-btn {
          opacity: 1;
          @include all-transition;
        }
      }
    }
  }

  a > span {
    &.light {
      font-size: 1em;
      cursor: default;
    }

    &.tag {
      cursor: default;
    }
  }
}

span {
  &.info {
    width: 18px;
    height: 18px;
    margin-right: 9px;
    display: block;
    float: left;
    position: relative;
    top: -2px;
    z-index: 1;
    cursor: pointer;
    background: url(~/assets/images/icons/icon-info-white.svg) $brand-blue
      no-repeat center center;
    @include border-radius(10px);
    @include background-size(5px, 10px);

    &:hover {
      background-color: $brand-black;
    }
  }
}

img {
  &.loader {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
}

.tag-block-container {
  span {
    &.tag {
      margin: 0;
      display: block;
    }
  }
}

#addEditRoles .tag-block-container {
  min-height: 65px;

  .drag-items-container {
    display: none;

    @media #{$tablet-portrait} {
      display: block;
    }
  }

  .connected-sortable {
    min-height: 150px;
  }
}

// Tooltip
.add-tooltip {
  cursor: default;
}

.si-tooltip {
  display: none;
  padding: 15px;
  position: fixed;
  background-color: $brand-black;
  color: $text-color-light;
  border: none;
  border-radius: 0;
  z-index: 99999 !important;
  @include box-shadow;
}

.tooltip-text {
  z-index: 1;
  position: relative;
}

img {
  &.tooltip {
    width: 18px;
    height: 18px;
  }
}

.truncate {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  line-height: 1.4em;
  text-overflow: ellipsis;
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}

.show-more {
  position: absolute;
  top: 0;
  right: 0;
  background: $fill;
  color: $link-color;
  z-index: 2;

  &:before,
  &:after {
    width: 12px;
    height: 20px;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &:before {
    content: "...";
    background: $fill;
    left: -12px;
  }

  &:after {
    content: "";
    background: linear-gradient(to left, $fill 0%, rgba($fill, 0) 100%);
    left: -24px;
  }

  &-off {
    position: relative;
    top: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

// multi select
.checkboxDropdown {
  position: relative;
  clear: both;
  background: url(~/assets/images/icons/icon-arrow-down-blue.svg)
    $brand-light-blue1 no-repeat right 10px top 50%;
  @include background-size(16px, 10px);

  &.error {
    border: 1px solid $red;
    background-color: $red2;

    label {
      color: $red;
    }
  }
}

label {
  &.no-float {
    @media #{$tablet-portrait} {
      float: none;
    }
  }
}

.checkboxDropdownElement {
  min-height: 42px;
  color: $text-color;
  padding: 0 15px;
  cursor: pointer;
  overflow: hidden;
  @include border-radius(0);

  span,
  label {
    position: relative;
    top: 13px;
    width: auto;
  }
}

.checkboxDropdownDropdown {
  max-height: 190px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid $brand-light-blue2;
  background: $fill;
  position: fixed;
  width: min-content;
  padding: 0;
  z-index: 100;
  @include border-radius(0);
  @include box-shadow;

  label {
    &.check-selector {
      width: 100%;
      min-height: 42px;
      line-height: 40px;
      margin: 0;
      padding: 0 0 0 44px;
      border-bottom: 1px solid $brand-light-blue2;
      @include background-transition;

      &:hover {
        background: $brand-light-blue1;
      }
    }
  }

  .checkmark {
    top: 10px;
    left: 10px;
  }
}

.address-container {
  display: flex;
  flex-direction: column;
  position: relative;

  @media #{$tablet-portrait} {
    flex-direction: row;
  }

  .address-content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 45px;

    img {
      position: relative;
      top: -1px;
    }

    @media #{$tablet-portrait} {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;

      @media #{$tablet-portrait} {
        margin-right: 15px;
      }
    }
  }

  .address-buttons {
    display: flex;
    align-items: center;

    button {
      white-space: nowrap;
    }

    @media #{$tablet-portrait} {
      margin-left: auto;
    }
  }

  .edit-panel,
  .edit-panel-content {
    top: -14px;
  }
}

#notesContainer {
  > p {
    padding: 15px;
  }
}

.new-entity {
  position: relative;
  background-color: $brand-blue;
  padding: 40px 10px 20px 10px;
  margin-bottom: 10px;

  .remove-entity {
    position: absolute;
    content: "";
    top: 13px;
    right: 10px;
    width: 12px;
    height: 12px;
    background: url(~/assets/images/icons/icon-cross-white.svg) $brand-blue
      no-repeat;
    cursor: pointer;
    @include background-size(12px, 12px);
  }

  .form-group {
    margin-top: 15px;
    margin-bottom: 0;
    color: #ffffff;

    &:first-of-type {
      margin-top: 0;
    }

    .error-msg {
      color: #ffffff;
    }
  }
}

.modal {
  &-container {
    p {
      font-size: 1.4em;
      color: $text-color;
    }

    button[type="submit"]:not(.transfer-booking-modal-button) {
      margin-top: 15px;
    }
  }

  &-intro {
    max-width: 55%;

    @media #{$tablet-portrait} {
      max-width: calc(100% - 110px);
    }
  }

  &-content {
    &.add-notes {
      .button-container {
        display: flex;
        align-items: center;
        min-height: 64px;
        position: relative;
        border-bottom: 1px solid $brand-light-blue2;

        button {
          position: absolute;

          &:nth-of-type(1) {
            right: 0;
          }

          &:nth-of-type(2) {
            right: 54px;
          }

          &.add,
          &.search {
            padding-right: 44px;

            &:hover {
              background-color: $brand-light-blue1;
            }

            &.on {
              background-color: $brand-light-blue1;
              border: 1px solid $brand-light-blue2;
              border-bottom: none;
            }
          }

          &.search {
            background: url(~/assets/images/icons/icon-search-blue.svg) $fill
              no-repeat center center;
            border-right: 1px solid $brand-light-blue2;
            @include background-size(21px, 21px);
          }

          &.add {
            background: url(~/assets/images/icons/icon-plus-blue.svg) $fill
              no-repeat center center;
            @include background-size(21px, 21px);
          }
        }
      }

      .content-container {
        padding: 0;
      }

      button {
        &.small {
          float: right;
        }
      }

      textarea {
        &.edit-field {
          &.add-note {
            margin-bottom: 15px;
          }
        }
      }

      .item-add-note {
        border-bottom: 1px solid $brand-light-blue2;
        padding: 15px;
        position: relative;
        @include background-transition;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          background-color: $brand-light-blue1;
        }

        p {
          margin-bottom: 10px;

          &.icon-text {
            display: flex;
            font-weight: 700;

            img {
              margin-right: 10px;
            }
          }
        }

        button {
          &.remove {
            height: 20px;
            background-color: transparent;
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }

        .edit-item {
          display: flex;

          p {
            margin-bottom: 0;
          }

          button {
            &.edit {
              margin-left: auto;
            }
          }

          &.active {
            textarea {
              width: 90%;
              margin-bottom: 0;
            }
          }
        }

        .item-content {
          width: 100%;
        }
      }

      .filter-toggle-content {
        padding: 15px;
        background-color: $brand-light-blue1;
        border: 1px solid $brand-light-blue2;
        border-top: none;

        .form-group {
          .mat-datepicker-toggle {
            position: absolute;
            bottom: 0;
            right: 0;

            @media #{$tablet-portrait} {
              top: 0;
              bottom: auto;
            }
          }

          &.last {
            margin-bottom: 0;

            select {
              background-color: $fill;
              border: 1px solid $brand-light-blue2;
            }
          }
        }
      }

      .edit-panel-toggle,
      .edit-panel-toggle:hover {
        background: url(~/assets/images/icons/icon-dots-blue.svg) no-repeat
          center center;
        @include background-size(16px, 16px);
      }

      .edit-panel-toggle {
        &.on {
          background-color: $fill;
        }
      }
    }

    .add-note-container {
      padding: 15px;
      border: 1px solid $brand-light-blue2;
      border-top: none;
      background-color: $brand-light-blue1;
    }

    .actions {
      min-height: 15px;
      margin: 15px 0 0 0 !important;

      .count {
        margin-bottom: 0 !important;
      }
    }
  }
}

.searching-indicator {
  background: url(../assets/images/icons/icon-loader.svg) $fill no-repeat right
    center;
  @include background-size(40px, 40px);
  transition: none !important; // !important for now

  &.right-offset-30 {
    background-position: calc(100% - 30px);
  }
}

.inter-container {
  .inter-block {
    margin: 25px 0;
    background-color: $brand-light-blue1;

    @media #{$tablet-portrait} {
      display: flex;
    }

    .inter-item {
      padding: 15px;

      @media #{$tablet-portrait} {
        width: 50%;
      }

      &:first-of-type {
        border-bottom: 1px solid $fill;

        @media #{$tablet-portrait} {
          margin-bottom: 0;
          border-bottom: none;
          border-right: 1px solid $fill;
        }
      }

      p {
        margin-bottom: 0;
      }

      .inter-item-title {
        margin-bottom: 5px;
        font-weight: bold;

        @media #{$tablet-portrait} {
          margin-bottom: 15px;
        }
      }

      .inter-item-content {
        @media #{$tablet-portrait} {
          display: flex;
          align-items: center;
        }

        .avatar-container {
          display: none;

          @media #{$tablet-portrait} {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            margin-right: 10px;
            border: 2px solid $brand-black;

            @include border-radius(30px);
          }

          img {
            @media #{$tablet-portrait} {
              width: 20px;
              height: 25px;
            }
          }
        }

        p {
          span {
            margin-left: 5px;
            color: $text-color;

            @media #{$tablet-portrait} {
              margin-left: 0;
              font-size: 0.5em;
              line-height: 1.6;
            }
          }

          @media #{$tablet-portrait} {
            display: flex;
            flex-direction: column;
            flex: 1;
            font-size: 1.8em;
          }
        }
      }
    }
  }

  .item.no-toggle {
    margin-right: 0;
    margin-bottom: 25px;
    padding-bottom: 25px;

    &:hover {
      .interaction-title {
        color: $link-color;
      }
    }

    .icon {
      border-right: 1px solid $brand-light-blue2;
      margin-bottom: 0;
      padding: 0 35px 0 30px;
    }

    .interaction-summary {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .item-content {
      max-width: none;
    }

    .icon-text-container {
      p {
        &.icon-text {
          display: flex;

          img {
            margin-right: 10px;
          }

          &:first-of-type {
            @media #{$tablet-portrait} {
              margin-right: 30px;
            }
          }
        }
      }

      @media #{$tablet-portrait} {
        display: flex;
      }
    }
  }

  .verticals-div {
    .verticals-table {
      .verticals-row {
        .header-cell {
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: middle;
          background-color: transparent;
          border-bottom: 1px solid $brand-light-blue2;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .verticals-div .verticals-table .verticals-row:first-child:hover {
    background-color: transparent;
  }

  .btn {
    margin-top: 25px;
  }
}

.equal-width-button-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.repeat-booking-delete-date-range-container {
  display: flex;
  flex-direction: column;

  .date-range-container {
    display: flex;
    flex-direction: row;

    .date-picker-container {
      position: relative;

      display: flex;
      flex-direction: row;

      input[type="text"] {
        width: 100%;
      }

      .date-picker-error {
        position: absolute;
        top: 2.8rem;
        right: 2.4rem;

        white-space: nowrap;
      }
    }

    .date-range-element-label {
      display: flex;
      align-self: center;

      margin-right: 0.5rem;

      font-size: 0.8rem;
    }

    .date-range-element {
      display: flex;
      flex-direction: row;

      .mat-datepicker-input {
        margin-bottom: 0;
        height: 2rem;
      }
    }
  }
}

.bookings-filters {
  @media #{$tablet-landscape} {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  @media #{$desktop} {
    flex-wrap: nowrap;
    gap: 2rem;
  }

  .filter {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 1.4em;

    &:last-of-type {
      @media #{$desktop} {
        max-width: none;
      }
    }

    @media #{$tablet-landscape} {
      max-width: 15rem;
      margin-bottom: 0;
      &:nth-of-type(3) {
        max-width: 21rem;
      }
    }
    @media #{$desktop} {
      max-width: 18rem;
      margin-bottom: 0;
    }

    .btn-page {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brand-light-blue1;
      @media #{$tablet-landscape} {
        width: 32px;
      }
      @media #{$desktop} {
        width: 42px;
      }

      img {
        width: 9px;
      }

      &.margin-right {
        @media #{$tablet-landscape} {
          margin-right: 0rem;
        }
        @media #{$desktop} {
          margin-right: 0.5rem;
        }
      }

      &.disableBookingNavButton {
        position: relative;

        span {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $fill;
          opacity: 0.75;
          cursor: default;
        }
      }
    }

    .check-selector {
      top: 0;
      font-size: 1em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    app-datepicker {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &.right-align {
      margin-left: auto;
      justify-content: flex-end;
      @media #{$tablet-landscape} {
        width: 100px;
      }
      @media #{$desktop} {
        width: auto;
      }

      button {
        font-size: 1em;
        text-align: center;

        @media #{$desktop} {
          width: max-content;
        }

        &.btn-toggle-table {
          padding-right: 15px;
          background-image: none;
          border: none;

          img {
            width: 24px;
          }
        }
      }
    }

    label {
      margin-right: 15px;
      white-space: nowrap;
    }

    select,
    input {
      width: 100%;
      margin: 0;
      font-size: 1em;

      @media #{$mobile-landscape} {
        min-width: 116px;
      }

      @media #{$desktop-mid} {
        min-width: 116px;
      }
    }

    .mat-form-field-wrapper {
      padding: 0;
    }

    app-multiselect-dropdown,
    app-multiselect-dropdown > mat-form-field {
      width: 100%;
    }

    app-datepicker input {
      width: 100%;
    }

    .checkbox-container input[type="checkbox"] {
      width: initial;
    }

    button {
      &.add {
        display: flex;
        align-items: center;
        margin-left: auto;
        height: 42px;
        font-size: 0.9rem;
        color: $text-color-light;
        background: url(~/assets/images/icons/icon-plus-white.svg) no-repeat
          center right 10px $brand-black;
        @include background-size(21px, 21px);

        span {
          white-space: nowrap;
        }

        &:hover {
          background-color: $grey1;
        }

        @media #{$desktop} {
          width: auto;
        }
      }
    }
  }
}

.facility-booking-form {
  textarea {
    resize: vertical;
    @include no-transition;
  }

  span.error-msg {
    text-align: right;
    margin-bottom: 1rem;
    margin-top: -0.5rem;

    &.display-label-error {
      text-align: left;
      margin-top: -1.4rem;
      font-size: 1.15em;
    }
  }

  app-member-search-input {
    flex: 1;

    .search,
    input[type="text"] {
      width: 100%;
    }
  }

  .form-group {
    @media #{$tablet-landscape} {
      display: flex;
      margin-bottom: 15px;
    }

    label,
    input,
    select,
    textarea {
      font-size: 0.8rem;

      @media #{$tablet-landscape} {
        flex: 1;
      }
    }

    &.full-width {
      display: table;

      label,
      p {
        top: 0;
      }
    }

    .booking-takeover-weekdays-label {
      display: flex;
      flex-direction: column;
      margin: 0;
      color: $link-color;
      font-size: 0.9rem;
      top: 0;

      .weekday-container .weekday {
        color: $text-color;

        &.highlighted {
          color: $text-color-light;
        }
      }
    }

    .weekday-container {
      margin: auto;

      &.booking-takeover-weekdays {
        margin: 15px 0 5px 0;
        font-size: 0.8rem;
        font-weight: initial;
      }
    }

    &.date-range {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        width: auto;
        margin: 10px 0;
        text-align: center;
        top: 0;
      }

      app-datepicker {
        display: flex;
        width: 100%;

        input {
          width: 100%;

          @media #{$tablet-landscape} {
            width: 75%;
          }
        }
      }
    }

    app-member-search-input .form-group {
      margin-bottom: 0;
    }

    .pr-0 {
      padding-right: 0;
    }

    .checkbox-container {
      display: flex;
      align-items: flex-end;
      flex: 1;

      input[type="checkbox"] {
        flex: 0;
        margin-bottom: 4px;
      }
    }
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  p {
    &.reset-end-date {
      width: 100%;
      top: -18px;
      text-align: right;
      font-size: 0.7rem;
    }
  }

  .exceptions {
    p {
      font-size: 0.8rem;
      margin: 0;

      &.exceptions-label {
        margin: 0 0 0 0.3rem;
      }

      &.indent {
        margin-left: 0.5rem;
      }

      &.lower-margin-03 {
        margin-bottom: 0.3rem;
      }

      &.lower-margin-05 {
        margin-bottom: 0.5rem;
      }
    }

    img {
      width: 1rem;
    }

    ul li:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  .error-msg.date-conflict,
  .warning-msg.date-conflict {
    padding: 1rem;

    margin-bottom: 30px;
    font-weight: 500;

    p {
      width: 100%;
      font-size: 0.8rem;
      float: none;
      position: initial;
      margin-bottom: 0;
    }
  }
}

.ability-level-selector {
  margin: 10px 0 10px 0;
  p {
    margin: 0 0 10px 0;
    font-size: 0.8rem;
  }
  .ability-labels {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
}

.booking-takeover-datetime-timeslots {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    width: 100%;
    top: 0;
  }

  .booking-takeover-set-date {
    width: calc(100% - 40px);
    padding-bottom: 0.6rem;
    font-weight: bold;
    border-bottom: 1px solid $brand-light-blue2;
  }

  .sport-timeslots-label {
    width: 100%;
    padding-right: 0;

    span {
      &.sport-type {
        display: block;
        margin: 10px 0;
        font-size: 0.9rem;
        font-weight: bold;

        @media #{$tablet-landscape} {
          margin-top: 0;
        }
      }
    }
  }

  .facility-timeslots {
    margin-bottom: 0.6rem;

    span {
      font-weight: 400;
    }
  }
}

.radio-buttons {
  display: flex;
  padding-left: 15px;

  label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    white-space: nowrap;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }

    input[type="radio"] {
      appearance: none;
      width: 20px;
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      border: solid 1px $brand-blue;
      margin-right: 7px;
      position: relative;
      border-radius: 100%;

      &[disabled] {
        border: 1px solid $brand-light-blue2;
      }
    }

    [type="radio"] {
      &:checked {
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          background-color: $brand-blue;
          position: absolute;
          top: 2px;
          left: 2px;
          border-radius: 100%;
        }
      }

      &[disabled] {
        &:checked {
          &::before {
            background-color: $brand-light-blue2;
          }
        }
      }
    }
  }
}

.error-msg.date-conflict {
  border: 1px solid $red;
  background-color: $red2;
}

.warning-msg.date-conflict {
  border: 1px solid $orange;
  background-color: $orange2;
}

.takeover-schedules,
.linked-members {
  font-size: 0.6rem;
  background-color: $brand-light-blue1;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;

  p {
    margin-bottom: 15px;
  }
}

.takeover-schedules {
  .schedules-container {
    margin-bottom: 1rem;
  }

  .weekday-container {
    margin: auto;
  }

  mat-panel-title {
    span.edit-indicator {
      margin-left: auto;
      color: $brand-blue;
      font-size: 0.7rem;
      align-self: center;
    }
  }

  mat-expansion-panel.mat-expansion-panel {
    box-shadow: none;
    box-sizing: inherit;
    overflow: visible;

    .mat-expansion-panel-content {
      font: inherit;
      letter-spacing: inherit;
    }
  }

  .date-range {
    justify-content: space-between;

    p {
      text-align: center;
      width: auto;
      padding: 0 0.5rem;
    }

    .mat-datepicker-toggle {
      display: none;
    }

    .mat-datepicker-input {
      width: 100%;
    }
  }

  button.btn {
    margin-left: auto;

    &.width-auto {
      width: auto;
    }

    &.warning {
      background-color: #d9534f;
      color: $fill;

      &:hover {
        background-color: #c9302c;
      }
    }
  }
}

.linked-members {
  app-member-search-input,
  .search {
    width: 100%;
  }

  app-member-search-input .form-group {
    margin-bottom: 0;
  }

  &.member-selected-area {
    padding: 0;
    margin-bottom: 0;
    background-color: initial;
    flex: 1;
  }

  .selected-members {
    display: flex;
    flex-direction: column;

    .member {
      border: 1px solid $brand-light-blue2;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      font-size: 0.65rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $fill;
      position: relative;
      word-break: break-word;
      margin-bottom: 0.3rem;

      p {
        margin: 0px 1rem;
        color: $brand-blue;
        width: fit-content;

        .role-tag {
          font-size: 0.6rem;
          display: block;
          line-height: normal;
          color: $grey3;
        }
      }

      img.avatar {
        width: 40px;
        height: 40px;
        border-width: 2px;
      }

      .flex-container {
        display: flex;
        align-items: normal;
        width: 100%;
        justify-content: flex-start;

        img.close-btn {
          width: 0.8rem;
          margin-left: auto;
        }
      }
    }

    .flex-container {
      display: flex;
      align-items: center;

      .user-text {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        p,
        span.tag {
          color: $brand-dark-blue;
        }

        p {
          margin: 0px;
          font-size: 0.9rem;
        }

        span.tag {
          margin-left: 0;
          text-transform: none;
          font-size: 0.8rem;
          color: $text-color;
        }

        &.bold {
          font-weight: bold;

          p {
            font-weight: bold;
          }
        }
      }

      img.avatar {
        width: 40px;
        height: 40px;
        border-width: 2px;
      }
    }
  }

  .flex-container {
    justify-content: space-between;
    display: flex;

    button.btn {
      width: auto;
    }

    .search {
      flex: 1;
      position: relative;

      input[type="text"] {
        width: 100%;
        margin-bottom: 0;

        &:disabled {
          background-color: $brand-light-blue1;
        }
      }

      .auto-complete-results {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 42px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        min-width: 0;
        overflow-y: auto;
      }
    }
  }
}

.transfer-booking-form-wrapper {
  .search,
  input[type="text"] {
    float: left;
    width: 100%;
  }
}

.guest-form {
  border: 1px solid $brand-light-blue2;
  padding: 1rem;
  background-color: $fill;
  margin-top: 1rem;
}

.cancel-booking-options-container .row {
  display: flex;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
  }

  fieldset {
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }

    input[type="radio"] {
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  &.date {
    input {
      margin: 0;
    }

    fieldset {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    label {
      margin-bottom: 0;
    }
  }

  &.middle-gap {
    gap: 1rem;
  }
}

.edit-booking-warning-modal-form {
  .radio-buttons {
    padding-left: 0;
  }
}

.news-generator-form {
  .type-definitions {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;

    .selector-error-container {
      display: flex;
      flex: 0.15;
      position: relative;

      .selector {
        display: flex;
        align-items: center;
        flex-direction: row;

        label {
          white-space: nowrap;
          margin-right: 1rem;
          font-size: 0.9rem;
        }

        select {
          margin-bottom: 0;
          width: 9rem;
        }
      }

      span.error-pos-selector {
        position: absolute;
        bottom: -1rem;
        right: 0;
      }
    }
  }

  div.fildsetContainer {
    width: 100%;
    height: 100%;

    display: flex;
    gap: 0 2rem;
  }
  fieldset {
    position: relative;
    margin-bottom: 2rem;

    input,
    textarea,
    input[type="file"],
    input {
      margin-bottom: 0;
    }

    span.error-pos {
      position: absolute;
      bottom: -1rem;
      @include all-transition;

      @media #{$tablet-portrait} {
        left: 30%;
      }

      @media #{$desktop-wide} {
        left: 20%;
      }
    }
  }

  .news-form-group {
    position: relative;
    width: 100%;
    font-size: 0.9rem;

    label {
      position: absolute;
      top: 0.6rem;
    }

    input {
      font-size: 0.9rem;
    }

    textarea {
      resize: vertical;
      font-size: 0.9rem;
      @include no-transition;
    }

    .wysiwyg {
      float: right;
      margin: 0 0 30px 0;
      width: 100%;
      @media #{$tablet-portrait} {
        width: 70%;
      }

      .angular-editor-toolbar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .angular-editor-button {
          font-size: 0.9em;
          min-height: 22px;
        }
      }
    }

    .date-picker-container {
      position: absolute;
      @include all-transition;

      @media #{$tablet-portrait} {
        left: 30%;
      }
      @media #{$desktop-wide} {
        left: 20%;
      }

      input {
        width: 14rem;
      }
    }

    .relevant-links-container {
      display: flex;
      flex-direction: row;
      @include all-transition;
      @media #{$tablet-portrait} {
        width: 70%;
        float: right;
      }
      @media #{$desktop-wide} {
        width: 80%;
      }
      .multi-link-container {
        width: 100%;

        display: flex;
        flex-direction: column;
        float: right;
        row-gap: 1rem;

        input {
          width: 100%;
        }

        .individual-link-container {
          width: 100%;

          display: flex;
          flex-direction: row;
          align-items: center;

          .link-info-container {
            width: 100%;

            display: flex;
            flex-direction: row;
          }

          .relevant-link-error-container {
            position: relative;
            width: 50%;

            .error-pos {
              left: 0%;
              font-size: 0.8rem;
            }

            input {
              margin-bottom: 0;
            }
          }

          img.remove-link {
            width: 0.8rem;
            margin-left: 0.5rem;

            display: flex;
            align-self: center;
          }
        }

        .link-description {
          width: 50%;
          display: flex;
          flex-direction: row;

          label {
            position: relative;
            margin: 0 1rem 0 2rem;

            white-space: nowrap;
            margin-right: 1rem;
          }

          .link-description-error-container {
            position: relative;
            width: 100%;

            .error-pos {
              left: 0%;
              font-size: 0.8rem;
            }

            input {
              width: 100%;
            }
          }
        }
      }

      button.add-link {
        width: 10rem;
        height: 2rem;
        margin-left: 1.5rem;
        float: right;
        align-self: flex-end;

        background-image: none;
        border: none;

        font-size: 0.9rem;
        text-align: center;
      }
    }

    input[type="file"] {
      display: none;
    }

    .upload-button-container {
      float: right;

      @include all-transition;

      @media #{$tablet-portrait} {
        width: 70%;
        float: right;
      }

      @media #{$desktop-wide} {
        width: 80%;
      }

      .upload-sub-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .upload-attm-label {
          min-width: 15rem;
          width: 15rem;
          height: 2rem;
        }

        label {
          float: left;
          font-size: 0.9rem;
          width: 10rem;

          &.add-file {
            background: url(~/assets/images/icons/icon-upload-blue.svg)
              $brand-light-blue1 no-repeat right 10px top 50%;

            &:hover {
              background: url(~/assets/images/icons/icon-upload-white.svg)
                $brand-blue no-repeat right 10px top 50%;
            }
          }

          &.add-file,
          &.remove-file {
            width: 100%;
            padding: 14px 15px;
            overflow: hidden;
            position: relative;
            display: block;
            top: 0;
            text-align: left;
            color: $link-color;
            font-size: 1em;
            @include background-size(16px, 16px);
            @include background-transition;

            &:hover {
              color: $text-color-light;
              @include background-size(16px, 16px);
            }

            [type="file"] {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              filter: alpha(opacity=0);
              opacity: 0;
              text-align: right;
              cursor: pointer;
            }
          }
        }

        .attm-list-container {
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .attm-info {
          position: relative;
          padding-left: 2rem;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 1rem;

          align-items: center;

          .input-label {
            margin-left: 1rem;
            white-space: nowrap;
          }

          input {
            width: 100%;
          }

          img.remove-link {
            width: 0.8rem;

            display: flex;
            align-self: center;
          }

          span.upload-error-pos {
            position: absolute;
            left: 51%;
            bottom: -1rem;

            white-space: nowrap;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .publish-date-divider {
    margin-bottom: 2rem;
  }

  .submission-container {
    float: right;

    display: flex;
    flex-direction: row;

    .make-public-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-right: 0.5rem;
        font-size: 0.9rem;
      }
    }

    .submit-buttons-container {
      display: flex;
      flex-direction: row;

      button {
        width: 10rem;
        height: 2rem;
        margin-left: 1rem;

        font-size: 0.9rem;
      }
    }
  }
}

.document-generator-form {
  .type-definitions {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;

    .selector-error-container {
      display: flex;
      flex: 0.15;
      position: relative;

      .selector {
        display: flex;
        align-items: center;
        flex-direction: row;

        label {
          white-space: nowrap;
          margin-right: 1rem;
          font-size: 0.9rem;
        }

        select {
          margin-bottom: 0;
          width: 9rem;
        }
      }

      span.error-pos-selector {
        position: absolute;
        bottom: -1rem;
        right: 0;
      }
    }
  }

  div.fildsetContainer {
    width: 100%;
    height: 100%;

    display: flex;
    gap: 0 2rem;
  }
  fieldset {
    position: relative;
    margin-bottom: 2rem;

    input,
    textarea,
    input[type="file"],
    input {
      margin-bottom: 0;
    }

    span.error-pos {
      position: absolute;
      bottom: -1rem;
      @include all-transition;

      @media #{$tablet-portrait} {
        left: 30%;
      }

      @media #{$desktop-wide} {
        left: 20%;
      }
    }
  }

  .document-form-group {
    position: relative;
    width: 100%;
    font-size: 0.9rem;

    label {
      position: absolute;
      top: 0.6rem;
    }

    input {
      font-size: 0.9rem;
    }

    textarea {
      resize: vertical;
      font-size: 0.9rem;
      @include no-transition;
    }

    .date-picker-container {
      position: absolute;
      @include all-transition;

      @media #{$tablet-portrait} {
        left: 30%;
      }
      @media #{$desktop-wide} {
        left: 20%;
      }

      input {
        width: 14rem;
      }
    }

    .relevant-links-container {
      display: flex;
      flex-direction: row;
      @include all-transition;
      @media #{$tablet-portrait} {
        width: 70%;
        float: right;
      }
      @media #{$desktop-wide} {
        width: 80%;
      }
      .multi-link-container {
        width: 100%;

        display: flex;
        flex-direction: column;
        float: right;
        row-gap: 1rem;

        input {
          width: 50%;
        }

        .individual-link-container {
          width: 100%;

          display: flex;
          flex-direction: row;
          align-items: center;

          input {
            margin-bottom: 0;
          }

          img.remove-link {
            width: 0.8rem;
            margin-left: 0.5rem;

            display: flex;
            align-self: center;
          }
        }

        .link-description {
          width: 50%;

          display: flex;
          flex-direction: row;

          label {
            position: relative;
            margin: 0 1rem 0 2rem;

            white-space: nowrap;
            margin-right: 1rem;
          }

          input {
            width: 100%;
          }
        }
      }

      button.add-link {
        width: 10rem;
        height: 2rem;
        margin-left: 1.5rem;
        float: right;
        align-self: flex-end;

        background-image: none;
        border: none;

        font-size: 0.9rem;
        text-align: center;
      }
    }

    input[type="file"] {
      display: none;
    }

    .upload-button-container {
      float: right;

      @include all-transition;

      @media #{$tablet-portrait} {
        width: 70%;
        float: right;
      }

      @media #{$desktop-wide} {
        width: 80%;
      }

      .upload-sub-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .upload-attm-label {
          width: 15rem;
          height: 2rem;
        }

        label {
          float: left;
          font-size: 0.9rem;
          width: 10rem;

          &.add-file {
            background: url(~/assets/images/icons/icon-upload-blue.svg)
              $brand-light-blue1 no-repeat right 10px top 50%;

            &:hover {
              background: url(~/assets/images/icons/icon-upload-white.svg)
                $brand-blue no-repeat right 10px top 50%;
            }
          }

          &.add-file,
          &.remove-file {
            width: 100%;
            padding: 14px 15px;
            overflow: hidden;
            position: relative;
            display: block;
            top: 0;
            text-align: left;
            color: $link-color;
            font-size: 1em;
            @include background-size(16px, 16px);
            @include background-transition;

            &:hover {
              color: $text-color-light;
              @include background-size(16px, 16px);
            }

            [type="file"] {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              filter: alpha(opacity=0);
              opacity: 0;
              text-align: right;
              cursor: pointer;
            }
          }

          &.upload-file-error {
            background-color: $red2;
          }
        }

        .attm-list-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .attm-info {
          position: relative;
          padding-left: 2rem;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 1rem;

          align-items: center;

          .input-label {
            margin-left: 1rem;
          }

          input {
            width: 15rem;
          }

          img.remove-link {
            width: 0.8rem;

            display: flex;
            align-self: center;
          }

          span.upload-error-pos {
            position: absolute;
            left: 51%;
            bottom: -1rem;

            white-space: nowrap;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .publish-date-divider {
    margin-bottom: 2rem;
  }

  .submission-container {
    float: right;

    display: flex;
    flex-direction: row;

    .make-public-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-right: 0.5rem;
        font-size: 0.9rem;
      }
    }

    .submit-buttons-container {
      display: flex;
      flex-direction: row;

      button {
        width: 10rem;
        height: 2rem;
        margin-left: 1rem;

        font-size: 0.9rem;
      }
    }
  }
}

div.input-error-msg-container {
  position: relative;

  display: flex;
  flex-direction: column;

  span.input-error-msg {
    margin-top: 0.2rem;
    font-size: 0.6rem;
    color: $red;
    left: 0%;
    white-space: nowrap;
  }
}

.manage-member-msgs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;

  .member-msg-list {
    width: 100%;

    .member-msg-list-item {
      width: 100%;

      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto;
      column-gap: 1rem;
      row-gap: 0.7rem;

      justify-items: center;
      align-items: center;

      font-size: 0.9rem;

      .member-msg-text-label {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        white-space: nowrap;
        font-size: 0.9rem;
      }

      .member-msg-text-input {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        width: 100%;
        margin-bottom: 0;
        font-size: 0.9rem;
      }

      .date-fields-container {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        width: 100%;

        display: flex;
        justify-content: space-between;

        .date-picker {
          width: 10rem;
          display: flex;

          .mat-datepicker-input {
            margin-bottom: 0;
            font-size: 0.9rem;
            width: 8rem;
          }
        }
      }

      .dismiss-options {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;

        display: flex;
        gap: 1rem;

        .remove-member-msg-icon {
          min-width: 16px;
          width: 16px;
          height: 16px;
          cursor: pointer;

          background-image: url("/assets/images/icons/icon-delete-blue.svg");
          background-repeat: no-repeat;
          background-size: 16px 16px;
        }
      }
    }
  }

  .add-member-msg {
    width: -moz-fit-content;
    width: fit-content;
    background-image: none;

    &:hover {
      background-image: none;
    }
  }

  .member-msg-history-prompt {
    font-size: 0.9rem;
  }

  .member-msg-history-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    .member-msg-history-item {
      width: 100%;

      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;

      p {
        margin-bottom: 0;
        font-size: 0.9rem;
      }

      .historic-member-msg-msg {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .historic-member-msg-start {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .historic-member-msg-end {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
  }
}

.add-member-form-container {
  .form-group {
    label {
      @media #{$tablet-portrait} {
        padding-right: 0;
      }
    }

    .form-group-right {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.test-margin {
  margin-bottom: 30px;
}

.login-template-container {
  margin-top: 95px;
  @media #{$tablet-landscape} {
    margin-top: 150px;
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .btn {
    &.add-member-btn-check {
      width: 40%;
    }
    &.add-member-btn-check-left {
      text-align: right !important;
      background: url(~/assets/images/icons/icon-arrow-left-white.svg)
        $brand-black no-repeat left 10px top 50%;
      color: $text-color-light;
      background-size: 10px 16px;
      &:hover {
        background-color: $grey1;
      }
    }
  }
}
