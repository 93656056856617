/* mixins */
@mixin background-size-nav {
  -webkit-background-size: 25px 25px, 10px 18px;
  background-size: 25px 25px, 10px 18px;

  @media (min-width: 980px) and (max-height: 780px) {
    -webkit-background-size: 22px 22px;
    background-size: 22px 22px;
  }
}

@mixin background-position-nav {
  -webkit-background-position: left 15px top 50%, right 22px top 50%;
  background-position: left 15px top 50%, right 22px top 50%;

  @media (min-width: 980px) and (max-height: 780px) {
    background-position: left 20px top 50%;
  }

  @media #{$desktop} {
    -webkit-background-position: left 30px top 50%;
    background-position: left 30px top 50%;
  }
}

@mixin background-repeat-nav {
  background-repeat: no-repeat, no-repeat;
}

body {
  &.nav-mob-on {
    position: fixed;
  }
}

nav {
  &.mob,
  &.desk {
    width: 100%;
    font-size: 1.15em;
    background: $grey2;
    bottom: 0;
    z-index: 90;

    .nav-mob-toggle {
      width: 100%;
      height: 56px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      cursor: pointer;
      @include box-shadow-reverse;

      .nav-mob-btn {
        width: 100%;
        height: 100%;
        float: right;
        z-index: 1;
        background: url(~/assets/images/icons/icon-hamburger.svg) no-repeat
          right 15px top 50%;
        @include background-size(20px, 20px);

        &.on {
          background: url(~/assets/images/icons/icon-hamburger-on.svg) no-repeat
            right 15px top 50%;
          @include background-size(20px, 20px);
        }

        &::before {
          content: "";
          width: 56px;
          height: 56px;
          background-color: $grey2;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
      }
    }

    &.mob {
      @media #{$tablet-landscape} {
        display: none;
      }
    }

    &.desk {
      @media #{$tablet-landscape} {
        display: block;
        top: 100px;
        width: 62px;
        position: fixed;
        font-size: 0;
        font-size: 1.1em;
        letter-spacing: 0;
        overflow: hidden;
        letter-spacing: $letter-spacing;
        @include all-transition-slow;
        @include box-shadow;

        @media #{$desktop-narrow} {
          top: 68px;
        }

        a {
          min-height: 72px;
          opacity: 0;
          @include all-transition-slow;

          @media (min-width: 980px) and (max-height: 780px) {
            padding-top: 14px;
            padding-bottom: 14px;
            min-height: 43px;
            font-size: 1em;
          }
        }

        &:before {
          content: "";
          width: 62px;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background: $brand-black;
          @include all-transition-slow;
        }

        &:hover {
          width: 220px;
          overflow-y: visible;

          a {
            opacity: 1;
          }

          &:before {
            width: 220px;
          }
        }
      }

      @media #{$desktop} {
        width: 85px;
        font-size: 1.3em;

        a {
          min-height: 74px;

          @media (min-width: 980px) and (max-height: 780px) {
            padding-top: 14px;
            padding-bottom: 14px;
            min-height: 46px;
            font-size: 1em;
          }
        }

        &:before {
          width: 85px;
        }

        &:hover {
          width: 265px;

          &:before {
            width: 265px;
          }
        }
      }
    }

    ul {
      position: relative;

      &.nav-mob-items {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: none;
        position: absolute;

        &.on {
          display: block;
        }
      }

      &.nav-mob-selected {
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      li {
        background: $brand-black;
        border-bottom: 1px solid $grey1;
        color: $text-color-light;
        position: relative;
        white-space: nowrap;
        @include background-transition;

        &.nav-block {
          &:hover {
            background-color: $grey2;

            > a {
              color: $grey3;
            }
          }
        }

        .nav-veil {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(54, 62, 70, 0.85);
          z-index: 1;
        }

        a {
          width: 100%;
          height: 100%;
          padding: 20px 20px 20px 55px;
          display: block;
          color: $grey3;
          outline: 0;

          @media #{$tablet-landscape} {
            padding: 28px 28px 28px 55px;
          }

          @media #{$desktop} {
            padding-left: 70px;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          background-color: $grey2;
          cursor: pointer;

          a {
            color: $text-color-light;
          }
        }

        &.selected {
          background-color: $grey2;

          &:after {
            @media #{$tablet-landscape} {
              content: "";
              width: 7px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              background-color: $brand-blue;
            }
          }

          a {
            color: $text-color-light;
            font-weight: 500;
            @include color-transition;
          }
        }

        &#dashboard {
          background-image: url(~/assets/images/icons/icon-nav-dashboard.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-dashboard.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-dashboard-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-dashboard-on.svg);
            }
          }
        }

        &#people,
        &#members,
        &#contacts {
          background-image: url(~/assets/images/icons/icon-nav-people.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-people.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-people-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-people-on.svg);
            }
          }
        }

        &#organisations {
          background-image: url(~/assets/images/icons/icon-nav-organisation.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-organisation.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-organisation-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-organisation-on.svg);
            }
          }
        }

        &#projects {
          background-image: url(~/assets/images/icons/icon-nav-projects.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-projects.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-projects-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-projects-on.svg);
            }
          }
        }

        &#events {
          background-image: url(~/assets/images/icons/icon-nav-events.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-events.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-events-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-events-on.svg);
            }
          }
        }

        &#venues {
          background-image: url(~/assets/images/icons/icon-nav-venues.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-venues.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-venues-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-venues-on.svg);
            }
          }
        }

        &#localities {
          background-image: url(~/assets/images/icons/icon-nav-localities.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-localities.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-localities-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-localities-on.svg);
            }
          }
        }

        &#member-messages {
          background-image: url(~/assets/images/icons/icon-nav-localities.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-localities.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-localities-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-localities-on.svg);
            }
          }
        }

        &#interactions {
          background-image: url(~/assets/images/icons/icon-nav-interactions.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-interactions.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-interactions-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-interactions-on.svg);
            }
          }
        }

        &#userManagement {
          background-image: url(~/assets/images/icons/icon-nav-wesport.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-wesport.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-wesport-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-wesport-on.svg);
            }
          }
        }

        &#reports {
          background-image: url(~/assets/images/icons/icon-nav-reports.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-reports.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-reports-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-reports-on.svg);
            }
          }
        }

        &#bookings {
          background-image: url(~/assets/images/icons/icon-nav-diary.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-diary.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-diary-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-diary-on.svg);
            }
          }
        }

        &#box-leagues {
          background-image: url(~/assets/images/icons/icon-events-white.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-events-white.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-events-blue.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-events-blue.svg);
            }
          }
        }

        &#documents {
          background-image: url(~/assets/images/icons/icon-small-book-white.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-small-book-white.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-small-book-blue.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-small-book-blue.svg);
            }
          }
        }

        &#news {
          background-image: url(~/assets/images/icons/icon-nav-news.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-news.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-news-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-news-on.svg);
            }
          }
        }

        &#usefulLinks {
          background-image: url(~/assets/images/icons/icon-nav-news.svg),
            url(~/assets/images/icons/icon-arrow-right-grey.svg);
          @include background-repeat-nav;
          @include background-position-nav;
          @include background-size-nav;

          @media #{$tablet-landscape} {
            background-image: url(~/assets/images/icons/icon-nav-news.svg);
          }

          &:hover,
          &.selected {
            background-image: url(~/assets/images/icons/icon-nav-news-on.svg),
              url(~/assets/images/icons/icon-arrow-right-blue.svg);

            @media #{$tablet-landscape} {
              background-image: url(~/assets/images/icons/icon-nav-news-on.svg);
            }
          }
        }
      }
    }
  }

  &.mob {
    position: fixed;
  }

  &.desk {
    position: relative;
    float: left;
    display: none;
  }

  &.on {
    height: 100%;
    background: $brand-black;

    ul {
      &.nav-mob-items {
        li {
          &#dashboard {
            margin-top: 68px;
          }

          &:last-of-type {
            margin-bottom: 56px;
          }

          ul {
            li {
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  &.tabs {
    margin-bottom: 15px;

    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      border-bottom: 1px solid $brand-blue;

      li {
        padding: 15px 10px;
        margin-right: 3px;
        white-space: nowrap;
        color: $link-color;
        cursor: pointer;
        @include background-transition;

        @media #{$tablet-portrait} {
          padding: 10px;
        }

        @media #{$desktop} {
          padding: 15px;
        }

        &:hover {
          background-color: $brand-light-blue1;
        }

        &.selected {
          cursor: default;
          background-color: $brand-light-blue1;
          color: $text-color;
        }
      }
    }
  }

  &.vertical-tabs {
    .tabs-list {
      width: 100%;
      margin: 0;
      padding: 0;
      clear: both;
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      align-items: center;
      z-index: 1;
      @include box-shadow-inset;

      .tabs-item {
        font-weight: 500;
        text-align: center;
        padding: 20px 15px;
        border-left: 1px solid $brand-light-blue2;
        color: $link-color;
        cursor: pointer;
        z-index: 2;
        @include all-transition;

        @media #{$tablet-portrait} {
          padding: 30px 20px;
        }

        @media #{$desktop} {
          padding: 30px;
        }

        @media #{$desktop-narrow} {
          padding: 20px 30px;
        }

        &.first {
          border-left: none;
        }

        &:last-of-type {
          border-right: 1px solid $brand-light-blue2;
        }

        &:not(&.disabled) {
          &:hover,
          &.selected {
            background-color: $fill;
            color: $brand-black;
          }
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }

  .active{
    background-color: $brand-light-blue1 !important;
  }

  img {
    &.twitter-temp-nav {
      width: 90%;
      margin: 20px 5%;
      display: block;
      text-align: center;
    }
  }
}
