.filter-search {
  input[type="submit"] {
    width: 22%;
    margin-bottom: 15px;
    background: url(~/assets/images/icons/icon-search-white.svg) $brand-black
      no-repeat center center;
    float: right;
    @include background-size(20px, 20px);
    @include background-transition;
    color: $text-color-light;
    font-size: 0;

    &:hover {
      background-color: $grey2;
    }

    @media #{$tablet-portrait} {
      width: 10%;
    }
  }

  input[type="text"] {
    width: 100%;
    border: 1px solid $brand-light-blue2;
    margin-bottom: 0;

    @media #{$tablet-portrait} {
      width: 90%;
    }
  }

  .icon-search {
    width: 20%;
    min-height: 42px;
    float: left;
    background: url(~/assets/images/icons/icon-search.svg) $fill no-repeat
      center center;
    @include background-size(20px, 20px);
    border-top: 1px solid $brand-light-blue2;
    border-bottom: 1px solid $brand-light-blue2;
    border-left: 1px solid $brand-light-blue2;

    @media #{$tablet-portrait} {
      width: 50px;
    }
  }

  p {
    margin-bottom: 15px;
  }
}

.filter-toggle {
  width: 100%;
  padding: 25px 10px 5px 15px;
  cursor: pointer;
  display: block;
  background: $brand-light-blue1;
  @include background-transition;

  @media #{$tablet-portrait} {
    padding-left: 0;
    padding-right: 0;
    background: none;
  }

  &.on {
    margin-bottom: 15px;
    border-bottom: 1px solid $brand-light-blue2;

    @media #{$tablet-portrait} {
      border-bottom: none;
    }

    img {
      &.on {
        display: block;
      }

      &.off {
        display: none;
      }
    }

    span:not(.count) {
      display: none;

      &.close {
        display: block;
      }
    }
  }

  p {
    width: 100%;
    margin-bottom: 0;
    color: $brand-blue;
    float: left;

    @media #{$tablet-portrait} {
      margin-right: 10px;
      float: none;
      text-align: center;
    }

    span {
      font-size: 1em;
      text-align: center;

      &.close {
        display: none;
      }
    }
  }

  img {
    width: 16px;
    height: 10px;
    margin: 0;
    float: right;
    position: relative;
    top: 4px;

    &.on {
      display: none;
    }
  }

  span {
    text-align: center;
  }
}

.filter-toggle-content {
  display: block;
}

section {
  &.widget {
    &.filter {
      margin-bottom: 40px;

      p {
        &.filter-heading {
          margin-bottom: 15px;
          flex-grow: 0;
        }
      }

      .heading-content {
        display: flex;
        align-items: center;
      }

      .button-container {
        position: relative;
        top: 0;
        right: 0;
        z-index: 1;

        @media #{$tablet-portrait} {
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        }

        button {
          display: flex;
          align-items: center;
          margin-top: 15px;
          min-width: auto;
          height: auto;
          float: none;
          position: relative;

          @media #{$tablet-portrait} {
            width: auto;
            margin-top: 0;
            margin-left: 15px;
          }

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6),
          &:nth-of-type(7) {
            right: 0;
          }

          &.add {
            color: $text-color-light;
            background: url(~/assets/images/icons/icon-plus-white.svg) no-repeat center right 10px $brand-black;
            @include background-size(21px, 21px);

            span {
              white-space: nowrap;
            }

            &:hover {
              background-color: $grey1;
            }
          }
        }
      }

      .heading-container {
        flex-direction: column;
        padding: 15px;
        border-bottom: 1px solid $brand-light-blue2;

        @media #{$tablet-portrait} {
          flex-direction: row;
        }

        h2 {
          color: $brand-blue;
        }
      }

      @media #{$tablet-landscape} {
        .content-container {
          padding-bottom: 0;
        }
      }

      .pointer {
        width: 0;
        height: 0;
        margin: 0 auto;
        position: relative;
        left: -10px;
        border-style: solid;
        border-width: 16px -16px 0 16px;
        border-color: #000 transparent transparent;
      }

      .pointer::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -18px;
        left: 50%;
        border: 1em solid #000;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, 0.2);
      }

      &.admin {
        .heading-container {
          background: $brand-black;
          border-bottom: 2px solid $fill;

          h2 {
            color: $text-color-light;
          }
        }
      }

      &.people {
        // adjust division - equal to the number of selects
        select {
          &:last-of-type {
            margin-bottom: 0;
          }

          @media #{$mobile-landscape} {
            width: 48%;
            margin-right: 4%;
            float: left;

            &:nth-child(even) {
              margin-right: 0;
              float: right;
            }
          }

          @media #{$tablet-landscape} {
            width: 23%;
            width: -moz-calc(94%/4);
            width: -webkit-calc(94%/4);
            width: calc(94% / 4);
            margin-right: 2%;
            float: left;

            &:last-of-type {
              margin-bottom: 15px;
            }

            &:nth-child(even) {
              margin-right: 2%;
              float: left;
            }

            &:nth-child(4n + 4) {
              margin-right: 0;
            }
          }

          @media #{$desktop-wide} {
            width: 13.4%;
            width: -moz-calc(95.9%/5);
            width: -webkit-calc(95.9%/5);
            width: calc(95.9% / 5);
            margin-right: 1%;
            float: left;

            &:nth-child(even),
            &:nth-child(4n + 4) {
              margin-right: 1%;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }

        form {
          &.filter-search-container {
            margin-bottom: 15px;
          }
        }

        @media #{$tablet-portrait} {
          form {
            &.filter-search-container {
              width: 75%;
              float: right;

              @media #{$desktop-wide} {
                width: 75%;

                &.full-width {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &.users {
        p {
          &.filter-heading {
            @media #{$tablet-portrait} {
              width: 30%;
              margin-bottom: 0;
              float: left;
              position: relative;
              top: 13px;
            }
          }
        }

        form {
          &.filter-search-container {
            width: 100%;

            @media #{$tablet-portrait} {
              width: 70%;
              float: right;

              input[type="text"] {
                width: 90%;
              }

              .icon-search {
                width: 10%;
              }
            }

            @media #{$tablet-landscape} {
              input[type="text"] {
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      &.projects {
        // adjust division - equal to the number of selects
        select {
          &:last-of-type {
            margin-bottom: 0;
          }

          @media #{$mobile-landscape} {
            width: 48%;
            margin-right: 4%;
            float: left;

            &:nth-child(even) {
              margin-right: 0;
              float: right;
            }
          }

          @media #{$tablet-landscape} {
            width: 23%;
            width: -moz-calc(94%/4);
            width: -webkit-calc(94%/4);
            width: calc(94% / 4);
            margin-right: 2%;
            float: left;

            &:last-of-type {
              margin-bottom: 15px;
            }

            &:nth-child(even) {
              margin-right: 2%;
              float: left;
            }

            &:nth-child(4n + 4) {
              margin-right: 0;
            }
          }

          @media #{$desktop-wide} {
            width: 13.4%;
            width: -moz-calc(93.9%/7);
            width: -webkit-calc(93.9%/7);
            width: calc(93.9% / 7);
            margin-right: 1%;
            float: left;

            &:nth-child(even),
            &:nth-child(4n + 4) {
              margin-right: 1%;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &.events {
        // adjust division - equal to the number of selects
        select {
          &:last-of-type {
            margin-bottom: 0;
          }

          @media #{$mobile-landscape} {
            width: 48%;
            margin-right: 4%;
            float: left;

            &:nth-child(even) {
              margin-right: 0;
              float: right;
            }
          }

          @media #{$tablet-landscape} {
            width: 23%;
            width: -moz-calc(94%/4);
            width: -webkit-calc(94%/4);
            width: calc(94% / 4);
            margin-right: 2%;
            float: left;

            &:last-of-type {
              margin-bottom: 15px;
            }

            &:nth-child(even) {
              margin-right: 2%;
              float: left;
            }

            &:nth-child(4n + 4) {
              margin-right: 0;
            }
          }

          @media #{$desktop-wide} {
            width: 14.3%;
            width: -moz-calc(94.9%/6);
            width: -webkit-calc(94.9%/6);
            width: calc(94.9% / 6);
            margin-right: 1%;
            float: left;

            &:nth-child(even),
            &:nth-child(4n + 5) {
              margin-right: 1%;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    &.member {
      .member-details,
      .linked-guests-container {
        .heading-content {
          display: flex;
          align-items: center;
        }

        .button-container {
          position: relative;
          top: 0;
          right: 0;
          z-index: 1;

          @media #{$tablet-portrait} {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
          }

          button {
            display: flex;
            align-items: center;
            margin-top: 15px;
            min-width: auto;
            height: auto;
            float: none;
            position: relative;

            @media #{$tablet-portrait} {
              width: auto;
              margin-top: 0;
              margin-left: 15px;
            }

            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7) {
              right: 0;
            }

            &.add {
              color: $text-color-light;
              background: url(~/assets/images/icons/icon-plus-white.svg) no-repeat center right 10px $brand-black;
              @include background-size(21px, 21px);

              span {
                white-space: nowrap;
              }

              &:hover {
                background-color: $grey1;
              }
            }
          }
        }

        .heading-container {
          flex-direction: column;
          margin-bottom: 15px;
          padding: 0 0 15px 0;
          border-bottom: 1px solid $brand-light-blue2;

          @media #{$tablet-portrait} {
            flex-direction: row;
          }

          h2, h3 {
            margin-bottom: 0;
            color: $brand-blue;
          }
        }
      }
    }
  }
}

.membership-notes-buttons{
  display: flex;
  align-items: center;
  min-height: 64px;
  position: relative;
  border-bottom: 1px solid $brand-light-blue2;

  .modal-intro {
    margin:0;
  }

  button {
    position: absolute;

    &:nth-of-type(1) {
      right: 0;
    }

    &:nth-of-type(2) {
      right: 54px;
    }

    &.add,
    &.search {
      padding-right: 44px;

      &:hover {
        background-color: $brand-light-blue1;
      }

      &.on {
        background-color: $brand-light-blue1;
        border: 1px solid $brand-light-blue2;
        border-bottom: none;
      }
    }

    &.search {
      background: url(~/assets/images/icons/icon-search-blue.svg) $fill
        no-repeat center center;
      border-right: 1px solid $brand-light-blue2;
      @include background-size(21px, 21px);
    }

    &.add {
      background: url(~/assets/images/icons/icon-plus-blue.svg) $fill
        no-repeat center center;
      @include background-size(21px, 21px);
    }
  }
}
