section {
	&.widget {
		
		&.report {
			h3 {
				&.underline {
					@media #{$tablet-landscape} {
						margin-bottom: 30px;
					}
				}
			}
			
			.widget {
				@include box-shadow-none;
				
				.content-container {
					margin-bottom: 15px;
					padding-left: 0;
					padding-right: 0;
					
					&.pad {
						padding: 15px;
						
						.col35, .col65 {
							margin-bottom: 0;	
						}
						
						p {
							margin-bottom: 10px;
							
							&:last-of-type {
								margin-bottom: 0;
							}
						}
						
						img {
							width: 100%;
							margin-bottom: 15px;
							
							@media #{$tablet-landscape} {
								margin-bottom: 30px;
							}
							
						}
					}
				}
			}
			
			.col100 {
				padding: 15px;
			}
			
			.heading-container {
				padding-left: 0;
				margin-bottom: 15px;
				border-bottom: 2px solid $brand-blue;
				
				h2 {
					color: $brand-blue;
				}
				
				&.black {
					margin-bottom: 0;
					padding-left: 15px;
					border: none;
					
					h2 {
						color: $text-color-light;
					}
				}
			}
		}	
	}
}

.summary {
	img {
		width: 100%;
		margin-bottom: 0;	
	}
}

span {
	@media #{$tablet-portrait} {
		&.center-bar {
			width: 1px;
			height: 50%;
			position: absolute;
			left: 49.9%;
			top: 20%;
			background: $brand-light-blue2;
		}
	}
	
	&.divide {
		width: 100%;
		height: 1px;
		margin-bottom: 15px;
		display: block;
		position: relative;
		background: $brand-light-blue2;
	}
}

.wash {
	background: $brand-light-blue1;
}