// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-primary: mat.define-palette(mat.$indigo-palette);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-theme);

/* You can add global styles to this file, and also import other style files */
// @import "@shared/styles/reset";
@import "./app/sub-projects/shared/styles/reset";
@import "./app/sub-projects/shared/styles/global_settings";
@import "./app/sub-projects/shared/styles/animate";
@import "./app/sub-projects/shared/styles/header";
@import "./app/sub-projects/shared/styles/navigation";
@import "./app/sub-projects/shared/styles/footer";
@import "./app/sub-projects/shared/styles/widgets";
@import "./app/sub-projects/shared/styles/reports";
@import "./app/sub-projects/shared/styles/filters";
@import "./app/sub-projects/shared/styles/forms";
@import "./app/sub-projects/shared/styles/tables";
@import "./app/sub-projects/shared/styles/typography";
@import "./app/sub-projects/shared/styles/alerts";
@import "./app/sub-projects/shared/styles/ie";
@import "./app/sub-projects/shared/styles/framework";
@import "./app/sub-projects/shared/styles/plugins";
@import "./app/sub-projects/shared/styles/tooltips";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999999999 !important;
}

html,
body {
  height: 100%;
}
