//Detected using bowser.js - for IE10 and below

//settings panel toggle
html.ie .settings-panel .settings-panel-toggle {
  background-size: 30px 50px !important;
}
html.ie .settings-panel-content ul li {
  background-image: none !important;
}
html.ie .settings-panel-content ul li a {
  padding-left: 30px;
}

// log button
html.ie button.log {
  background-image: none;
  padding-right: 10px;
  text-align: center;
}

// primary navigation
html.ie nav.mob ul li,
html.ie nav.desk ul li {
  background: none;
  background-image: url(~/assets/images/icons/icon-arrow-right-grey.svg) !important;
  background-color: $brand-black;
  background-size: 100px 20px !important;
  background-position: center right !important;

  &:hover,
  &.selected {
    background-image: url(~/assets/images/icons/icon-arrow-right-blue.svg) !important;
    background-color: $grey2;
    background-size: 100px 20px !important;
    background-position: center right !important;
  }
}

html.ie nav.mob ul li a,
html.ie nav.desk ul li a {
  padding-left: 30px;
}

html.ie nav.mob .nav-mob-toggle .nav-mob-btn {
  background-size: 30px 20px !important;
}

// widgets
html.ie .item .large-text {
  margin-right: 15px;
}

// filter ppointer
html section.widget.filter .pointer {
  display: none;
}
html section.widget.filter {
  margin-bottom: 30px;
}
