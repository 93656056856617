/* mixins */
@mixin background-size-edit {
  -webkit-background-size: 16px;
  -moz-background-size: 16px;
  -o-background-size: 16px;
  background-size: 16px;
}

@mixin background-position-edit {
  -webkit-background-position: left 15px top 50%;
  -moz-background-position: left 15px top 50%;
  -o-background-position: left 15px top 50%;
  background-position: left 15px top 50%;
}

.verticals-div {
  .verticals-table {
    width: 100%;
    font-size: 1.4em;
    font-weight: normal;
    color: $brand-blue;
    border: none;

    @media #{$tablet-portrait} {
      table-layout: fixed;
    }

    .verticals-row {
      width: 100%;
      display: block;
      border-bottom: 1px solid $brand-light-blue2;

      @media #{$tablet-portrait} {
        display: revert;
      }

      @include background-transition;

      // offset table headers instead of hiding for accessibility purposes
      &:first-child {
        position: absolute;
        top: -9999px;
        left: -9999px;

        @media #{$tablet-portrait} {
          position: relative;
          top: 0;
          left: 0;
        }
      }

      &:not(:first-child) {
        cursor: pointer;
      }

      &:hover {
        background-color: $brand-light-blue1;
      }

      &:focus {
        outline: 0;
      }

      &:active {
        background-color: $brand-light-blue2;
        outline: 0;
      }

      .col {
        @media #{$tablet-portrait} {
          max-width: 25%;
          min-width: 6%;
          padding-left: 15px;
          padding-right: 15px;
        }

        &.full {
          padding-top: 15px;
          padding-bottom: 10px;
          width: 100%;
          padding-left: 30px;

          &:hover {
            background-color: $fill;
          }

          .verticals-span {
            display: block;
            font-size: 1em;
            color: $text-color;
          }
        }
      }

      .header-cell {
        height: 50px;
        font-weight: bold;
        text-align: left;
        color: $brand-black;
        background-color: $brand-light-blue1;
        border-left: 1px solid $brand-light-blue2;
        border-bottom: 1px solid $fill;
        padding-top: 40px;
        padding-bottom: 15px;
        position: relative;
        word-wrap: break-word;
        vertical-align: middle;

        @media #{$desktop-mid} {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 62px;
        }

        @media #{$desktop-narrow} {
          padding-top: 15px;
          padding-bottom: 15px;
        }

        &:first-of-type {
          border-left: none;
        }

        .sort-icon-container {
          margin-right: 15px;
          height: 26px;
          float: right;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          position: relative;
          bottom: 4px;

          .sort-icon {
            display: none;
            width: 12px;
            float: right;
            cursor: pointer;

            &.on {
              display: block;
            }
          }
        }

        .header-cell-content {
          position: absolute;
          top: 12px;
          right: 0;

          @media #{$desktop-mid} {
            top: calc(50% - 10px);
          }
        }
      }

      .body-cell {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        word-break: break-word;

        @media #{$tablet-portrait} {
          display: revert;
        }

        span {
          &.title-mob {
            width: 40%;
            padding-right: 10px;
            color: $text-color;

            @media #{$tablet-portrait} {
              display: none;
            }
          }

          &.cell-content {
            width: 60%;

            @media #{$tablet-portrait} {
              width: revert;
            }
          }
        }

        .cell-div {
          display: flex;

          @media #{$tablet-portrait} {
            display: revert;
          }

          .dots {
            float: right;
            height: 20px;
            width: 20px;
            background-image: url(~/assets/images/icons/icon-dots-blue.svg);

            &:hover {
              background-image: url(~/assets/images/icons/icon-dots-blue-on.svg);
              cursor: pointer;
            }
            &.selected {
              background-image: url(~/assets/images/icons/icon-dots-blue-on.svg);
            }
          }
        }
      }
    }

    .edit-panel-content ul li a {
      font-size: 1.1em;
    }

    &.no-sorting {
      .header-cell {
        padding-top: 15px;

        @media #{$desktop-mid} {
          padding-top: 20px;
        }
      }
    }
  }
}

.verticals-table {
  .modal-container {
    float: right;
    top: 0;
    right: 0;
  }

  .modal-toggle {
    img {
      &.filter-icon {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
        position: relative;
        top: 2px;
      }
    }
  }

  .modal-content {
    display: block;
    width: 250px;
    max-height: 200px;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 58px;
    background: $fill;
    overflow-y: scroll;
    z-index: 100;

    &.hide {
      display: none;
    }

    &.modal-autocomplete {
      width: 350px;
      overflow-y: visible;

      .auto-complete-results {
        width: 100%;
        margin-right: 0;
        position: absolute;
        top: 42px;
        max-height: 264px;
        overflow-y: scroll;
        @include box-shadow;

        ul {
          margin: 0;

          li {
            font-size: 1em;
            font-weight: normal;
          }
        }
      }

      .icon-clear {
        width: 24px;
        height: 24px;
        padding: 0;
        min-height: auto;
        background-color: $brand-light-blue1;
        position: absolute;
        top: 9px;
        right: 9px;
        @include border-radius(12px);
        background: url(~/assets/images/icons/icon-close-blue.svg)
          $brand-light-blue1 no-repeat center center;
        @include background-size(10px, 10px);

        &:hover {
          background: url(~/assets/images/icons/icon-close-white.svg)
            $brand-blue no-repeat center center;
          @include background-size(10px, 10px);
        }
      }
    }

    @media #{$desktop-mid} {
      top: 37px;
    }

    @include box-shadow();

    .check-selector-container {
      display: flex;
      flex-direction: column;

      .check-selector {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 10px;
        }
      }

      label {
        text-align: left;
        font-size: 1em;
        font-weight: normal;
      }
    }

    .form-group {
      margin-bottom: 0;

      &.datepicker-container {
        display: table;
        margin-bottom: 15px;

        label {
          width: auto;
          top: 0;
          font-size: 1em;
        }

        input[type="text"].search-input {
          width: 70%;
          min-height: auto;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    input[type="text"].search-input {
      font-size: 1em;
    }
  }
}

.vertical-searchbar {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media #{$tablet-portrait} {
    margin-bottom: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$desktop} {
    justify-content: flex-end;
  }

  @media #{$desktop-narrow} {
    margin-bottom: 15px;
  }

  .actions {
    .count {
      @media #{$tablet-portrait} {
        margin: 0 !important;
      }

      span {
        @media #{$tablet-portrait} {
          display: block;
          margin-top: 5px;
          margin-left: 0;
          font-size: 1.2em;
        }

        @media #{$desktop} {
          display: unset;
          margin-top: 0;
          margin-left: 5px;
          font-size: 1.4em;
        }
      }
    }

    @media #{$desktop} {
      margin-right: auto !important;
    }
  }

  .filter-search-container {
    display: flex;
    margin-bottom: 15px;

    @media #{$tablet-portrait} {
      margin-bottom: 0;
    }
  }

  .filter-heading {
    display: none;

    @media #{$desktop} {
      display: block;
      margin-bottom: 0 !important;
    }
  }

  .filter-search {
    display: flex;

    @media #{$tablet-portrait} {
      width: 50%;
      margin-left: 15px;
    }

    @media #{$desktop} {
      width: 40%;
      margin-left: 15px;
    }

    form {
      width: 100%;
    }

    .icon-search {
      width: 50px;
    }

    input[type="text"] {
      width: 100%;
    }
  }

  .filter-download {
    margin-bottom: 15px;

    @media #{$tablet-portrait} {
      margin-left: 15px;
      margin-bottom: 0;
    }

    @media #{$desktop} {
      margin-left: 15px;
    }

    button {
      &.download {
        position: relative;
        background: url("/assets/images/icons/icon-download-white.svg")
          $brand-black no-repeat right 10px top 50%;
        @include background-size(22px, 22px);

        &:hover {
          background-color: $grey1;
        }

        .btn-loading {
          background-color: $brand-light-blue1;
        }
      }
      &.notification {
        position: relative;
        background: url("/assets/images/icons/icon-book.svg") $brand-black
          no-repeat right 10px top 50%;
        @include background-size(22px, 22px);

        &:hover {
          background-color: $grey1;
        }
      }
    }
  }

  .clear-filters {
    margin-bottom: 15px;

    @media #{$tablet-portrait} {
      margin-left: 15px;
      margin-bottom: 0;
    }

    span {
      display: none;

      @media #{$tablet-portrait} {
        margin-left: 15px;
      }

      @media #{$tablet-landscape} {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

.relative {
  position: relative;
}

.row-action {
  background-size: 16px 16px;

  .edit {
    background-image: url("/assets/images/icons/icon-edit-blue.svg");
  }

  .delete {
    background-image: url("/assets/images/icons/icon-delete-blue.svg");
  }

  .publish {
    background-image: url("/assets/images/icons/activities/icon-karate-blue.svg");
  }
}

.hr-vertical-search {
  margin: 30px 0;
}

.date-selector {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 4rem;
  background-color: $brand-blue;
  color: $fill;
  font-size: 0.8rem;

  .date-selector-item {
    padding: 1rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $brand-dark-blue;
      @include all-transition;
    }

    &.selected {
      background-color: $brand-dark-blue;
      border-bottom: 3px solid white;
    }
  }

  .left-arrow,
  .right-arrow {
    max-width: 3rem;
  }

  .left-arrow {
    background: url(~/assets/images/icons/icon-arrow-left-white.svg) $brand-blue
      no-repeat center;
    @include background-size(10px, 16px);
  }

  .right-arrow {
    background: url(~/assets/images/icons/icon-arrow-right-white.svg)
      $brand-blue no-repeat center;
    @include background-size(10px, 16px);
  }
}

.booking-buttons {
  width: 100%;
  position: fixed;
  bottom: 56px;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  background-color: $fill;
  @include box-shadow;

  @media #{$tablet-landscape} {
    bottom: 0;
  }

  .btn-group {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:last-of-type {
      margin-right: 15px;

      @media #{$tablet-landscape} {
        margin-right: 30px;
      }
    }
  }

  button {
    &.btn {
      background-image: none;
      margin-top: 15px;
      margin-bottom: 15px;

      @media #{$tablet-landscape} {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      span {
        display: none;

        @media #{$tablet-portrait} {
          display: inline-block;
        }
      }
    }

    @media #{$tablet-portrait} {
      padding-left: 20px;
      padding-right: 20px;
    }

    &:disabled {
      cursor: default;
    }
  }
}

.booking-frame-member {
  overflow-x: clip;
}
.takeover-scroll-admin {
  overflow-x: clip;
}

.timeslot-data {
  font-size: 0.8rem;
  overflow-x:inherit;
  height: auto;
  background-image: linear-gradient(
    135deg,
    #f7f7f7 8%,
    $fill 8%,
    $fill 50%,
    #f7f7f7 50%,
    #f7f7f7 58%,
    $fill 58%,
    $fill 100%
  );
  background-size: 12px 12px;

  &.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 35vh;

    .headers {
      .heading {
        min-height: 4.2rem;

        @media #{$desktop-narrow} {
          min-height: 3.6rem;
        }
      }
    }
  }

  &.flex {
    display: flex;
  }

  .headers,
  .grid-container {
    display: grid;
  }

  .headers {
    position: sticky;
    z-index: 2;
    font-weight: bold;
    background-color: $brand-light-blue2;

    &.vertical {
      top: 67px;

      @media #{$tablet-landscape} {
        top: 99px;
      }

      @media #{$desktop-narrow} {
        top: 67px;
      }
    }

    &.horizontal {
      left: 0;

      .heading {
        width: 8rem;
      }
    }

    .heading {
      padding: 1rem;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $brand-light-blue1;
      border-right: 1px solid $fill;
      border-bottom: 1px solid $fill;

      @media #{$desktop-narrow} {
        padding: 0.75rem;
      }

      .tooltip-container {
        width: 1rem;
        margin-left: 5px;
      }
    }
  }

  .headers .heading,
  .grid-container .timeslot {
    min-width: 9rem;
  }

  .grid-container {
    overflow-x: auto;
    .timeslot {
      background-color: $green1;
      color: $fill;
      padding: 1rem;
      position: relative;
      margin-bottom: 1px;
      margin-right: 1px;

      @include background-transition;

      @media #{$desktop-narrow} {
        padding: 0.7rem;
      }

      .court-alert-icon-container {
        position: absolute;
        z-index: 1;

        width: 1rem;
        height: 1rem;

        right: 0.5rem;
        top: 0.5rem;

        .court-alert-tooltip-container {
          position: relative;
        }

        &:hover {
          .court-alert-tooltip {
            visibility: visible;
          }
        }

        .court-alert-tooltip {
          visibility: hidden;

          z-index: 3;
          position: absolute;
          top: 1rem;
          right: 0;

          padding: 0.2rem;
          border-radius: 0.2rem;
          background: $brand-blue;

          white-space: nowrap;
        }
      }

      .booking-info-tooltip-positioning {
        .booking-info-tooltip {
          z-index: 1;
        }
      }

      .booking-label {
        width: 100%;
        font-weight: bold;
        display: block;
        padding-top: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.booked {
        background-color: $grey0-5;
        color: $text-color-light;
      }

      &.highlighted {
        background-color: $grey1;
      }

      &.hashed {
        cursor: default;

        &:active {
          pointer-events: none;
        }

        background-image: linear-gradient(
          135deg,
          $green0 8%,
          $green1 8%,
          $green1 50%,
          $green0 50%,
          $green0 58%,
          $green1 58%,
          $green1 100%
        );
        background-size: 12px 12px;

        &.booked {
          background-image: linear-gradient(
            135deg,
            $grey0 8%,
            $grey3 8%,
            $grey3 50%,
            $grey0 50%,
            $grey0 58%,
            $grey3 58%,
            $grey3 100%
          );
        }

        &.current-user {
          background-image: linear-gradient(
            135deg,
            #40c3f4 8%,
            $brand-blue 8%,
            $brand-blue 50%,
            #40c3f4 50%,
            #40c3f4 58%,
            $brand-blue 58%,
            $brand-blue 100%
          );
        }
        &.editDeselected {
          background-image: none;
          background-color: $green1;
        }
      }

      &.selected {
        background-image: none;
        background: $brand-black!important;
      }

      &.current-user {
        background-image: none;
        background-color: $brand-blue;
      }

      &.editDeselected {
        background-image: none;
        background-color: $green1;
      }

      &.admin-booking {
        background-image: none;
        background-color: $grey3;
        color: $grey2;
      }

      &.admin-booking.selected {
        background-image: none;
        background: $brand-black;
        color: $fill;

      }
    }

    &.horizontal .timeslot {
      border-right-width: 1px;
    }

    &.horizontal .timeslot,
    &.vertical .timeslot {
      min-height: 4.2rem;

      @media #{$desktop-narrow} {
        min-height: 3.6rem;
      }
    }
  }
}

.member-metrics-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  height: calc(100% - 2rem);

  .grid-item {
    padding: 1rem;
    align-self: center;
    text-align: center;

    span {
      display: block;

      &.metric {
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.bookings-page-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h1 {
    color: $brand-black;
    margin: 0;
  }

  .icon {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 2px;

    &.grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .block,
    .line {
      padding: 3px;
      border: 2px solid $brand-black;
      border-radius: 2px;
    }

    .line {
      width: 34px;
    }
  }
}

.admin-repeat-booking-list-component {
  .parent-group-container {
    margin-bottom: 1.5rem;
    background-color: $brand-light-blue0;
    border: 1px solid $brand-light-blue2;

    @media #{$tablet-landscape} {
      display: grid;
      grid-template-columns: auto 1fr;
    }

    .parent-subgroup-container {
      padding: 0.5rem;

      .group-container {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .parent-group-label {
      margin-bottom: 0;
      padding: 1rem 0.5rem;
      font-size: 1.6em;

      @media #{$tablet-landscape} {
        margin: 0;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        transform-origin: center;
        text-align: center;
        background-color: $brand-light-blue1;
        border-left: 1px solid $brand-light-blue2;
      }
    }
  }

  p.repeat-booking-type-header {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

.group-container {
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

table.repeat-booking-list-table {
  border: 1px solid $brand-light-blue2;
  width: 100%;
  font-size: 1.4em;

  .edit-panel-content ul li a {
    font-size: 1.1em !important;
  }

  span.faded {
    color: #bbb;
    font-style: italic;
  }

  td {
    display: block;
    width: 100%;
    padding: 1rem;

    @media #{$tablet-landscape} {
      max-width: 1px;
      display: revert;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    &.overflow-visible {
      overflow: visible;
    }

    &.no-cell-padding {
      @media #{$tablet-landscape} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &.left-border {
      @media #{$tablet-landscape} {
        border-left: 1px solid $brand-light-blue2;
      }
    }

    &.w-5 {
      @media #{$tablet-landscape} {
        width: 5%;
      }
    }

    &.w-10 {
      @media #{$tablet-landscape} {
        width: 10%;
      }
    }

    &.w-15 {
      @media #{$tablet-landscape} {
        width: 15%;
      }
    }

    &.w-20 {
      @media #{$tablet-landscape} {
        width: 20%;
      }
    }

    &.w-30 {
      @media #{$tablet-landscape} {
        width: 30%;
      }
    }

    &.center {
      @media #{$tablet-landscape} {
        text-align: center;
      }
    }

    &.icons-cell {
      min-width: 81px;
      padding: 0;
    }

    .weekday-icon-tooltip {
      h3,
      .grid {
        font-size: 0.9em;
      }
    }
  }

  tr {
    position: relative;

    &:nth-child(even) {
      background: $brand-light-blue1;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $brand-light-blue2;
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 1rem;
      flex: 1;
      height: 1rem;
    }

    .edit-panel {
      position: initial;
    }
  }

  .weekday {
    font-size: 0.8em;
  }
}

.mat-tooltip.repeat-bookings-list-notes-tooltip-styling {
  font-size: 0.9rem;
}

.weekday-container {
  display: flex;
  gap: 0.3rem;

  @media #{$tablet-landscape} {
    justify-content: center;
  }

  .weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $brand-light-blue1;
    border: 1px solid $brand-light-blue1;
    border-radius: 50%;
    font-size: 1.1em;

    &.highlighted {
      background-color: $brand-blue;
      color: $fill;
    }
  }
}
