.clickable {
  &:hover {
    cursor: pointer;
  }
}

.heading-container {
  padding: 15px 0 15px 15px;
  color: $text-color-light;
  position: relative;
  display: flex;

  h2 {
    margin-bottom: 0;
    position: relative;
    align-self: center;
    color: $text-color-light;
  }

  img {
    display: none;
    width: 34px;
    height: 34px;
    margin: 0 15px 0 0;
    float: left;

    @media #{$mobile-landscape} {
      display: block;
    }

    @media #{$desktop-narrow} {
      width: 24px;
      height: auto;
    }
  }

  &.black {
    background-color: $brand-black;
  }

  &.blue {
    background-color: $brand-blue;
  }

  button {
    min-width: 45px;
    position: absolute;
    top: 0;
    border-left: 1px solid rgba($fill, 0.3);
    font-size: 0;
    padding-right: 25px !important;

    @media #{$mobile-landscape} {
      padding-right: 40px !important;
    }

    &:nth-of-type(1) {
      right: 0;
    }

    &:nth-of-type(2) {
      right: 45px;

      @media #{$mobile-landscape} {
        right: 54px;
      }
    }

    &:nth-of-type(3) {
      right: 90px;

      @media #{$mobile-landscape} {
        right: 111px;
      }
    }

    &:nth-of-type(4) {
      right: 105px;

      @media #{$mobile-landscape} {
        right: 168px;
      }
    }
  }

  .mat-focus-indicator {
    position: initial;
  }
}

.task {
  margin-bottom: 15px;
  border-bottom: 1px solid $brand-light-blue2;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;

    .col {
      &.four {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .col {
    float: left;

    &.one {
      padding-right: 10px;
      margin-right: 10px;

      @media #{$tablet-landscape} {
        width: 3%;
        margin-right: 10px;
      }
    }

    &.two {
      @media #{$tablet-landscape} {
        width: 13%;
        margin-right: 10px;

        @media #{$desktop} {
          width: 10%;
        }
      }
    }

    &.three {
      clear: both;
      float: none;

      @media #{$tablet-landscape} {
        width: 52%;
        float: left;
        clear: none;

        @media #{$desktop} {
          width: 62%;
        }
      }
    }

    &.four {
      img {
        margin-right: 10px;
        float: none;
      }

      @media #{$tablet-landscape} {
        width: 29%;
        border-left: 1px solid $brand-light-blue2;
        float: left;
        clear: none;

        img {
          margin-left: 10px;
        }

        p {
          margin-bottom: 0;
        }
      }

      @media #{$desktop} {
        width: 22%;
      }
    }
  }

  img {
    width: 16px;
    float: left;
  }

  p {
    margin-bottom: 15px;
  }

  &.red {
    .col {
      &.one {
        border-right: 1px solid $red;
      }

      &.two {
        color: $red;

        p {
          font-weight: 700;
        }
      }
    }
  }

  &.orange {
    .col {
      &.one {
        border-right: 1px solid $orange;
      }

      &.two {
        color: $orange;

        p {
          font-weight: 700;
        }
      }
    }
  }
}

.wysiwyg {
  ul {
    margin: 0 18px;
    padding: 0 5px;

    li {
      list-style-type: disc;
      font-size: 1.1em;
    }
  }
  ol {
    margin: 0 18px;
    padding: 0 5px;
  }
}

.item {
  margin-right: 5px; //offset for ngx-scrollbar
  margin-bottom: 15px;
  position: relative;
  border-bottom: 1px solid $brand-light-blue2;

  .archived {
    &:after {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      padding-left: 10px;
      content: "";
      position: absolute;
      background: url(~/assets/images/icons/icon-archive-blue.svg) no-repeat
        center right;
    }
  }

  .icon {
    width: 16px;
    margin: 0;
    float: left;

    &.uploaded {
      @include border-radius(8px);

      @media #{$tablet-portrait} {
        @include border-radius(16px);
      }
    }

    @media #{$tablet-portrait} {
      width: 34px;
      margin-bottom: 0;
    }
  }

  .edit-panel-content {
    top: 0;
    right: 39px;
  }

  .icon-pop-up {
    &.notes-line-item,
    &.facility-notes-line-item {
      position: absolute;
      top: 40px;
      right: 10px;

      @media #{$desktop} {
        top: 15px;
        right: 40px;
      }
    }
  }

  &.static {
    h3 {
      color: $text-color;
    }
  }

  &.date {
    .large-text {
      border-right: none;
    }

    .item-content {
      margin-left: 50px;
    }
  }

  &.no-toggle {
    padding-bottom: 15px;
    display: flex;

    &.containment {
      border: none;
      padding: 0;

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    > .item-containment {
      width: 100%;

      .item-containment-heading {
        width: 100%;
        padding: 10px;
        background-color: $brand-light-blue1;
        border-left: 1px solid $brand-light-blue1;
        border-top: 1px solid $brand-light-blue1;
        border-right: 1px solid $brand-light-blue1;

        img {
          width: 16px;
        }

        p {
          color: $link-color;
        }
      }

      .flex-container {
        display: flex;
        width: 100%;
        border-left: 1px solid $brand-light-blue2;
        border-bottom: 1px solid $brand-light-blue2;
        border-right: 1px solid $brand-light-blue2;

        img {
          margin: 0 0 0 15px;

          @media #{$desktop} {
            margin-left: 20px;
          }
        }

        .item-content {
          display: flex;
          flex-direction: column;
          padding-top: 15px;
          padding-bottom: 15px;

          @media #{$mobile-landscape} {
            max-width: 84%;
          }

          @media #{$desktop} {
            max-width: 88%;
          }

          @media #{$desktop-wide} {
            max-width: 91%;
          }
        }
      }
    }

    &:hover {
      .item-content {
        > h3 {
          @include color-transition;
          color: $text-color;
        }
      }
    }

    img {
      align-self: center;

      &.avatar {
        width: 54px;
      }
    }

    h3 {
      margin-bottom: 15px;
      line-height: 22px;
    }

    p {
      line-height: 22px;

      &.icon-text {
        display: flex;

        &:last-of-type {
          margin-bottom: 0;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      &.add-info {
        margin-bottom: 5px;
      }
    }

    &.date {
      .large-text {
        border-right: 1px solid $brand-light-blue2;
      }

      h3 {
        margin-bottom: 15px;
      }
    }

    .item-content {
      max-width: 70%;
      margin-right: 0;
      margin-left: 15px;
      border-left: none;
      border-right: none;
      align-self: center;

      @media #{$mobile-landscape} {
        max-width: 75%;
      }

      @media #{$tablet-portrait} {
        max-width: 74%;
        margin-left: 0;
      }

      @media #{$desktop} {
        max-width: 70%;
      }

      @media #{$desktop-wide} {
        max-width: 81%;
      }
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
  }

  &.toggle-wide {
    .large-text {
      min-width: 50px;
      border-right: none;
    }

    .item-content {
      margin-right: 0;
      border-right: none;
    }
  }

  .item-content {
    margin-right: 38px;
    border-right: 1px solid $brand-light-blue2;
    position: relative;

    @media #{$tablet-portrait} {
      margin-left: 30px;
      padding: 0 15px;
      border-left: 1px solid $brand-light-blue2;
    }

    @media #{$desktop} {
      margin-left: 54px;
      padding: 0 20px;
    }

    &:hover {
      @include color-transition;

      > h3 {
        @include color-transition;
        color: $text-color;
      }
    }
  }

  .large-text {
    min-width: 50px;
    padding-right: 15px;
    border-right: 1px solid $brand-light-blue2;
    float: left;

    @media #{$tablet-portrait} {
      &.wide {
        min-width: 140px;

        span {
          &.large {
            text-align: left;
          }
        }
      }
    }

    span {
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }

  p {
    margin-bottom: 15px;
  }

  .avatar,
  .avatar-none {
    width: 54px;
    height: 54px;
    border: 2px solid $brand-black;
    float: left;
  }

  .avatar-none {
    @include background-size(20px, 20px);
  }

  &.slim {
    display: flex;
    align-items: center;
    width: 100%;

    .item-content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      max-width: none;
      padding: 10px 0;

      @media #{$tablet-portrait} {
        padding-left: 15px;
      }
    }

    h3 {
      margin-bottom: 0;

      span {
        &.tag {
          word-break: break-all;
        }
      }
    }

    img {
      &.set-favourite {
        width: 16px;
        height: 16px;
        margin-left: auto;
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  &.no-results {
    padding: 15px;
    margin-bottom: 0;
    border-bottom: none;

    span {
      font-size: 1.4em;
    }
  }
}

.tabs-content-container {
  overflow-y: auto;
  overflow-x: hidden;

  .tab-content {
    position: relative;
    display: none;

    &.scroll {
      margin-bottom: 15px;
      padding-right: 18px;
      overflow-y: scroll;
      border-bottom: 1px solid $brand-light-blue2;
    }

    &.on {
      display: block;
      width: calc(100% - 10px);

      img {
        &.icon-toggle {
          position: absolute;
          top: 12px;
          right: 7px;
        }
      }
    }
  }

  .whoFullName {
    font-size: inherit !important;

    &.bold {
      font-weight: bold;
    }
  }
}

.item-toggle {
  height: 100%;
  margin: 0;
  padding: 17px;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;

  span {
    font-size: 1.4em;
  }

  &:hover {
    span {
      color: $link-color;
      @include color-transition;
    }
  }

  &.on {
    height: 110px;

    span {
      color: $link-color;
    }

    img {
      &.on {
        display: block;
      }

      &.off {
        display: none;
      }
    }
  }

  img {
    width: 16px;
    margin: 10px auto;
    display: block;

    &.on {
      display: none;
    }
  }
}

.item-toggle-sub {
  padding: 15px;
  border-bottom: 1px solid $brand-light-blue2;
  cursor: pointer;
  @include background-transition;

  span {
    margin-right: 10px;
    font-size: 1.4em;
    float: right;
  }

  &:hover {
    background-color: $brand-light-blue1;

    span {
      color: $link-color;
      @include color-transition;
    }
  }

  &.on {
    border-bottom: none;

    span {
      color: $link-color;
    }

    img {
      &.on {
        display: block;
      }

      &.off {
        display: none;
      }
    }
  }

  img {
    width: 16px;
    margin: 0 10px 0 0;
    display: block;
    float: right;
    position: relative;

    &.on {
      margin: 0;
      display: none;
      top: 4px;
    }

    &.off {
      margin: 0;
      top: 4px;
    }
  }

  p {
    width: 70%;
    margin-bottom: 0;
    float: left;
    color: $brand-blue;

    @media #{$desktop} {
      width: 80%;
    }
  }
}

.item-toggle-content {
  display: none;
  padding: 15px;
  background: $brand-light-blue1;
  border-top: 1px solid $brand-light-blue2;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;

  .item {
    &:hover {
      .item-content {
        > h3 {
          @include color-transition;
          color: $text-color;
        }
      }
    }
  }

  &.on {
    display: block;
  }

  .item {
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .avatar-none {
    width: 54px;
    height: 54px;
    border: 2px solid $brand-black;
    background: url(~/assets/images/icons/icon-profile.svg) no-repeat center
      center;
    @include background-size(20px, 20px);
  }
}

.item-toggle-wide {
  padding: 15px;
  cursor: pointer;
  @include background-transition;

  &:hover {
    background: $brand-light-blue1;
  }

  &.on {
    border-bottom: 1px solid $brand-light-blue2;

    img {
      &.on {
        display: block;
      }

      &.off {
        display: none;
      }
    }

    span {
      display: none;

      &.close {
        display: block;
      }
    }
  }

  p {
    margin-bottom: 0;
    color: $brand-blue;
    float: left;

    span {
      font-size: 1em;

      &.close {
        display: none;
      }
    }
  }

  img {
    width: 16px;
    height: 10px;
    margin: 0;
    float: right;
    position: relative;
    top: 4px;

    &.on {
      display: none;
    }
  }

  span {
    float: right;
  }
}

.item-toggle-wide-container {
  display: none;

  &.on {
    display: block;
  }
}

.avatar,
.avatar-none {
  width: 100px;
  height: 100px;
  border: 4px solid $brand-black;
  @include border-radius(54px);
  object-fit: cover;

  &.white {
    color: $fill;
    border-color: $fill;
  }
}

.avatar-none {
  background: url(~/assets/images/icons/icon-profile.svg) no-repeat center
    center;
  @include background-size(40px, 40px);
}

.content-container {
  width: 100%;

  .loader {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .light-tag {
    margin: 0 auto;
    text-align: center;
    display: block;
    color: $brand-blue;
  }
}

.smaller {
  font-size: small;

  &.left-padding {
    padding-left: 15px;
  }
}

.no-margin {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.card-summary-container {
  position: relative;
}

.card-summary {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media #{$tablet-portrait} {
    flex-direction: row;
    height: 100%;
  }

  .details {
    position: relative;
    padding: 15px;

    @media #{$tablet-portrait} {
      flex: 1;
      border-right: 1px solid $brand-light-blue2;
    }

    &:first-of-type {
      border-bottom: 20px solid $brand-light-blue1;

      @media #{$tablet-portrait} {
        border-bottom: none;
      }
    }

    &:last-of-type {
      @media #{$tablet-portrait} {
        border-right: none;
      }
    }

    @media #{$desktop} {
      padding: 30px 20px 10px 20px;
    }

    .card-section {
      margin-bottom: 20px;

      &:last-of-type {
        border-bottom: none;
      }

      &.no-border {
        border: none;
      }

      p {
        margin: 0;
      }

      .card-item {
        display: flex;
        margin-bottom: 20px;
      }

      &.breakdown {
        border-bottom: 1px solid $brand-light-blue2;

        .row {
          display: flex;
          margin: 20px 0;
          justify-content: space-between;

          .categories {
            display: flex;
            align-items: center;
          }
        }

        &:first-of-type {
          > .row {
            margin-top: 0;
          }
        }

        &:last-of-type,
        &.no-border {
          border: none;
        }
      }
    }

    .edit-item {
      .edit-option {
        position: relative;
      }

      p {
        margin-right: 25px;
      }

      .btn-select-item {
        width: 16px;
        min-height: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media #{$tablet-landscape} {
    margin-top: 15px;
    margin-bottom: 30px;
    flex-direction: row;
    height: 350px;
  }

  @media #{$desktop} {
    height: 350px;
  }

  .details {
    @media #{$tablet-landscape} {
      width: 25%;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid $brand-light-blue2;
    }

    @media #{$desktop} {
      width: 50%;
    }

    .title {
      font-size: x-large;
      color: $brand-black;

      @media #{$desktop} {
        font-size: xx-large;
        margin-top: -10px;
      }
    }

    .graph {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      flex-wrap: wrap;

      .row {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid $brand-light-blue1;
        align-items: center;

        &:last-of-type {
          border-bottom: none;
        }

        @media #{$mobile-landscape} {
          width: 100%;
          border-bottom: none;
        }

        @media #{$tablet-portrait} {
          width: 100%;
        }

        @media #{$tablet-landscape} {
          width: 100%;
        }

        @media #{$desktop} {
          width: 34%;
          padding: 20px 0;
        }

        .categories {
          display: flex;
          align-items: center;

          svg {
            position: absolute;
          }

          .category-name {
            margin: 0 15px 0 28px;
          }
        }

        .value-container {
          display: flex;
          flex-wrap: nowrap;
          gap: 5px;

          .dummy-hidden-icon {
            width: 16px;
          }
        }

        .value {
          font-size: initial;
          font-weight: bold;
        }

        .tooltip-wrapper {
          display: flex;
          width: 100%;
        }
      }

      .tooltip-container {
        @media #{$mobile-landscape} {
          width: 100%;
          border-bottom: none;
        }

        @media #{$tablet-portrait} {
          width: 100%;
        }

        @media #{$tablet-landscape} {
          width: 100%;
        }

        @media #{$desktop} {
          width: 34%;
        }
      }

      .stretch {
        width: 100%;
      }
    }
    .graph-tooltip {
      flex-direction: row;
      flex-flow: column;
      padding: 10px 20px;

      @media #{$desktop} {
        padding: 10px 20px;
      }
      .row {
        width: 100%;
        @media #{$desktop} {
          width: 100%;
        }

        .categories {
          width: 200px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .value-container {
            justify-self: flex-end;
          }
        }
      }
    }
  }

  .graphics {
    display: none;
    padding-bottom: 60px;

    @media #{$tablet-landscape} {
      display: flex;
      width: 75%;
      position: relative;
    }

    @media #{$desktop} {
      width: 50%;
      overflow-x: scroll;
    }

    // breakpoint specific to graph
    @media screen and (min-width: 1580px) {
      overflow: unset;
    }

    .graph {
      @media #{$tablet-landscape} {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        flex-grow: 1;
      }
    }
  }

  &.add-graph {
    .details {
      @media #{$desktop} {
        width: 40%;
      }
    }

    .graphics {
      @media #{$desktop} {
        width: 60%;
      }
    }
  }

  &.activity-widget {
    margin: 0;
    height: auto;

    .details {
      .graph {
        .row {
          width: 100%;

          @media #{$mobile-landscape} {
            width: 42%;
          }

          @media #{$tablet-landscape} {
            width: 100%;
          }

          @media #{$desktop} {
            width: 42%;
          }
        }
      }
    }

    .graphics {
      padding-bottom: 0;

      @media #{$tablet-landscape} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.content-container {
  padding: 15px;
  background-color: $fill;
  position: relative;

  &.repeat-booking-listing {
    background: none;
  }

  &.twitter {
    border-top: 15px solid $fill;
    border-bottom: 15px solid $fill;
    max-height: 400px;

    @media #{$tablet-portrait} {
      max-height: none;
      height: 100vh;
    }
  }

  &.p-30 {
    padding: 30px;
  }

  .item {
    span {
      font-size: 1.4em;

      &.tag {
        font-size: 0.7em;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  img {
    &.twitter-temp {
      width: 100%;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }

  &.scroll {
    margin-bottom: 15px;
    overflow-y: scroll;
    border-bottom: 1px solid $brand-light-blue2;
  }

  .icon-toggle {
    &.favourite {
      width: 40px;
      height: 40px;
      margin: 0 9px 0 0;
      position: relative;
      z-index: 9999;
      background: url(~/assets/images/icons/icon-star.svg) no-repeat center
        center;
      border: 1px solid $brand-light-blue2;
      @include background-size(16px, 16px);
      @include border-radius(20px);

      &.on {
        background: url(~/assets/images/icons/icon-star-blue-fill.svg) no-repeat
          center center;
        @include background-size(16px, 16px);
        border: 1px solid $brand-blue;
      }

      @media #{$desktop} {
        margin-left: 3px;
      }
    }
  }
}

.profile-header,
.edit-user-header {
  padding: 15px;
  background-color: $brand-black;
  position: relative;

  &.bg {
    background: url(~/assets/images/clubs/club-optional-bg01.jpg) $brand-black
      no-repeat center center;
    background-size: cover;
  }

  &.blue {
    background: $brand-blue;
  }

  .archived-status {
    color: $red;
    font-weight: bold;
    line-height: 30px;
  }

  img {
    &.stamp {
      width: 25px;
      position: absolute;
      top: 15px;
      right: 15px;

      @media #{$tablet-portrait} {
        width: 50px;
      }

      @media #{$desktop} {
        top: 20px;
        right: 20px;
      }
    }
  }

  span {
    font-size: 1.1em;

    &.sub-line {
      margin-top: 10px;
      font-size: 0.6em;
      display: block;
    }
  }

  @media #{$desktop} {
    width: 32%;
    padding: 30px;
    display: table-cell;
    vertical-align: top;
  }

  .profile-title {
    display: flex;
    margin-bottom: 15px;

    @media #{$desktop} {
      display: block;
      margin-bottom: 30px;
      text-align: center;
    }

    h1 {
      max-width: 94%;
      margin: 0 auto;
      font-size: 2em;
      color: $text-color-light;
      align-self: center;
      position: relative;

      @media #{$desktop} {
        padding-bottom: 25px;
        clear: both;
        align-self: auto;
        text-align: center;
        font-size: 2.4em;
        background: url(~/assets/images/icons/icon-dash-white.svg) no-repeat;
        @include background-size(40px, 7px);
        background-position: center bottom;
      }

      .edit-item {
        position: relative;
        min-height: 16px;
        margin-bottom: 0;
        content: "";
        line-height: 30px;
        cursor: pointer;
        display: inline-block;

        &:hover {
          &::after {
            width: 16px;
            height: 16px;
            position: absolute;
            top: calc(50% - 4px);
            right: -26px;
            content: "";
            background: url(~/assets/images/icons/icon-edit-white.svg) no-repeat;
            @include background-size(16px, 16px);
          }
        }

        &.edit-account-types {
          &:hover {
            &::after {
              right: -26px;
            }
          }
        }
      }
    }

    span {
      &.edit-avatar {
        display: inline-block;
        margin-right: 15px;
        cursor: pointer;
        position: relative;

        &:hover {
          > .avatar-overlay {
            opacity: 0.99;
            @include all-transition;
          }
        }

        @media #{$desktop} {
          margin-right: 0;
          margin-bottom: 30px;
        }

        @media #{$desktop-narrow} {
          margin-bottom: 15px;
        }

        .avatar-overlay {
          opacity: 0;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 2px;
          left: 2px;
          background: url(~/assets/images/icons/icon-edit-white.svg) no-repeat
            center center rgba(0, 0, 0, 0.7);
          @include background-size(16px, 16px);
          @include border-radius(25px);
          @include all-transition;

          @media #{$desktop} {
            width: 94px;
            height: 94px;
            top: 3px;
            left: 3px;
            @include border-radius(50px);
          }

          @media #{$desktop-narrow} {
            width: 48px;
            height: 48px;
          }
        }
      }
    }

    img {
      &.avatar {
        width: 54px;
        height: 54px;
        border: 2px solid $fill;
        padding: 0;

        @media #{$desktop} {
          width: 100px;
          height: 100px;
          border: 3px solid $fill;
          margin: 0 auto;
          text-align: center;
          display: block;
        }

        @media #{$desktop-narrow} {
          width: 54px;
          height: 54px;
          border-width: 2px;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
    font-size: 1.1em;
    color: $text-color-light;

    @media #{$desktop} {
      font-size: 1.2em;
    }
  }

  span {
    &.left {
      float: left;
    }

    &.center {
      margin: 0 auto;
      display: block;
      text-align: center;
      position: relative;
    }

    &.right {
      float: right;
    }

    &.status {
      width: 46px;
      height: 5px;
      margin-left: 10px;
      display: block;
      float: right;
      background: url(~/assets/images/icons/icon-status-off.svg) no-repeat
        center center;
      @include background-size(46px, 5px);
      position: relative;
      top: 5px;

      &.on {
        background: url(~/assets/images/icons/icon-status-on.svg) no-repeat
          center center;
      }
    }
  }

  .extra-info-container {
    .extra-info-item {
      margin-bottom: 0.5rem;
    }

    .faint {
      color: $grey3;
    }

    .membership-tag-icon {
      background-color: $fill;
      color: $brand-black;
      width: fit-content;
      padding: 0 5px 0 5px;
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-left: 5px;
        width: 25px;
      }
    }
  }

  .edit-panel {
    .edit-panel-toggle {
      background: url(~/assets/images/icons/icon-dots-white.svg) no-repeat
        center center;
      @include background-size(16px, 16px);

      &:hover {
        background: url(~/assets/images/icons/icon-dots-white.svg) no-repeat
          center center;
        @include background-size(16px, 16px);
      }

      &.on {
        background: url(~/assets/images/icons/icon-dots-blue-on.svg) $fill
          no-repeat center center;
        @include background-size(16px, 16px);
      }
    }
  }

  .icon-toggle {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 70px;
    right: 0;

    @media #{$tablet-portrait} {
      height: 45px;
    }

    @media #{$desktop} {
      top: 0;
      right: auto;
      left: 0;
    }
  }

  .icon-pop-up {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;

    &.notes {
      top: 35px;

      @media #{$tablet-portrait} {
        top: 0;
        right: 40px;
      }

      span {
        &.count {
          color: $text-color-light;
          position: absolute;
          top: 15px;
          right: 32px;
          font-weight: 300;

          @media #{$tablet-portrait} {
            top: 18px;
          }
        }
      }
    }

    @media #{$tablet-portrait} {
      height: 45px;
    }
  }
}

.loading-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.profile-content {
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;

  &.no-padding {
    padding: 0;
  }

  @media #{$desktop} {
    width: 68%;
    padding: 30px;
    display: table-cell;
    vertical-align: top;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }

  p {
    overflow: hidden;
  }

  a {
    color: $text-color;

    &:hover {
      color: $brand-blue;
    }
  }

  span {
    &.large {
      font-size: 2.3em;
    }
  }

  .logo-mark {
    float: right;

    img {
      width: 34px;
      height: auto;
      margin: 0;
      float: right;
    }

    p {
      margin: 0;
      font-size: 1.2em;
      position: relative;
      right: 10px;
    }

    @media #{$tablet-portrait} {
      width: 70px;

      img {
        width: 70px;
        margin-bottom: 5px;
        float: none;
      }

      p {
        right: 0;
        text-align: center;
      }
    }
  }

  .col50 {
    &.right {
      margin-bottom: 0;
    }

    @media #{$tablet-portrait} {
      margin-bottom: 0;
    }
  }

  .col75 {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .content-ext {
    .filter-toggle {
      display: flex;
      align-items: center;
      border-top: 20px solid $brand-light-blue1;
      padding: 15px;
      background-color: $fill;

      @media #{$tablet-portrait} {
        border-top: none;
        padding: 15px 0;
      }

      &.on {
        margin-bottom: 0;
      }

      img {
        top: 0;
      }

      p,
      span {
        text-align: left;

        @media #{$tablet-portrait} {
          text-align: right;
        }
      }
    }

    .filter-toggle-content {
      padding: 15px;

      @media #{$tablet-portrait} {
        padding: 0 0 15px 0;
      }
    }

    @media #{$tablet-portrait} {
      margin: 15px 15px 0 15px;
      border-top: 1px solid $brand-light-blue2;
    }

    .secondary-address-container {
      margin: 15px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.total {
  display: block;

  p,
  span {
    margin: 0;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }

  span {
    margin-left: 10px;
  }

  p {
    position: relative;
    top: 7px;

    @media #{$desktop} {
      top: 14px;
    }
  }
}

.icon-toggle {
  width: 16px;
  height: 16px;
  z-index: 1;
  cursor: pointer;

  &.star {
    background: url(~/assets/images/icons/icon-star-white.svg) no-repeat center
      center;
    @include background-size(16px, 16px);

    &.on {
      background: url(~/assets/images/icons/icon-star-white-fill.svg) no-repeat
        center center;
      @include background-size(16px, 16px);
    }
  }

  // standard icon size
  &.favourite {
    width: 16px;
    height: 16px;
    margin: 0 9px 0 0;
    position: relative;
    background: url(~/assets/images/icons/icon-star.svg) no-repeat center center;
    @include background-size(16px, 16px);

    &.on {
      background: url(~/assets/images/icons/icon-star-blue-fill.svg) no-repeat
        center center;
      @include background-size(16px, 16px);
      border: 1px solid $brand-blue;
    }
  }
}

.icon-pop-up {
  width: 16px;
  height: 16px;
  z-index: 1;
  cursor: pointer;

  &.notes {
    &.white {
      background: url(~/assets/images/icons/icon-small-note-white.svg) no-repeat
        center center;
      @include background-size(16px, 16px);
    }

    &.blue {
      background: url(~/assets/images/icons/icon-small-note-blue.svg) no-repeat
        center center;
      @include background-size(16px, 16px);
    }
  }

  &.notes-line-item,
  &.facility-notes-line-item {
    background: url(~/assets/images/icons/icon-small-note-blue.svg) no-repeat
      center center;
    @include background-size(16px, 16px);
  }
}

.actions {
  button {
    margin-left: 10px;
    float: right;
  }

  .count {
    font-size: 1.1em;
    background-color: $brand-light-blue1;
    padding: 5px;
    float: left;

    span {
      margin-left: 7px;
      font-size: 1.4em;
      font-weight: 700;
      color: $brand-blue;
    }
  }

  &.independent {
    margin-bottom: 15px;
  }
}

.standard-scroll-widget-component {
  height: 100%;

  .no-list-items {
    margin-bottom: 1rem;
  }

  .footer-container {
    margin: 1rem 0 0 0;

    .count {
      font-size: 1.1em;
      background-color: $brand-light-blue1;
      padding: 5px;
      float: left;

      span {
        margin-left: 7px;
        font-size: 1.4em;
        font-weight: 700;
        color: $brand-blue;
      }
    }
  }
}

.filter-search {
  &.widget-search {
    display: none;
    padding: 15px;
    background-color: $brand-light-blue1;
    border-bottom: 1px solid $brand-light-blue2;

    &.on {
      display: block;
    }

    .icon-search {
      width: 20%;

      @media #{$desktop} {
        width: 10%;
      }
    }

    input[type="text"] {
      width: 80%;
      margin-bottom: 0;

      @media #{$desktop} {
        width: 90%;
      }
    }
  }
}

section {
  &.widget {
    @include box-shadow;

    &.no-shadow {
      @include box-shadow-none;
    }

    &.filter {
      @include box-shadow;
    }

    &.booking-section {
      margin-bottom: 0;
    }

    &.admin-user {
      .profile-header {
        width: 100%;
        display: block;

        .profile-title {
          margin-bottom: 0;

          img {
            &.avatar {
              margin-right: 15px;

              @media #{$desktop} {
                margin: 0 auto;
              }
            }
          }

          h1 {
            padding-bottom: 0;
            left: 0;
            background-image: none;

            @media #{$desktop} {
              margin-top: 15px;
            }
          }

          .avatar-none {
            width: 54px;
            height: 54px;
            margin-right: 15px;
            background: url(~/assets/images/icons/icon-profile-white.svg)
              no-repeat center center;
            border: 2px solid $fill;
            @include background-size(20px, 20px);
          }

          @media #{$desktop} {
            .avatar-none {
              width: 100px;
              height: 100px;
              margin: 0 auto;
              @include background-size(40px, 40px);
            }
          }
        }
      }
    }

    &.edit-user-header {
      background-color: $brand-black;
      color: $text-color-light;

      @media #{$desktop} {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .profile-title {
        margin-bottom: 0;

        h1 {
          padding-bottom: 0;
          background-image: none;

          .edit-item {
            &:hover {
              cursor: default;
              background-image: none;
            }
          }
        }
      }
    }

    &.edit-user-details {
      padding: 15px;
    }
  }

  &.member {
    .content-container {
      padding-bottom: 0;
    }
    .content-bottom-padding {
      padding-bottom: 20px;
      max-width: 600px;
    }

    section {
      &.widget {
        @include box-shadow-none;

        border: 1px solid $brand-light-blue2;
      }
    }

    .member-about {
      padding: 15px 0;

      hr {
        margin: 25px 0;
      }

      .address-content {
        margin-bottom: 15px;
        position: relative;
        display: flex;
        align-items: center;

        p {
          margin: 0 45px 0 15px;
        }

        img {
          &.icon-type {
            width: 16px;
          }
        }
      }

      .edit-option {
        @media #{$tablet-portrait} {
          width: max-content;
        }

        p {
          span {
            &:first-of-type {
              span {
                @media #{$tablet-landscape} {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }

      .edit-on {
        .edit-option {
          width: auto;
        }
      }

      .container {
        p {
          margin-bottom: 15px;
        }

        .form-group {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .mat-form-field {
        display: block;
      }

      .mat-form-field-flex {
        @media #{$tablet-portrait} {
          width: 70%;
        }
      }

      .check-selector-container {
        width: 100%;
      }
    }

    .member-details {
      padding: 0 0 15px 0;

      .columns {
        .column-item {
          .w-70 {
            clear: both;
            border-bottom: 1px solid $brand-light-blue2;

            &:last-of-type {
              border-bottom: none;
            }

            @media #{$tablet-portrait} {
              clear: none;
              float: right;
            }
          }

          p {
            &.member-detail-addition {
              margin: 12px 0;
            }
          }

          button {
            &.member-add {
              padding-left: 0;
              padding-right: 0;
              display: flex;
              align-items: center;
              color: $link-color;

              img {
                width: 21px;
                height: 21px;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .verticals-div {
        margin-bottom: 35px;
      }

      .heading-notes {
        &.accordion {
          .filter-toggle {
            display: inline-flex;
            margin-bottom: 0;
            padding: 10px;
            border-bottom: 1px solid $brand-light-blue2;
            background: none;

            &:hover {
              background-color: $brand-light-blue1;
            }

            &.on {
              background-color: $brand-light-blue1;
              border: 1px solid $brand-light-blue2;
              border-bottom: none;
            }

            p {
              width: auto;
              text-align: left;

              @media #{$tablet-portrait} {
                margin-right: 0;
              }
            }

            .modal-veil,
            .notes {
              font-size: 100%;
              color: $link-color;
            }

            > img {
              margin-left: auto;
            }
          }
        }

        .current-membership-title-area {
          display: flex;

          .enable-login-btn {
            margin-left: auto;
            font-size: 0.9rem;
          }
        }

        .current-membership-overview-txt {
          font-size: 0.9rem;
          white-space: nowrap;
          align-self: center;
          color: $text-color;
        }

        .current-membership-overview-container {
          margin-left: 0.4rem;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          .update-renewal-date-btn {
            font-size: 0.9rem;
          }

          .current-membership-note-count {
            font-size: 1.4em;
          }
        }
      }

      .add-active-membership-container {
        display: flex;
        flex-direction: row;

        .no-add-active-membership {
          margin-top: 10px;
        }

        .add-active-membership-btn {
          margin: 0 0 40px 10px;
          width: 12rem;
          float: left;
        }
      }

      .filter-toggle-content {
        padding: 15px 10px;
        border: 1px solid $brand-light-blue2;
        border-top: none;

        .verticals-div {
          margin-bottom: 5px;
        }
      }

      .auto-complete-results {
        &.link-member {
          width: auto;
          margin-top: -30px;
          margin-bottom: 30px;

          @media #{$tablet-portrait} {
            width: 100%;
            margin-left: 167px;
          }
          @media #{$desktop-wide} {
            margin-left: 112px;
          }
        }
      }
    }
  }
}

.widget-veil {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba($fill, 0.75);
  z-index: 2;

  &:before {
    width: 100%;
    position: absolute;
    top: 47%;
    text-align: center;
    content: "Coming soon";
    font-size: 1.6em;
    font-weight: 700;
  }
}

.widget-result-veil {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba($fill, 0.8);
  z-index: 1;
}

.content-veil {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba($fill, 0.8);
  z-index: 4;

  @media #{$tablet-landscape} {
    width: 85%;
  }
}

.adjust-for-booking-takeover {
  width: 100%;

  .booking-frame-member,
  .booking-frame-admin {
    @media #{$tablet-landscape} {
      display: flex;
    }
  }

  .booking-frame-member {
    .timeslot-data {
      @media #{$tablet-portrait} {
        width: 100%;
      }
      @media #{$tablet-landscape} {
        width: 70%;
        flex-grow: 1;
      }
    }
  }

  .booking-frame-admin {
    .date-banner-container {
      @media #{$tablet-landscape} {
        display: none;
      }
    }
  }

  &.admin-repeat-booking-menu {
    .date-banner-container {
      @media #{$tablet-landscape} {
        display: block;
      }
    }
  }

  .takeover-matrix-admin {
    @media #{$tablet-landscape} {
      width: 100%;
      max-width: calc(100% - 340px);
    }

    &::before {
      @media #{$tablet-landscape} {
        content: "";
        width: 20px;
        height: calc(100% - 31px);
        position: absolute;
        top: 15px;
        right: 15px;
        background: linear-gradient(
          90deg,
          rgba($brand-black, 0) 0%,
          rgba($brand-black, 1) 100%
        );
        z-index: 2;
        opacity: 0.25;
      }
    }
  }

  .takeover-member {
    position: relative;
    margin-left: auto;
    padding-left: 15px;
    &::before {
      @media #{$tablet-landscape} {
        content: "";
        width: 20px;
        height: calc(100%);
        position: absolute;
        top: 15px;
        left: -20px;
        background: linear-gradient(
          90deg,
          rgba($brand-black, 0) 0%,
          rgba($brand-black, 1) 100%
        );
        z-index: 2;
        opacity: 0.25;
      }
    }

    @include respond-to("sm") {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 999;
      top: 0;
      left: 0;
      padding-left: 0;
    }

    @media #{$tablet-landscape} {
      position: relative;
      width: 50%;
      max-width: 400px;
      z-index: inherit;
    }

    .booking-takeover {
      @include respond-to("sm") {
        height: 100%;
        padding-top: 50px;
        padding-inline: 30px;

        .modal-btn-close {
          position: relative;
          margin-left: auto;
          top: 25px;
        }
      }

      @media #{$tablet-landscape} {
        max-height: calc(100vh - 162px);
      }
    }
  }

  .takeover-admin {
    @media #{$tablet-landscape} {
      width: 100%;
      max-width: 340px;
      margin: 15px 15px 15px 0;
    }
  }

  .booking-takeover {
    width: 100%;
    padding: 15px;
    background-color: $fill;
    z-index: 1;

    @media #{$tablet-landscape} {
      position: sticky;
      top: 115px;
      max-height: calc(100vh - 236px);
      overflow-y: auto;
      border: 1px solid $brand-light-blue2;
    }

    .modal-btn-close {
      @include background-transition;
    }

    .view-other-repeat-bookings {
      font-size: 0.7rem;
    }

    img.close-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 0.8rem;
    }

    .content-container {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0.8;

      @media #{$tablet-portrait} {
        top: calc(68px);
      }

      @media #{$tablet-landscape} {
        top: calc(100px);
      }

      img.loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .booking-action {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media #{$tablet-landscape} {
        width: calc(100% + 30px);
        margin: 0 -15px -15px -15px;
        padding: 15px;
        position: sticky;
        bottom: -15px;
        background-color: $fill;
        box-shadow: 0 1px 8px rgba($brand-black, 0.4);
        z-index: 1;
      }
    }

    .noBookingActions {
      padding: 0;
    }

    @include respond-to('sm') {
      button.confirm-booking {
        margin-top: 25px;
      }
    }

    button.confirm-booking:disabled {
      pointer-events: none;
      background: url(~/assets/images/icons/icon-arrow-right-grey.svg) no-repeat
        right 10px top 50% rgb(234, 234, 234);
      color: $grey3;

      @include background-size(10px, 16px);
    }
  }
}

app-member-messages + .main .booking-takeover {
  @media #{$tablet-landscape} {
    max-height: calc(100vh - 202px) !important;
  }

}

.date-banner-container {
  padding: 5px 15px 5px 0;
}

h2.choose-site-message {
  text-align: center;
  margin-bottom: 5rem;
}

.sites-container {
  display: flex;
  justify-content: center;
  gap: 5rem;

  .site {
    height: 10rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-black;
    color: $fill;
    font-size: 0.8rem;

    &:hover {
      @include box-shadow;
      @include all-transition;
      background-color: $brand-blue;
    }
  }
}

.member-dashboard {
  .grid-section {
    gap: 2rem;
    min-height: 500px;
    padding: 1rem;
    display: grid;
    grid-template-areas:
      "bookings"
      "alerts"
      "news"
      "opportunities"
      "box-leagues"
      "links";

    grid-template-columns: minmax(0, 1fr);

    @media #{$tablet-landscape} {
      padding: 0.5rem;
      padding-top: 2rem;

      grid-template-areas:
        "bookings news opportunities"
        "alerts news opportunities"
        "box-leagues links links";

      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .grid-item {
      .header {
        border-bottom: 1px solid $brand-light-blue2;
        margin-bottom: 1rem;
        color: $brand-blue;

        h2 {
          font-size: 2em;
        }
      }

      &.upcoming-bookings {
        grid-area: bookings;

        .icons {
          img {
            opacity: 0.5;
            @include all-transition;

            &:hover {
              opacity: 1;
            }

            &.user-checkedin-tick {
              height: 30px;
              width: 30px;
              position: relative;
              right: 20px;
              top: 20px;
              opacity: 1;
            }
          }
        }
      }

      &.latest-news {
        grid-area: news;
      }

      &.playing-opportunities {
        grid-area: opportunities;
      }

      &.court-alerts {
        grid-area: alerts;
      }

      &.box-leagues {
        grid-area: box-leagues;

        @media #{$desktop} {
          display: flex;
          flex-direction: column;
        }
      }

      &.useful-links {
        grid-area: links;
      }
    }
  }
}

.admin-dashboard {
  padding: 1.5rem 1rem 2rem 1rem;

  .grid-section {
    @media #{$tablet-landscape} {
      display: flex;
    }

    .item-membership-fees {
      @media #{$tablet-landscape} {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-left: 2rem;
        margin-bottom: 2rem;
      }
    }

    .grid-item {
      width: 100%;
      margin-bottom: 2rem;

      p {
        &.center {
          text-align: center;
        }
      }

      &.item-facility {
        @media #{$tablet-landscape} {
          width: 40%;
          margin-left: 2rem;
          margin-bottom: 2rem;
        }
      }

      &.item-members {
        @media #{$tablet-landscape} {
          width: 60%;
          margin-right: 2rem;
          margin-bottom: 2rem;
        }
      }

      &.item-primary-sport {
        @media #{$tablet-landscape} {
          width: 60%;
          margin-right: 2rem;
          margin-bottom: 2rem;
        }
      }

      &.item-news {
        @media #{$tablet-landscape} {
          width: 40%;
          margin-right: 2rem;
          margin-bottom: 2rem;
        }

        .verticals-row {
          &:nth-of-type(1),
          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      &.item-useful-links {
        @media #{$tablet-landscape} {
          width: 100%;
          // margin-left: 2rem;
          margin-bottom: 0.3rem;
        }
      }

      .item-guests-title {
        margin-top: 3.1rem;
      }
    }
  }

  .admin-grid-2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);

    .grid-item {
      margin-bottom: 0;
      padding: 0.5rem;
      align-self: center;
      text-align: center;
      border-bottom: 1px solid $brand-light-blue2;

      p {
        text-align: left;
        margin-bottom: 0;
      }
    }

    &.admin-grid-memberhip-fees {
      .grid-item {
        &:nth-of-type(7),
        &:nth-of-type(8) {
          border-bottom: none;
        }
      }
    }
    &.admin-grid-booking-source {
      .grid-item {
        &:nth-of-type(7),
        &:nth-of-type(8) {
          border-bottom: none;
        }
      }
      margin-bottom: 50px;
    }
  }

  .admin-grid-3 {
    display: grid;
    // grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);

    .grid-item {
      margin-bottom: 0;
      // padding: 0.7rem;
      align-self: center;
      text-align: center;
      margin-top: 15px;

      span {
        &.metric {
          font-weight: bold;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
      }

      p {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .grid-item-header {
      padding: 0;
      margin: 0;
    }

    .grid-item-select-container-small {
      padding: 0;
      margin-bottom: 0;
    }

    &.admin-grid-primary-sport {
      border: 1px solid $brand-light-blue2;
      border-bottom: none;

      .grid-item {
        padding: 1.05rem;
        border-bottom: 1px solid $brand-light-blue2;
      }
    }

    &.admin-grid-facility {
      max-height: 300px;
      .title-med {
        font-size: 0.8rem;
        &.with-icon {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            margin-right: 0.4rem;
          }
        }
      }

      .item-large-text {
        background-color: $brand-light-blue1;
        padding: 1.2rem 0;
        border-right: 1px solid $fill;
        border-bottom: 1px solid $fill;
        .metric {
          font-size: 1rem;
        }
      }
      .item-med-text {
        background-color: $brand-light-blue1;
        padding: 20px 0;
        border-right: 1px solid $fill;
        border-bottom: 1px solid $fill;
        margin-bottom: 8px;
        align-self: flex-end;
        .metric {
          font-size: 1rem;
        }
      }
    }
  }

  .bg-blue {
    background-color: $brand-light-blue1;
  }

  .useful-links-container {
    @media #{$tablet-landscape} {
      padding-top: 3.6rem;
      padding-bottom: 3.6rem;
    }
  }

  .arrow-link {
    margin: 30px 0 0 0;
    display: flex;
    align-items: center;
    color: $link-color;

    a {
      font-size: 0.9rem;
    }

    img {
      margin-left: 0.8rem;
      height: 1rem;
    }
  }

  .summary {
    display: flex;
    justify-content: center;

    @media #{$tablet-landscape} {
      height: 270px;
    }

    .graphics {
      width: 80%;
    }
  }

  .title {
    &.with-icon {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 0.5rem;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    select {
      width: 100%;
    }

    label {
      width: auto;
      margin-bottom: 0;
      top: 0;
    }

    .form-group-right {
      width: 180px;
    }
  }

  .guests-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2rem;

    h3 {
      margin-bottom: 0;
    }
    .form-control {
      width: 180px;
      margin: 0 10px;
    }
    p {
      margin: 0;
      font-size: 1.4rem;
    }
  }

  .no-dashboard-permission {
    display: flex;
    justify-content: center;
    padding: 2rem;

    .no-permission-message {
      font-size: 0.9rem;
      padding: 2rem;
      border: 1px solid $brand-black;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
}

.metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h3 {
    margin-bottom: 0;
  }
  select {
    width: 215px;
    margin-bottom: 0;
  }
  .form-control-small {
    width: 135px;
  }
}

.booking-source-header {
  display: flex;
  justify-content: space-between;
  .form-control {
    width: 146px;
  }
}

// Dashboard newsfeed
div.latest-news {
  .filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    position: relative;

    @media #{$desktop} {
      flex-direction: row;
    }

    &.on {
      @media #{$desktop} {
        margin-bottom: 4.5rem;
      }
    }

    .news-selector-container {
      width: 100%;

      .standard-selector {
        @media #{$desktop} {
          min-width: 0;
        }
      }

      .news-type-label {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        height: 100%;

        white-space: nowrap;
        font-size: 0.9rem;

        .news-type-multi-selector {
          width: 100%;
          height: 100%;
        }
      }
    }

    .news-searchbar-container {
      width: 100%;
      height: 100%;
      display: none;

      @media #{$desktop} {
        width: -moz-fit-content;
        width: fit-content;
        display: block;
      }

      .toggle-search {
        display: none;

        @media #{$desktop} {
          display: block;
          margin-right: 0;
          background-image: url(~/assets/images/icons/icon-search.svg);
          background-color: $brand-light-blue1;
          transition: none;
        }

        &.search-on {
          @media #{$desktop} {
            display: block;
            background-image: url(~/assets/images/icons/icon-close-blue.svg);
            background-size: 14px 14px;
          }
        }
      }

      .toggle-search-news-container {
        @media #{$desktop} {
          display: none;
          width: 100%;
          position: absolute;
          top: 57px;
          left: 0;
          z-index: 1;
        }

        &.on {
          @media #{$desktop} {
            display: block;
          }
        }
      }
    }
  }

  .news-list {
    .news-element {
      background-color: $brand-light-blue1;
      margin: 0.1rem 0 0.1rem 0;
      position: relative;
      line-height: 1.5rem;
      border-left: 0.2rem solid $brand-dark-blue;
      font-size: 0.9rem;
      @include background-transition;

      &:hover:not(.read) {
        background-color: $brand-blue;
        border-left: 0.2rem solid $brand-blue;

        cursor: pointer;

        .news-container {
          a {
            color: $fill;
          }

          .news-type-icon {
            .ALLNEWS {
              background-image: url(~/assets/images/icons/icon-small-book-white.svg);
            }

            .GENERAL {
              background-image: url(~/assets/images/icons/icon-small-note-white.svg);
            }

            .TENNIS {
              background-image: url(~/assets/images/icons/activities/icon-tennis-white.svg);
            }

            .SQUASH {
              background-image: url(~/assets/images/icons/activities/icon-squash-white.svg);
            }

            .COMMITEE {
              background-image: url(~/assets/images/icons/facilities/icon-meeting-room-white.svg);
            }

            .CLUBROOMANDBAR {
              background-image: url(~/assets/images/icons/facilities/icon-licenced-bar-white.svg);
            }

            .CROQUET {
              background-image: url(~/assets/images/icons/activities/icon-croquet-white.svg);
            }

            .RACKETBALL {
              background-image: url(~/assets/images/icons/activities/icon-racketball-white.svg);
            }

            .SQUASHANDRACKETBALL {
              background-image: url(~/assets/images/icons/activities/icon-squashandracketball-white.svg);
            }

            .TABLETENNIS {
              background-image: url(~/assets/images/icons/activities/icon-table-tennis-white.svg);
            }
          }
        }

        .descPopUp {
          transition-delay: 1s;

          visibility: visible;
        }
      }
      .news-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
        padding: 0.6rem 0.5rem 0.6rem 0;

        position: relative;
        left: 5%;
        max-width: 95%;

        display: flex;
        align-items: flex-start;

        &.read-sub {
          border-bottom: 0.1rem solid $brand-light-blue1;
        }

        a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .news-type-icon {
          padding: 0 1rem 0 0;

          p {
            vertical-align: text-top;

            width: 1.2rem;
            height: 1.2rem;
            margin: 0;
          }

          .ALLNEWS {
            background-image: url(~/assets/images/icons/icon-small-book-blue.svg);
          }

          .GENERAL {
            background-image: url(~/assets/images/icons/icon-small-note-blue.svg);
          }

          .TENNIS {
            background-image: url(~/assets/images/icons/activities/icon-tennis-blue.svg);
          }

          .SQUASH {
            background-image: url(~/assets/images/icons/activities/icon-squash-blue.svg);
          }

          .COMMITEE {
            background-image: url(~/assets/images/icons/facilities/icon-meeting-room-blue.svg);
          }

          .CLUBROOMANDBAR {
            background-image: url(~/assets/images/icons/facilities/icon-licenced-bar-blue.svg);
          }

          .CROQUET {
            background-image: url(~/assets/images/icons/activities/icon-croquet-blue.svg);
          }

          .RACKETBALL {
            background-image: url(~/assets/images/icons/activities/icon-racketball-blue.svg);
          }

          .SQUASHANDRACKETBALL {
            background-image: url(~/assets/images/icons/activities/icon-squashandracketball-blue.svg);
          }

          .TABLETENNIS {
            background-image: url(~/assets/images/icons/activities/icon-table-tennis-blue.svg);
          }
        }
      }

      &.read {
        background-color: $fill;
      }

      .element-removeall {
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        margin-right: 0.5rem;
        margin-left: auto;
        width: 2rem;
      }

      .descPopUp {
        position: absolute;
        top: 2.8rem;
        z-index: 100;
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
        visibility: hidden;
        pointer-events: none;
        background-color: $fill;
        font-size: 0.8rem;

        @include box-shadow();
      }
    }
  }
}

img.back-button-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 0.8rem;
}

.make-news-item {
  position: relative;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  background-color: $fill;
  overflow-y: auto;

  @include box-shadow;
}

.make-document {
  position: relative;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  background-color: $fill;
  overflow-y: auto;

  @include box-shadow;
}

.news-item-container,
.document-container {
  p {
    &.news-type,
    &.document-type {
      width: fit-content;
      margin-bottom: 0.5rem;
      padding: 0.2rem 0.4rem;
      background-color: $brand-light-blue1;
      color: $link-color;

      span {
        color: $text-color;
      }
    }
  }

  .image-gallery-container {
    width: 100%;
    height: 15rem;
    margin-bottom: 1.5rem;
  }

  .image-caption {
    transform: translate(0, 0) !important;
    left: 0 !important;
  }

  .carousel-image {
    max-width: none;
  }

  .description-container {
    p {
      margin-bottom: 1.5rem;
    }
  }

  .news-content-container {
    .htmlDisplayBox {
      font-size: 1.4em;
      margin: 0 0 30px 0;
      line-height: 1.4em;
      font-weight: 400;
    }
  }

  .links-container,
  .attms-container {
    display: flex;
    padding: 1rem 0;
    border-top: 1px solid $brand-light-blue2;

    .links-label,
    .attms-label {
      margin-right: 0.4rem;
      font-size: 0.9rem;
    }

    .links-links,
    .attms-links {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;

      li {
        font-size: 0.9rem;
      }
    }
  }

  .footer-divider {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;

    .footer-container {
      p {
        margin-bottom: 0;
        font-size: 0.7rem;
        color: $grey3;
      }
    }
  }
}

.dashboard-profile {
  background-color: $brand-black;
  color: $fill;
  padding: 1rem;

  .loader {
    margin: auto;
  }

  .membership-tag-icon {
    background-color: $fill;
    color: $brand-black;
    width: fit-content;
    padding: 0 5px 0 5px;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial;

    img {
      margin-left: 5px;
      width: 25px;
    }
  }

  p {
    margin: 0;
    font-size: 0.9rem;

    span.tag {
      font-size: 0.9rem;
      margin-left: 0;
    }
  }

  .edit-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    @include all-transition;

    background: url(~/assets/images/icons/icon-edit-white.svg) no-repeat center
      right;

    &:hover {
      background: url(~/assets/images/icons/icon-edit-blue.svg) no-repeat center
        right;
    }
  }

  > .grid-container {
    @media #{$mobile-portrait} {
      grid-template-columns: 1fr;
      grid-template-areas:
        "left"
        "right"
        "bottom";
    }

    @media #{$tablet-portrait} {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-areas:
        "left right right"
        "bottom bottom bottom";
    }

    .left {
      grid-area: left;
      display: flex;
      gap: 1rem;
      align-items: center;

      .flex-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      @media #{$mobile-portrait} {
        border-right: none;
        border-bottom: 1px solid $grey1;
        padding-bottom: 1rem;
        flex-direction: column;
        justify-content: center;

        .flex-container {
          text-align: center;
        }

        .membership-tag-icon {
          margin: auto;
        }
      }

      @media #{$mobile-landscape} {
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 1rem;

        .flex-container {
          text-align: initial;
        }

        .membership-tag-icon {
          margin: initial;
        }
      }

      @media #{$tablet-portrait} {
        border-bottom: none;
        border-right: 1px solid $grey1;
      }

      span {
        &.edit-avatar {
          width: 92px;
          height: 92px;
          border: 3px solid $fill;
          display: inline-block;
          cursor: pointer;
          position: relative;
          @include border-radius(50%);

          @media #{$tablet-portrait} {
            width: 49px;
            height: 49px;
          }

          @media #{$desktop} {
            width: 92px;
            height: 92px;
          }

          &:hover {
            > .avatar-overlay {
              opacity: 0.99;
              @include all-transition;
            }
          }

          .avatar-overlay {
            width: 86px;
            height: 86px;
            top: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            background: url(~/assets/images/icons/icon-edit-white.svg) no-repeat
              center center rgba(0, 0, 0, 0.7);
            z-index: 1;
            @include background-size(16px, 16px);
            @include border-radius(50%);
            @include all-transition;

            @media #{$tablet-portrait} {
              width: 43px;
              height: 43px;
            }

            @media #{$desktop} {
              width: 86px;
              height: 86px;
            }
          }

          img {
            &.avatar {
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }
      }
    }

    .right {
      grid-area: right;
      padding-left: 1rem;

      .grid-container {
        display: grid;

        @media #{$mobile-portrait} {
          padding-top: 1rem;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(7, 1fr);
          grid-template-areas: "one" "six" "seven" "two" "three" "four" "five";
        }

        @media #{$mobile-landscape} {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
          grid-template-areas:
            "one two"
            "six three"
            "seven four"
            "empty five";
        }

        @media #{$desktop} {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-template-areas: "one two four" "six three five" "seven seven seven";
        }

        .grid-item {
          padding: 0.5rem;
          display: flex;
          align-items: center;

          gap: 1rem;

          img {
            width: 1rem;
          }

          &.one {
            grid-area: one;
          }

          &.two {
            grid-area: two;
          }

          &.three {
            grid-area: three;
          }

          &.four {
            grid-area: four;
          }

          &.five {
            grid-area: five;

            a:hover {
              color: $fill;
            }
          }

          &.six {
            grid-area: six;
          }

          &.seven {
            grid-area: seven;
          }
        }
      }
    }

    .bottom {
      grid-area: bottom;
      padding-top: 1rem;

      hr {
        background-color: $grey1;
      }

      .expand-toggle-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          width: max-content;
          margin: 0.5rem 1rem 0 auto;
          text-align: left;
        }

        img {
          width: 1rem;
          align-self: center;
          padding-top: 0.5rem;
        }
      }

      .expandable {
        max-height: 0;

        .expandable-content {
          text-align: initial;
          display: none;

          p {
            margin: 0;
          }
        }

        p.no-linked-guests {
          margin-top: 1rem;
          margin: auto;
        }

        &.on {
          display: contents;

          .expandable-content {
            display: block;
          }
        }
      }
      .members-guests-container {
        width: 100%;
        height: 100%;

        .linked-members-container {
          width: 100%;
          height: 100%;

          hr {
            @media #{$tablet-portrait} {
              margin-bottom: 1rem;
            }
          }
        }

        .linked-guests-container {
          width: 100%;
          height: 100%;

          hr {
            @media #{$tablet-portrait} {
              margin-bottom: 1rem;
            }
          }
        }

        h2 {
          margin: 2rem 0 0.6rem 0;
          color: $fill;
          font-size: 2em;
        }

        .grid-container {
          @media #{$tablet-portrait} {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
          }

          @media #{$tablet-landscape} {
            grid-template-columns: repeat(3, 1fr);
          }

          @media #{$desktop} {
            grid-template-columns: repeat(4, 1fr);
          }

          .linked-guest {
            margin-bottom: 0.8rem;
            padding: 0.8rem;
            display: flex;
            align-items: center;
            background-color: $grey4;
            gap: 1rem;
            position: relative;
            font-size: 0.9rem;

            @media #{$tablet-portrait} {
              margin-bottom: 0;
            }

            &:first-of-type {
              margin-top: 0.85rem;

              @media #{$tablet-portrait} {
                margin-top: 0;
              }
            }

            .remove-icon {
              position: absolute;
              right: 0.6rem;
              top: 0.6rem;
              width: 1rem;
              height: 1rem;
            }

            img {
              width: 50px;
              height: 50px;
              border-width: 2px;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                background-color: orange;
              }
            }

            .profile-details {
              display: flex;
              flex-direction: column;

              .member-card-container {
                width: 3rem;
                display: flex;
                align-items: center;
                gap: 0.4rem;
                white-space: nowrap;

                img {
                  height: 1.2rem;
                  width: auto;
                  cursor: pointer;
                }

                .product-name-tooltip {
                  visibility: hidden;
                  position: relative;
                  padding: 0.15rem;
                  border: 0.1rem solid $grey1;
                  box-shadow: 0 1px 4px rgba($fill, 0.2);
                  background-color: $brand-black;
                  white-space: nowrap;
                  color: $fill;
                  font-size: 0.9rem;
                }

                &:hover {
                  .product-name-tooltip {
                    visibility: visible;
                  }
                }
              }
            }
          }
          .add-profile-container {
            display: flex;
            flex-direction: column;

            .btn {
              margin-top: auto;
              margin-left: auto;
              width: fit-content;
              height: 42px;

              &.add {
                font-size: 1.4em;
                background-position: center right;
                text-align: right;
                color: $text-color-light;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-linked-profile-takeover {
  .create-new-guest-btn-container {
    display: flex;
    justify-content: flex-end;
    .create-new-guest-btn {
      width: 12rem;
    }
  }

  .content-container {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.8;

    @media #{$tablet-portrait} {
      top: calc(68px);
    }

    @media #{$tablet-landscape} {
      top: calc(100px);
    }

    img.loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.notification-centre-container {
  background-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  transition: all ease-in-out 0.3s;
  z-index: -1;

  @media #{$mobile-portrait, $mobile-landscape, $tablet-portrait, $tablet-landscape} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media #{$desktop} {
    position: absolute;
    top: -32px;
    bottom: -32px;
    left: -30px;
    right: -30px;
  }

  .notification-centre {
    background-color: $fill;
    transition: all ease-in-out 0.3s;
    padding: 2rem;
    overflow-y: auto;

    .notification {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem 0;

      img {
        width: 1rem;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $brand-blue;
      }

      .text-container {
        display: flex;
        flex-direction: column;

        p {
          font-size: 0.8rem;
          margin-bottom: 0;

          &.heading {
            font-weight: bold;
          }
        }
      }
    }

    @media #{$mobile-portrait, $mobile-landscape, $tablet-portrait, $tablet-landscape} {
      top: 0;
      left: 0;
      right: 0;
      bottom: 100%;
      position: fixed;
    }

    @media #{$mobile-portrait, $mobile-landscape} {
      .notification-centre-content-container {
        margin: auto;
        width: 100%;
      }
    }

    @media #{$tablet-portrait, $tablet-landscape} {
      .notification-centre-content-container {
        margin: auto;
        width: 60%;
      }
    }

    @media #{$desktop} {
      top: 0;
      bottom: 0;
      width: 30rem;
      right: -30rem;
      margin-left: auto;
      position: absolute;

      .notification-centre-content-container {
        width: 100%;
      }
    }

    h3 {
      border-bottom: 1px solid $brand-light-blue2;
      padding-bottom: 0.8rem;
      color: $brand-blue;
    }
  }

  &.on {
    @media #{$mobile-portrait, $mobile-landscape, $tablet-portrait, $tablet-landscape} {
      z-index: 95;
    }

    @media #{$mobile-portrait, $mobile-landscape, $tablet-portrait} {
      .notification-centre {
        top: 68px;
        bottom: 0;
      }
    }

    @media #{$tablet-landscape} {
      .notification-centre {
        top: 100px;
        bottom: 0;
      }
    }

    @media #{$desktop} {
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 20;

      .notification-centre {
        top: 0;
        right: 0;
      }
    }
  }
}

.upcoming-booking-container {
  .upcoming-booking-list-container {
    position: relative;
    max-height: 16rem;
    margin-bottom: 1rem;
  }

  .upcoming-booking {
    position: relative;

    min-height: 4rem;
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    transition: all 0.15s ease-in-out;
    background: url(~/assets/images/icons/icon-arrow-right-blue.svg) no-repeat right
      10px top 50% $brand-light-blue1;
    background-size: 0.6rem;

    &:hover {
      color: white;
      background: url(~/assets/images/icons/icon-arrow-right-white.svg)
        no-repeat right 10px top 50% $brand-blue;
      background-size: 0.7rem;
      cursor: pointer;
    }

    .icon {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      max-width: 4rem;
      width: 100%;
      background-color: $brand-blue;
      min-width: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.white-background {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        border: 1px solid $brand-light-blue2;

        background-color: $fill;
      }

      img {
        width: 2rem;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 1rem;

      p {
        margin: 0;
        font-size: 0.9rem;

        &.date {
          font-weight: 500;
        }
      }
    }

    .icons {
      display: flex;
      margin-left: auto;
      gap: 1rem;
      margin-right: 1rem;
      z-index: 1;

      img {
        width: 1rem;
      }
    }
  }

  .manage-notifications {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      width: 0.6rem;
    }

    button {
      margin-bottom: 15px;
    }
  }
}

.court-alerts-link {
  margin: 0 0 30px 0;
  width: 8rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: $link-color;

  a {
    font-size: 0.9rem;
  }

  img {
    margin-left: 0.8rem;

    height: 1rem;
  }
}

.box-league-container {
  text-align: center;
  position: relative;
  height: 80%;
  min-height: 3rem;
  width: 100%;

  @media #{$desktop} {
    height: 100%;
  }

  .background-image {
    background-image: url(~/assets/images/clubs/club-member-dashboard-cta01.jpg);
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        135deg,
        rgba($brand-blue, 1) 0%,
        rgba($brand-dark-blue, 1) 100%
      );
      opacity: 0.7;
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14rem;
    }
  }
}

.useful-links-container {
  padding: 1.5rem;
  background-color: $brand-light-blue1;
  min-height: 8rem;
  width: 100%;

  @media #{$mobile-landscape} {
    display: flex;
    align-items: center;
  }

  @media #{$desktop} {
    padding: 2.5rem;
  }
}

.upcoming-activities {
  .filter {
    background-color: $fill;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 0.8;
    margin-bottom: 1.3rem;

    label {
      font-size: 0.9rem;
      white-space: nowrap;
      margin-right: 1rem;
    }

    select {
      margin-bottom: 0;
    }
  }

  ul.activities-list {
    height: 500px;
    .list-element {
      position: relative;

      // flex positioning
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.not-last {
        border-bottom: 0.1rem solid $brand-light-blue2;
      }

      .activity-container {
        width: 100%;
        max-width: 100%;
        height: 4.4rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;

        // flex positioning
        display: flex;
        flex-direction: row;
        align-items: center;
        @include background-transition;

        &:hover {
          background-color: $brand-light-blue1;
        }

        .activity-icon {
          width: 1.6rem;
        }
        .activity-info {
          padding-left: 1rem;
          width: 90%;

          .activity-name {
            padding-bottom: 0.4rem;
            width: 100%;

            // text constraints
            font-size: 0.9rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .activity-datetime-container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            color: $link-color;

            .activity-date {
              white-space: nowrap;
            }

            .time-range {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.user-details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .contact-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
    align-items: stretch;

    .left-contact {
      width:30%;
      display:flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid $brand-light-blue2;
      margin-right: 30px;
      padding-right:10px;

      .username {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .membership-type-info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .membership-tag-icon-container {
          display: flex;
          flex-direction: row;
          padding: 1px 4px 1px 4px;
          border: 1px solid $brand-black;
          border-radius: 5px;
          margin-right: 5px;
          align-items: center;
        }

        img.icon-type {
          height: 2em;
          padding-left: 6px;
        }
      }
    }

    .right-contact {
      flex-grow: 1;
      display:flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin-bottom: 15px;
      }
    }
  }

  p {
    margin: 0;

    img {
      width: 1.5rem;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }
}

.update-renewal-date-content-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .renewal-date-datepicker-container {
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 1rem;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    label {
      color: $text-color;
      font-size: 0.9rem;
    }

    .renewal-date-datepicker {
      margin-left: 1rem;

      input {
        width: 14rem;
        margin: 0;
        font-size: 0.9rem;
      }
    }
  }

  button.btn {
    width: 9rem;
    font-size: 0.9rem;
    min-width: 45px;
    position: relative;
  }
}

.useful-links-editor-container {
  width: 100%;
  height: fit-content;
  background-color: $fill;
  overflow: auto;
  padding: 1rem;

  .useful-links-editor-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    .submit-field {
      display: flex;
      gap: 1rem;

      .undo-changes-btn,
      .submit-changes-btn {
        font-size: 0.9rem;
      }
    }
  }

  .input-field-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    align-items: center;

    label.useful-links-label {
      font-size: 0.9rem;
      white-space: nowrap;

      &.useful-links-group-name-label {
        color: rgba($brand-blue, 1);
        font-weight: bold;
      }
    }
  }

  input[type="text"] {
    margin: 0%;
    font-size: 0.9rem;
  }

  button.btn {
    font-size: 0.9rem;
    white-space: nowrap;

    &.add-useful-link-btn {
      width: -moz-fit-content;
      width: fit-content;
      background-image: none;
    }

    &.add-links-group-btn {
      align-self: flex-end;
    }
  }

  .useful-link-groups-container {
    display: flex;
    flex-wrap: wrap;

    .useful-link-groups {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 35px;
      width: 100%;

      &.last-useful-link-groups {
        border-right: none;
        padding-right: none;
      }

      .remove-useful-link {
        width: 1rem;
        align-self: center;
      }

      .group-name-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding-bottom: 1rem;
        border-bottom: 1px solid rgba($brand-light-blue2, 1);
      }

      .link-list-container {
        .link-list-item {
          margin-bottom: 1rem;
          display: flex;
          flex-direction: row;
          gap: 1rem;

          &.last-link-list-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.add-active-membership-contents-container {
  .form-group-right {
    &.date-picker-input {
      .add-membership-date-picker {
        @media #{$tablet-portrait} {
          display: flex;
        }

        input {
          width: 355px;
        }
      }
    }
  }
}

.members-documents-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: $fill;

  .documents-header-container {
    margin-bottom: 2rem;
    height: 34px;

    display: flex;
    align-items: center;
    gap: 1rem;

    .page-title {
      margin-bottom: 0;
    }

    .documents-icon {
      height: 100%;
    }
  }

  .extra-table-elements {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .documents-searchbar-container {
      width: 26rem;
    }

    .filters-container {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
    }
  }

  .documents-table-container {
    padding-top: 1rem;
  }
}

.results-count-container {
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: $brand-light-blue1;
  font-size: 1.1em;

  span {
    margin-left: 5px;
    font-size: 1.4em;
    font-weight: 700;
    color: $brand-blue;
  }
}

.table-component-container {
  width: 100%;

  .table-widget {
    width: 100%;

    .table-header-row {
      height: 3rem;
      border-bottom: 2px solid $fill;
      background-color: $brand-light-blue1;

      .table-header-column {
        padding: 1rem 0 1rem 0;
        border-right: 1px solid $brand-light-blue2;

        &.last-header-column {
          border-right: none;
        }

        &.download-table-entity-header {
          width: 0%;
          border-right: none;
          border-left: 1px solid $brand-light-blue2;
        }

        .header-column-content {
          float: left;
          padding: 0 1rem 0 1rem;
          margin-bottom: 0;

          .header-column-name {
            white-space: nowrap;
            font-size: 0.9rem;
            font-weight: 700;
          }
        }
      }
    }

    .table-entity-row {
      margin-top: 1px;
      border-bottom: 2px solid $brand-light-blue1;
      height: 2rem;

      @include all-transition;

      &.last-entity-row {
        margin-top: 0;
        border-bottom: none;
      }

      &:hover {
        background-color: $brand-light-blue1;
        cursor: pointer;

        .table-entity-column {
          .standard-table-tooltip {
            transition-delay: 1s;

            visibility: visible;
          }
        }
      }

      .table-entity-column {
        position: relative;
        padding: 0.5rem 0 0.5rem 0;

        .standard-table-tooltip {
          position: absolute;
          padding: 0.2rem;
          top: 100%;
          left: 1rem;
          z-index: 1;
          visibility: hidden;

          background-color: $brand-light-blue1-5;
          font-size: 0.9rem;
        }

        &.download-table-entity {
          width: 10rem;

          @include all-transition;

          &:hover {
            background-color: $brand-light-blue1-5;

            .entity-column-content {
              .entity-column-name {
                color: $brand-black;
              }
            }
          }
        }

        .entity-column-content {
          padding-left: 2rem;
          padding-right: 2rem;
          margin-bottom: 0;
          white-space: nowrap;

          .download-icon {
            height: 1rem;
            width: auto;
          }

          .entity-column-name {
            font-size: 0.9rem;
            color: rgba($brand-blue, 1);
          }
        }
      }
    }
  }
}

.selector-component-container {
  width: 100%;

  .selector-containing-label {
    width: 100%;
    font-size: 0.9rem;
    white-space: nowrap;

    display: flex;
    align-items: baseline;
    gap: 1rem;

    .selector-error-msg-container {
      position: relative;
      width: 100%;

      display: flex;
      align-items: baseline;

      .standard-selector {
        margin-bottom: 0;
        padding-right: 2rem;

        width: 100%;
        min-width: -webkit-fill-available;
        min-width: -moz-fit-content;
        min-width: fit-content;
        font-size: 0.9rem;
      }
      .selector-error-msg {
        position: absolute;
        left: 0;
        top: 104%;

        font-size: 0.7rem;
        color: $red;
      }
    }
  }
}

.searchbar-component-container {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 1rem;

  .searchbar-label {
    width: -moz-fit-content;
    width: fit-content;

    display: flex;
    align-items: center;

    font-size: 0.9rem;
    white-space: nowrap;
  }

  .search-input-container {
    width: 100%;
    display: flex;

    .searchbar-search-icon {
      min-height: 42px;
      aspect-ratio: 1/1;
      background: url(~/assets/images/icons/icon-search.svg) $fill no-repeat
        center center;
      background-color: $brand-light-blue1;

      @include background-size(20px, 20px);
      cursor: pointer;
    }

    .searchbar-input {
      margin-bottom: 0;
      width: 100%;
      font-size: 0.9rem;
    }
  }
}

.facility-type-icon {
  width: 100%;
  height: 100%;

  &.TENNISCOURT {
    background-image: url(~/assets/images/icons/activities/icon-tennis-white.svg);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: 32px;

    &.blue-sport-icon {
      background-image: url(~/assets/images/icons/activities/icon-tennis-blue.svg);
      background-size: 32px;
    }
  }

  &.SQUASHCOURT {
    background-image: url(~/assets/images/icons/activities/icon-squash-white.svg);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: 32px;

    &.blue-sport-icon {
      background-image: url(~/assets/images/icons/activities/icon-squash-blue.svg);
      background-size: 32px;
    }
  }
}

.z-index-one {
  z-index: 1;
}

.user-roles-form-component-container {
  .role-sub-selectors-title {
    white-space: nowrap;
    font-size: large;
    color: rgba($brand-blue, 1);
  }

  .role-status-selector-label {
    padding-right: 0;
    margin-bottom: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      padding-right: 0;
      width: 100%;
    }
    .role-status-selector-container {
      width: 80%;
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.linked-members-guests {
  padding-bottom: 1rem;

  .loader {
    margin: auto;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }

  p.no-linked-guests {
    margin-top: 1rem;
    margin: auto;
  }

  .members-guests-container {
    width: 100%;
    height: 100%;

    .linked-members-container {
      width: 100%;
      height: 100%;

      hr {
        @media #{$tablet-portrait} {
          margin-bottom: 1rem;
        }
      }
    }

    .linked-guests-container {
      width: 100%;
      height: 100%;

      hr {
        @media #{$tablet-portrait} {
          margin-bottom: 1rem;
        }
      }
    }

    h2 {
      margin-bottom: 0.6rem;
      font-size: 2em;
    }

    .grid-container {
      @media #{$tablet-portrait} {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$tablet-landscape} {
        grid-template-columns: repeat(3, 1fr);
      }

      @media #{$desktop} {
        grid-template-columns: repeat(4, 1fr);
      }

      .linked-guest {
        margin-bottom: 0.8rem;
        padding: 0.8rem;
        display: flex;
        align-items: center;
        background-color: $brand-light-blue1;
        gap: 1rem;
        position: relative;
        font-size: 0.9rem;

        @media #{$tablet-portrait} {
          margin-bottom: 0;
        }

        &:first-of-type {
          margin-top: 0.85rem;

          @media #{$tablet-portrait} {
            margin-top: 0;
          }
        }

        .remove-icon {
          position: absolute;
          right: 0.6rem;
          top: 0.6rem;
          width: 1rem;
          height: 1rem;
        }

        img {
          width: 50px;
          height: 50px;
          border-width: 2px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            background-color: orange;
          }
        }

        .profile-details {
          display: flex;
          flex-direction: column;

          .member-card-container {
            width: 3rem;
            display: flex;
            align-items: center;
            gap: 0.4rem;
            white-space: nowrap;

            img {
              height: 1.2rem;
              width: auto;
              cursor: pointer;
            }

            .product-name-tooltip {
              visibility: hidden;
              position: relative;
              padding: 0.15rem;
              border: 0.1rem solid $grey1;
              box-shadow: 0 1px 4px rgba($fill, 0.2);
              background-color: $brand-black;
              white-space: nowrap;
              color: $fill;
              font-size: 0.9rem;
            }

            &:hover {
              .product-name-tooltip {
                visibility: visible;
              }
            }
          }
        }
      }
      .add-profile-container {
        display: flex;
        flex-direction: column;

        .btn {
          &.add {
            width: fit-content;
            font-size: 1.4em;
            background: url(~/assets/images/icons/icon-plus-white.svg)
              $brand-black center right 10px no-repeat;

            @include background-size(21px, 21px);

            &:hover {
              background-color: $grey1;
            }
          }
        }
      }
    }
  }
}

.image-carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image-list-container {
    position: absolute;
    height: 100%;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;

    @include all-transition;

    .image-container {
      height: 100%;
      position: relative;

      .carousel-image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        max-width: 100%;
        max-height: 100%;
      }

      .image-caption {
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, 0%);

        padding: 0.2rem;
        background-color: rgba($fill, 0.6);
        color: $grey3;
        font-size: 0.7rem;
      }
    }
  }

  .carousel-btn {
    width: -moz-fit-content;
    width: fit-content;

    opacity: 0.8;
  }

  .prev-btn {
    position: absolute;
    bottom: 50%;
    left: 5%;
    transform: translate(0%, 50%);

    background-image: url("/assets/images/icons/icon-arrow-left-blue.svg");

    &:hover {
      background-image: url("/assets/images/icons/icon-arrow-left-white.svg");
    }
  }

  .next-btn {
    position: absolute;
    bottom: 50%;
    right: 5%;
    transform: translate(0%, 50%);
  }
}

.sportinsight-homepage-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .login-options {
    display: flex;
    justify-content: space-between;

    .login-option-btn {
      padding-left: 2rem;

      background-image: none;
      width: -moz-fit-content;
      width: fit-content;

      @include all-transition;
      background: url(~/assets/images/icons/icon-small-logout-blue.svg)
        $brand-light-blue1 no-repeat left 10px top 50%;
      @include background-size(16px, 16px);
      @include background-transition;

      &:hover {
        background-image: url(~/assets/images/icons/icon-small-logout.svg);
        color: $brand-black;
      }
    }

    .two-FA-prompt {
      font-size: 0.8rem;
    }
  }

  .sites-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;

    .site-option {
      width: 6rem;
      height: 6rem;

      display: flex;
      align-items: center;
      text-align: center;

      font-size: 0.9rem;
      font-weight: 500;
      color: $brand-blue;
      background-color: $brand-light-blue1;

      @include all-transition;

      &:hover {
        color: $fill;
        background-color: $brand-blue;
      }
    }
  }
}

.unselectable {
  -webkit-user-select: none;
  user-select: none;
}

.linked-member-container {
  cursor: pointer;
}

.default-cursor {
  cursor: default;

  :not(:first-child) {
    cursor: default;
  }
}

.no-border {
  border: none !important;
}

.no-box-shadow {
  @include box-shadow-none;
}

.no-padding {
  padding: 0 0 0 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}

section.view-entity-tab-styling {
  .content-container {
    section {
      border: none;
    }
  }
}

.member-search-results-style-takeover {
  .linked-members .flex-container .search .auto-complete-results {
    max-height: none !important;
    position: relative !important;
    z-index: 0 !important;
    top: 0 !important;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.admin-dashboard.noPermissions {
  height: 100%;
  padding: 1.5rem 1rem 2rem;
}

app-dashboard {
  height: 100%;
}

.no-dashboard-permission.no-dashboard-permission-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  .grid-section {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .no-permission-message {
    margin-bottom: 60px;
  }
}

#checkin-txt {
  font-size: 0.875rem;
}
