// input-popup.component.html
.mat-calendar-body-selected {
    background-color: $brand-blue;
}

.mat-calendar-body-cell-content.mat-focus-indicator {
    &:hover {
        background-color: $brand-blue;
    }
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: $brand-blue;
}

// metrics.component.html
img.tooltip-trigger-icon {
    width: 16px;
}

// widget.component.html
.scrollbar-track {
    background-color: rgba(238, 247, 252, 1) !important;
    border-radius: 4px !important;
}

.scrollbar-thumb {
    background-color: rgba(0, 175, 240, 1) !important;
    border-radius: 4px !important;
}

.ng-scroll-content {
    display: unset !important;
}

.scroll-padding {
    padding-bottom: 10px;
}
