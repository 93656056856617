// Base tooltip

.bookings-tooltip {
  position: relative;
  display: inline-block;

  img {
    width: 1rem;
  }
}

.bookings-tooltip .tooltiptext {
  visibility: hidden;
  background-color: $fill;
  color: $brand-black;
  position: fixed;
  z-index: 10;
  transition: opacity 0.3s;
  padding: 1rem;
  overflow-y: auto;
  max-width: 450px;
  @include box-shadow();
}

.bookings-tooltip .dark-bg-text {
  background-color: $brand-black;
  color: white;
}

// Specific tooltips

.facility-notes-tooltip {
  width: 250px;

  .property {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:first-of-type {
      margin-top: 1rem;
    }

    img {
      margin-right: 0.5rem;
      width: 15px;
    }
  }

  p {
    margin: 0;

    &.facility-name {
      font-size: 0.9rem;
      font-weight: bold;
    }

    &.property-text {
      font-size: 0.8rem;
    }
  }
}

.weekday-icon-tooltip {
  max-width: 400px;
  width: max-content;

  h3 {
    color: $brand-black;
    font-size: 1.4em;
    font-weight: bold;
  }

  .grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    white-space: break-spaces;
    font-size: 1.4em;

    span:first-of-type {
      font-weight: 500;
    }
  }
}

.booking-exception-tooltip {
  width: 300px;
}

.multiple-bookings-tooltip {
  .bookings-tooltip,
  .tooltip-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .tooltiptext {
    width: 400px;

    p {
      font-size: 0.9rem;
      font-weight: bold;
    }

    ul {
      margin-left: 1rem;

      li {
        font-size: 0.9rem;
        list-style-type: circle;
      }
    }
  }
}

.booking-info-tooltip {
  .bookings-tooltip,
  .tooltip-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .tooltiptext {
    min-width: 300px;
    max-height: 300px;

    .booking-info-skill {
      margin-top: 1.5rem;

      .ability-labels {
        display: flex;
        justify-content: space-between;
      }
    }
    .booking-owner {
      margin-top: 0.7rem;

      p {
        margin: 0;
        font-size: 0.6rem;
      }
    }
    p {
      font-size: 0.9rem;

      &.booking-info-header {
        margin-bottom: 0;
        color: $link-color;
      }

      span.tag {
        margin-left: 0;
        color: $text-color;
      }

      &.notes {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.notes,
      span.tag {
        font-size: 0.8rem;
      }

      &.no-notes {
        font-size: 0.8rem;
        margin-bottom: 0;
      }
    }

    .selected-members {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1rem;

      .flex-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        background-color: $brand-light-blue1;
        padding: 0.5rem;

        .user-text {
          display: flex;
          flex-direction: column;

          p,
          span.tag {
            color: $brand-dark-blue;
          }

          p {
            margin-bottom: 0;
            font-size: 0.9rem;
          }

          span.tag {
            margin-left: 0;
            text-transform: none;
            font-size: 0.8rem;
            color: $text-color;
          }

          &.bold {
            font-weight: bold;

            p {
              font-weight: bold;
            }
          }
        }

        img {
          width: 2.25rem;
          height: 2.25rem;
          border-width: 2px;
        }
      }
    }
  }
}

.vertical-table-tooltip {
  .bookings-tooltip,
  .tooltip-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .tooltiptext {
    font-size: 0.9rem;

    p {
      font-size: 0.9rem;
      margin-bottom: 0;
    }
  }
}

.user-select-none {
  user-select: none;
}
