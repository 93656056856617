body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 62.5%;
  letter-spacing: $letter-spacing;
  color: $text-color;
  background-color: $brand-light-blue1;
}

html,
body {
  position: relative;
}

div {
  &.main {
    position: relative;
    margin: 1rem 5%;
    display:flex;
    flex-grow:2;
    flex-direction: column;

    @media #{$tablet-portrait} {
      margin: 2rem 20px;
    }

    @media #{$tablet-landscape} {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

main {
  margin: 88px 5% 0 5%;

  &.full {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  @media #{$tablet-portrait} {
    min-height: calc(
      100vh - 178px
    ); /* minus footer height & margin top height */
    margin: 88px 20px 20px 20px;
  }

  @media #{$tablet-landscape} {
    width: 74%;
    min-height: calc(
      100vh - 220px
    ); /* minus footer height & margin top height */
    margin: 130px 30px 0 0;
    float: right;
    clear: none;
  }

  @media #{$desktop} {
    width: 75.5%;
  }

  @media #{$desktop-wide} {
    width: 76.5%;
  }

  // login
  &.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    max-width: 700px;
    min-height: 100vh;
    margin: 0 auto;
    float: none;

    .login-form {
      .login-footer-container {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin-top:10px;
        flex-direction: column;
        align-items: flex-start;
        .check-selector {
          margin-bottom: 10px
        }

        @include all-transition;

        @media #{$tablet-portrait} {
          margin-left: 30%;
          width: 70%;
          flex-direction: row;
          align-items: flex-start;
          .check-selector {
            margin-bottom: 30px
          }
        }

        @media #{$tablet-landscape} {
          margin-left: 30%;
          width: 70%;
          margin-top:5px;
        }

        @media #{$desktop} {
          margin-left: 20%;
          width: 80%;
        }

        @media #{$desktop} {
          margin-left: 30%;
          width: 70%;
        }

        @media #{$desktop-wide} {
          margin-left: 20%;
          width: 80%;
        }

        .remember-me-checkbox {
          width: 1rem;
          height: 1rem;
          top: -2px;

          &:after {
            left: 5px;
            top: 1px;
          }
        }
      }
    }

    .base-link {
      display: block;
      margin-top: 15px;
      text-align: right;
    }
  }
}

.two-FA-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .two-FA-title {
    text-align: center;
  }

  .two-FA-desc {
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;

    &.auth-app-links-container {
      margin-bottom: 0.5rem;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .auth-app-link {
      margin-left: 1rem;
    }
  }

  .two-FA-QR-code-container {
    padding: 1rem;
    border: 1px solid $brand-light-blue2;
    margin-bottom: 1rem;

    max-width: 28rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    .two-FA-QR-code {
      width: 10rem;
      aspect-ratio: 1;

      .QR-code-contents {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .cannot-scan-QR-prompt {
      margin-top: 0.2rem;
      align-self: flex-end;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .cannot-scan-QR-prompt-toggle {
        left: 0%;
      }

      .manual-2FA-setup-desc {
        margin-bottom: 0.5rem;
      }

      .manual-2FA-setup-field {
        margin-left: 1rem;
        margin-bottom: 0;

        color: $brand-blue;
        font-weight: bolder;

        display: flex;
        white-space: nowrap;

        .field-contents {
          margin-left: 0.5rem;

          color: initial;
          font-weight: initial;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    .verification-code-label {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      color: $brand-blue;
      font-size: 0.9rem;

      .verification-code-input {
        width: 100%;
        margin-bottom: 0;
        font-size: 0.9rem;
      }
    }
  }

  .two-FA-code-input-container {
    width: 18rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .two-FA-code-input-label {
      visibility: hidden;
    }

    .two-FA-code-input {
      width: 100%;
      margin: 0;
    }

    .recovery-codes-prompt {
      margin-top: 0.2rem;

      .recovery-codes-prompt-toggle {
        left: 0%;
      }
    }
  }

  .verify-two-factor-btn {
    margin-top: 1rem;
  }

  .recovery-codes-container {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid $brand-light-blue2;
    width: 30rem;

    display: flex;
    flex-direction: column;

    .recovery-codes-desc {
      color: $brand-blue;
    }

    .recovery-codes {
      margin-bottom: 2rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .copy-btn {
      width: -moz-fit-content;
      width: fit-content;
      background-image: none;

      align-self: flex-end;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 68px;
  bottom: 0;
  right: 0;
  left: 0%;

  @media #{$tablet-landscape} {
    left: 62px;
    top: 100px;
  }

  @media #{$desktop} {
    left: 85px;
  }

  @media #{$desktop-narrow} {
    top: 68px;
  }
}

section {
  clear: both;
  position: relative;

  &.widget {
    margin-bottom: 20px;
    background-color: $fill;

    &.admin-user {
      max-width: 950px;
      margin: 0 auto;
    }

    a {
      &.btn {
        margin-top: 15px;
      }
    }

    @media #{$tablet-landscape} {
      margin-bottom: 30px;
    }

    &.profile {
      width: 100%;

      @media #{$desktop} {
        display: table;
      }
    }

    .edit-panel-content {
      ul {
        li {
          a {
            font-size: 1.4em;
          }
        }
      }
    }

    .table {
      .edit-panel-content {
        ul {
          li {
            a {
              font-size: 1.1em;
            }
          }
        }
      }
    }

    .actions {
      margin: 0 15px 0 15px;

      &.independent {
        margin: 0;
      }

      .count {
        margin-bottom: 15px;
      }

      button {
        &.small {
          margin-bottom: 15px;
        }
      }
    }

    &.no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &.back-bar {
    background-color: $fill;
  }
}

.left-col {
  position: relative;

  @media #{$tablet-portrait} {
    width: 48%;
    float: left;
  }

  @media #{$tablet-portrait} {
    &.dash {
      width: 62%;
    }
  }
}

.right-col {
  position: relative;

  @media #{$tablet-portrait} {
    width: 48%;
    float: right;
  }

  @media #{$tablet-portrait} {
    &.dash {
      width: 34%;
    }
  }
}

.clear {
  clear: both;

  @media #{$tablet-portrait} {
    &.fix {
      clear: none;
    }
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 1.4em;
  }
}

hr {
  width: 100%;
  height: 1px;
  background-color: $brand-light-blue2;
  border: none;
}

.edit-panel {
  position: absolute;
  top: 0;
  right: 0;

  #upcoming-bookings.edit-panel-toggle{
    height: 63px;
  }

  .edit-panel-toggle {
    width: 40px;
    height: 40px;
    position: relative;
    float: right;
    background: url(~/assets/images/icons/icon-dots-blue.svg) no-repeat center
      center;
    @include background-size(16px, 16px);
    @include background-transition;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-image: url(~/assets/images/icons/icon-dots-blue-on.svg);
      @include background-size(16px, 16px);
      @include background-transition;
    }

    @media #{$tablet-portrait} {
      height: 45px;
    }

    &.on {
      background-color: $fill;
      z-index: 3;
      border: 1px solid $brand-light-blue2;
      background-image: url(~/assets/images/icons/icon-dots-blue-on.svg);
      @include background-size(16px, 16px);
    }
  }

  p {
    margin: 0 20px 0 0;
    color: $text-color-light;
    display: none;
    float: left;

    @media #{$desktop} {
      display: block;
      margin: 40px 0 0 30px;
      font-size: 1.6em;
    }
  }
}

.edit-panel-content {
  width: 90%;
  max-width: 230px;
  position: absolute;
  top: 0;
  right: 39px;
  @include box-shadow;
  display: block;
  border: 1px solid $brand-light-blue2;
  z-index: 10;

  @media #{$mobile-landscape} {
    max-width: 292px;
  }

  &.override-scrollbar {
    @media #{$mobile-landscape} {
      position: fixed;
    }
  }

  ul {
    li {
      font-size: 0.9em;
      border-bottom: 1px solid $brand-light-blue2;
      background: $fill;

      &:last-of-type {
        border-bottom: none;
      }

      a {
        padding: 13px 20px 13px 40px;
        display: block;
        color: $link-color;
        @include color-transition;

        &:hover {
          color: $link-hover-color;
          cursor: pointer;
        }

        @media #{$tablet-portrait} {
          padding-top: 15px;
          padding-bottom: 16px;
        }
      }

      &.address {
        background-image: url(~/assets/images/icons/icon-small-home-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-home.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.edit {
        background-image: url(~/assets/images/icons/icon-edit-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-edit.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.delete,
      &.delete-challenge {
        background-image: url(~/assets/images/icons/icon-delete-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-delete.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.transfer {
        background-image: url(~/assets/images/icons/icon-arrow-right-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-arrow-right.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size(16px, 16px);
          background-color: $brand-light-blue1;
        }
      }

      &.primary {
        background-image: url(~/assets/images/icons/icon-primary-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-primary.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.primary-relationship {
        background-image: url(~/assets/images/icons/icon-top-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-top.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.venue {
        background-image: url(~/assets/images/icons/icon-small-location-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-location.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.view {
        background-image: url(~/assets/images/icons/icon-view-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-view.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &#send-login,
      &.send-login {
        background-image: url(~/assets/images/icons/icon-small-book-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-small-book.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &#activate,
      &.activate {
        background-image: url(~/assets/images/icons/icon-activate-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-activate.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &#deactivate,
      &.deactivate {
        background-image: url(~/assets/images/icons/icon-deactivate-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-deactivate.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.archive {
        background-image: url(~/assets/images/icons/icon-archive-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-archive.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.unarchive {
        background-image: url(~/assets/images/icons/icon-unarchive-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-unarchive.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.copy {
        background-image: url(~/assets/images/icons/icon-plus-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/icon-plus.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }

      &.publish {
        background-image: url(~/assets/images/icons/activities/icon-karate-blue.svg);
        @include background-repeat-settings;
        @include background-position-edit;
        @include background-size(16px, 16px);
        @include background-transition;

        &:hover {
          background-image: url(~/assets/images/icons/activities/icon-karate.svg);
          @include background-repeat-settings;
          @include background-position-edit;
          @include background-size-edit;
          background-color: $brand-light-blue1;
        }
      }
    }
  }
}

.hide {
  display: none;
}

.nicescroll-cursors {
  z-index: 3;
}

.sr-only {
  position: absolute !important;
  left: -99990px !important;
  font-size: 0 !important;
  width: 1px;
  height: 1px;
}

.global-freeze {
  overflow: hidden;
}

.modal-veil {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; // flex-start to top align

  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1050;

  @media #{$tablet-landscape} {
    padding: 25px;
  }

}

.modal-container:not(.header-cell-content .modal-container) {
  position: relative;
  width: 100%;
  max-width: 634px;
  padding: 25px;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  background-color: $fill;

  @media #{$mobile-landscape} {
    height: auto;
    max-height: 95%;
  }


  &:first-of-type h2 {
    margin-bottom: 1.5rem;
  }
}

.modal-btn-close {
  width: 34px;
  height: 34px;
  min-height: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: url(/assets/images/icons/icon-close-blue.svg);
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 2;
  background-color: $brand-light-blue1;

  @include background-size(14px, 14px);
  @include border-radius(17px);

}

.columns {
  display: flow-root;

  @media #{$tablet-landscape} {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .column-item {
    margin-bottom: 15px;

    .content-right {
      margin-left: auto;
    }

    &:last-child,
    &:nth-last-child(2) {
      @media #{$tablet-landscape} {
        margin-bottom: 0;
      }
    }
  }

  &.columns2 {
    .column-item {
      @media #{$tablet-landscape} {
        width: 48%;
      }
    }
  }

  &.columns3 {
    .column-item {
      @media #{$tablet-landscape} {
        width: 33.33%;
      }
    }
  }
}

.container {
  margin-bottom: 25px;
  padding: 15px;
}

.blue {
  background-color: $brand-light-blue0;

  select,
  .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-form-field-flex {
    border: 1px solid $brand-light-blue2;
    background-color: $fill;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    &:after {
      background: linear-gradient(
        to left,
        $fill 0%,
        rgba($fill, 0) 100%
      );
    }
  }
}

.heading-notes {
  .notes {
    font-size: 62.5%;
  }

  .underline {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.6em;

    @media #{$tablet-portrait} {
      line-height: inherit;
    }
  }

  .icon-pop-up.notes.blue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    width: max-content;
    height: 100%;
    min-height: 16px;
    background-position: center right;
  }
}

.w-30,
.w-65,
.w-70 {
  width: 100%;
}

.w-30 {
  @media #{$tablet-portrait} {
    width: 30%;
  }
}

.w-65 {
  @media #{$tablet-portrait} {
    width: 65%;
  }
}

.w-70 {
  @media #{$tablet-portrait} {
    width: 70%;
  }

  @media #{$desktop-wide} {
    width: 80%;
  }
}

.custom-context-menu {
  position: fixed;
  z-index: 99999;
  background-color: $brand-light-blue1;
  min-width: 7rem;
  max-width: 12rem;
  @include box-shadow;

  ul li {
    padding: 1rem 2rem 1rem 3rem;
    font-size: 0.8rem;
    background-color: $fill;

    @include all-transition;

    &:hover {
      background-color: $brand-light-blue1;
    }

    &.icon {
      background-repeat: no-repeat;
      background-position: 1rem 50%;
      background-size: 1.2rem;

      &.edit {
        background-image: url(~/assets/images/icons/icon-edit-blue.svg);
      }

      &.delete {
        background-image: url(~/assets/images/icons/icon-delete-blue.svg);
      }

      &.plus {
        background-image: url(~/assets/images/icons/icon-plus-blue.svg);
      }

      &.close {
        background-image: url(~/assets/images/icons/icon-close-blue.svg);

        @include background-size(15px, auto);
      }

      &.history {
        background-image: url(~/assets/images/icons/icon-history-blue.svg);
      }

      &.events {
        background-image: url(~/assets/images/icons/icon-events-blue.svg);
      }

      &.email {
        background-image: url(~/assets/images/icons/icon-email-blue.svg);
      }
    }
  }
}

.mb-1 {
  margin-bottom: 1rem;
}

.displayNone {
  display: none!important;
}
