.footer-container {
  margin-top: auto;
}

footer {
  width: 100%;
  margin-bottom: 56px;
  padding: 20px 5%;
  clear: both;
  background-color: $brand-black;
  color: $text-color-light;

  position: relative;

  @media #{$tablet-portrait} {
    padding: 20px;
  }

  @media #{$tablet-landscape} {
    margin-bottom: 0;
    padding: 35px 30px;
    float: right;
  }

  @media #{$desktop-narrow} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  img {
    &.badge {
      height: 28px;
      margin: 0 auto 0 auto;
      display: block;

      @media #{$tablet-portrait} {
        margin: 0 15px 0 0;
        float: left;
      }
    }
  }

  p {
    text-align: center;
    margin-bottom: 20px;

    &.powered-by {
      margin-right: 0.5rem;
    }

    &.right {
      text-align: center; /* override for mobile */
    }

    @media #{$tablet-portrait} {
      position: relative;
      top: 7px;
    }

    &.copyright {
      margin-left: 1rem;
      color: rgba($color: #ffffff, $alpha: 0.6);
      margin-top: 20px;
    }

    a {
      margin-left: 15px;

      &:hover {
        color: $text-color-light;
      }
    }

    @media #{$tablet-portrait} {
      margin-bottom: 0;
      text-align: left;
      float: left;

      &.copyright {
        float:right;
        margin-top: 0px;
      }

      &.right {
        margin-right: 15px;
        float: right;
      }
    }
  }

  ul {
    &.social-media {
      margin-bottom: 20px;
      position: relative;
      left: 50%;
      float: left;

      li {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border: 2px solid $fill;
        @include border-radius(15px);
        float: left;
        position: relative;
        right: 50%;
        @include all-transition;

        img {
          width: 14px;
          margin: 0 auto;
          display: block;
          text-align: center;
          position: relative;
          top: 6px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          border: 2px solid $brand-blue;
        }

        @media #{$tablet-portrait} {
          right: 0;
        }
      }

      @media #{$tablet-portrait} {
        margin-bottom: 0;
        left: 0;
        float: right;
      }
    }
  }
}
